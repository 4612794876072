import React from 'react'

import Icon from 'components_new/atoms/Icon'
import Switch from 'components_new/atoms/Switch'
import Tooltip from 'components_new/atoms/Tooltip'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

interface FeaturesProps {
  editable: boolean
  embedType: 'dashboard' | 'widget'
  imageExport: boolean
  setImageExport: (value: boolean) => void
  setUserFilter: (value: boolean) => void
  userFilter: boolean
}

const Features = (props: FeaturesProps) => {
  const {
    editable,
    embedType,
    imageExport,
    setImageExport,
    setUserFilter,
    userFilter
  } = props

  return (
    <SettingsGroup
      helperText={
        <>
          Användarfilter och bildexport går endast att använda då dashboardens
          åkomst sker via en API-nyckel över en dynamisk länk.
        </>
      }
      title="Funktionalitet"
    >
      <SettingsItem
        actions={
          <Tooltip
            title={
              'Endast ägare eller adminstratörer av dashboarden kan ändra det här.'
            }
            disableHoverListener={editable}
          >
            <span>
              <Switch
                color="success"
                checked={userFilter}
                disabled={!editable}
                onChange={() => setUserFilter(!userFilter)}
                size="medium"
              />
            </span>
          </Tooltip>
        }
        body={
          <>Låter användaren få tillgång till {embedType}ens användarfilter.</>
        }
        icon={<Icon name={'FilterAlt'} />}
        title={'Användarfilter'}
        titleAdornments={<code>?filter=true</code>}
      />

      <SettingsItem
        actions={
          <Tooltip
            title={
              'Endast ägare eller adminstratörer av dashboarden kan ändra det här.'
            }
            disableHoverListener={editable}
          >
            <span>
              <Switch
                color="success"
                checked={imageExport}
                disabled={!editable}
                onChange={() => setImageExport(!imageExport)}
                size="medium"
              />
            </span>
          </Tooltip>
        }
        body={<>Låter användaren exportera {embedType}en som en bild.</>}
        icon={<Icon name={'FileDownloadOutlined'} />}
        title={'Bildexport'}
        titleAdornments={<code>?export=true</code>}
      />
    </SettingsGroup>
  )
}

export default Features
