import React from 'react'

import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return <Skeleton variant="rounded" height={48} width={148} />
}

Loading.displayName = 'Loading'

export default Loading
