import React, { useEffect, useState, useMemo, useRef, ReactNode } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { ThemeProvider, useMediaQuery, useTheme } from '@mui/material'
import { QueryParams } from 'redux/api/Widgets'
import { getTheme } from 'themes'
import { sortGridLayout } from 'utils/sortHelper'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { ApplicationState } from 'redux/Stores/types'

import * as AccountActions from 'redux/actions/Accounts'
import * as AdditionalSegmentActions from 'redux/actions/AdditionalSegments'
import * as DashboardActions from 'redux/actions/Dashboards'
import * as DashboardFolderActions from 'redux/actions/DashboardFolders'
import * as DashboardGroupActions from 'redux/actions/DashboardGroups'
import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as SIMActions from 'redux/actions/SIM'
import * as TagOptionActions from 'redux/actions/TagOptions'
import * as KpiOptionActions from 'redux/actions/KpiOptions'
import * as UtilActions from 'redux/actions/Utilities'
import * as WidgetActions from 'redux/actions/Widgets'
import * as WidgetTagActions from 'redux/actions/WidgetTags'

import {
  CustomPeriodFilter,
  FormattedWidgetData,
  FunnelStagePutBody,
  InnerWidgetPatchBody,
  ParsedSegmentPath,
  PeriodFilter,
  SegmentPeriod,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import {
  KpiOptionPatchAttributes,
  KpiOptionPostAttributes
} from 'types/GlobalKpiOption'
import {
  WidgetTagPatchAttributes,
  WidgetTagPostAttributes
} from 'types/GlobalWidgetTag'
import { AccountRole } from 'types/GlobalUser'
import { DashboardFilter } from 'types/GlobalDashboardFilter'

import {
  getFilterFromSegmentPaths,
  isCustomPeriodFilter,
  isPeriodFilterEnum
} from 'utils/functions'

import {
  getWidgetQueryParams,
  downloadPNG,
  formatWidgetData,
  convertWidgetDataToExport,
  getDetailsQueryParams
} from './utils'

import Box from 'components_new/atoms/Box'

import DashboardGrid from 'components_new/organisms/DashboardGrid'
import EditModeBar from 'components_new/organisms/EditModeBar'
import MobileTabSelector from 'components_new/organisms/MobileTabSelector'
import PublishBar from 'components_new/organisms/PublishBar'
import UnderlyingContentDialog from 'components_new/organisms/UnderlyingContentDialog'
import Widget from 'components_new/organisms/Widget'

import DesktopLoading from './desktop/loading'
import MobileLoading from './mobile/loading'

interface DashboardProps {
  customSegmentByMapper: { [widgetId: string]: string | null }
  displayTabs?: boolean // only for mobile
  editMode: boolean
  gridRef: any | null
  dashboardFilter: DashboardFilter
  periodFilter: PeriodFilter | CustomPeriodFilter | null
  resetAllTempStates: () => void
  setCustomSegmentBy: (
    widgetId: string,
    attributeOptionId: string | null,
    dbFilter?: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  setDashboardFilter: (
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  setPeriodFilter: (value: PeriodFilter | CustomPeriodFilter | null) => void
  variant: {
    embedded: boolean
    type: 'api' | 'portal' | 'public'
  }
}

const Dashboard = (props: ComponentProps) => {
  const {
    customSegmentByMapper,
    displayTabs,
    editMode,
    gridRef,
    dashboardFilter,
    periodFilter,
    resetAllTempStates,
    setCustomSegmentBy,
    setDashboardFilter,
    setPeriodFilter,
    variant,
    // redux stores:
    AccountStore,
    AuthStore,
    CompanyGroupStore,
    CustomizationStore,
    DashboardFilterOptionsStore,
    KPIDashboardStore,
    KPITemplateStore,
    TagOptionStore,
    // redux actions:
    tryCreateAdditionalSegmentBy,
    tryCreateKpiOption,
    tryCreateWidgetTag,
    tryExportDataXlsx,
    tryGetAllTagOptions,
    tryDeleteAdditionalSegmentBy,
    tryDeleteKpiOption,
    tryDeleteWidgetTag,
    tryUpdateKpiOption,
    tryUpdateWidgetTag,
    tryPutFunnelStages,
    tryUpdateAdditionalSegmentBy,
    tryUpdateDashboardLayout,
    tryUpdateWidget,
    tryUploadDashboardThumbnail,
    tryUploadWidgetImage
  } = props

  const params = useParams<{ id: string }>()
  const history = useHistory()
  const [underlyingContent, setUnderlyingContent] = useState<{
    widget: WidgetObject
    initialKpiId: string
    segmentLabel: string | number | null
    queryParams: QueryParams | null
  } | null>(null)

  const signedInUser = AuthStore.user
  const isAdmin =
    signedInUser?.role === AccountRole.ADMIN || !!signedInUser?.is_homepal_user

  useEffect(() => {
    const { tryGetAllKPIs, tryGetAllFilterOptions } = props

    if (
      variant.type !== 'public' &&
      !KPITemplateStore.fetchedKpis &&
      !KPITemplateStore.fetchingKpis
    ) {
      tryGetAllKPIs()
    }

    if (
      !variant.embedded &&
      !KPITemplateStore.fetchedFilterOptions &&
      !KPITemplateStore.fetchingFilterOptions
    ) {
      tryGetAllFilterOptions()
    }

    if (
      !variant.embedded &&
      !TagOptionStore.fetched &&
      !TagOptionStore.fetching
    ) {
      tryGetAllTagOptions()
    }
  }, [])

  useEffect(() => {
    const { tryGetOneDashboard } = props

    if (params.id !== KPIDashboardStore.activeFetchOne) {
      tryGetOneDashboard(params.id, () => history.push('/dashboards'))
    }
  }, [params.id])

  const dashboard = KPIDashboardStore.data[params.id]
  const widgets = dashboard?.widgets || []

  // Needs to be a ref
  const isDownloadReadyRef = useRef(false)

  useEffect(() => {
    const widgetArray = Object.values(widgets)
    const allFetched = widgetArray.every(
      (widget) =>
        widget.data ||
        [WidgetType.TEXT, WidgetType.COMMENT, WidgetType.IMAGE].includes(
          widget.settings.type.selected
        ) ||
        widget.status.broken
    )

    isDownloadReadyRef.current = widgetArray.length > 0 && allFetched
  }, [widgets, params.id])

  useEffect(() => {
    // Listen to route changes
    const unlisten = history.listen(() => {
      // Save the thumbnail before the route changes
      if (gridRef.current && isDownloadReadyRef.current) {
        downloadPNG(gridRef.current, (thumbnail: Blob | null) => {
          if (thumbnail && !AuthStore?.user?.is_company_group) {
            tryUploadDashboardThumbnail(dashboard.id, thumbnail)
          }
        })
      }
    })

    // Clean up event listener when the component unmounts
    return () => {
      unlisten()
    }
  }, [dashboard?.id])

  // We need to rebuild this.
  useEffect(() => {
    // Fetch data for new/edited widgets
    const { tryGetOneWidget } = props

    if (KPIDashboardStore.currentWidgetId && dashboard) {
      const widget = dashboard?.widgets[KPIDashboardStore.currentWidgetId]

      tryGetOneWidget(
        widget.id,
        widget.settings.type.selected,
        widget.settings.show_total,
        widget.settings.segment_by.additional,
        dashboard.id,
        getWidgetQueryParams(
          widget,
          dashboardFilter,
          periodFilter,
          CompanyGroupStore.showAs
        )
      )
    }
  }, [KPIDashboardStore.currentWidgetId])

  const [dashboardWidgets, setWidgets] = useState<string[]>([])

  useEffect(() => {
    if (Boolean(widgets)) {
      setWidgets(Object.values(widgets).map((widget) => widget.id))
    }
  }, [widgets])

  const formattedWidgets = useMemo(() => {
    return dashboardWidgets.map((id) => {
      const widget = widgets[id]

      if (!widget) {
        return null
      }

      return {
        id,
        data: formatWidgetData(widget),
        status: widget.status,
        widget,
        type: widget.settings.type.selected
      }
    })
  }, [dashboardWidgets, widgets])

  const layout: { x: number; y: number; w: number; h: number; i: string }[] =
    useMemo(() => {
      const newLayout = dashboardWidgets
        .filter((id: string) => widgets[id])
        .map((id: string) => {
          const widget = widgets[id]

          return {
            x: widget.layout.x,
            y: widget.layout.y,
            w: widget.layout.width,
            h: widget.layout.height,
            i: id
          }
        })

      return newLayout
    }, [dashboardWidgets])

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  const widgetHeight = useMemo(() => {
    if (!isMobile) return undefined

    return 256
  }, [isMobile, dashboardWidgets])

  // loading
  if (
    KPIDashboardStore.activeFetchOne !== params.id ||
    !KPIDashboardStore.fetched ||
    (!variant.embedded && !AccountStore.fetched)
  ) {
    return isMobile ? (
      <MobileLoading colors={CustomizationStore?.colors} />
    ) : (
      <DesktopLoading colors={CustomizationStore?.colors} />
    )
  }

  // success
  return (
    <ContentContainer displayTabs={displayTabs} isMobile={isMobile}>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {isMobile ? null : (
          <>
            <PublishBar editMode={editMode} />
            <EditModeBar
              availableSpace={dashboard.available_space ?? false}
              editMode={editMode}
            />
          </>
        )}
        <Box
          sx={{
            flex: '1 1 auto',
            minWidth: 0,
            position: 'relative',
            height: '100%',
            width: '100%',
            borderLeft: editMode ? '4px solid' : undefined,
            borderBottom: editMode ? '4px solid' : undefined,
            borderRight: editMode ? '4px solid' : undefined,
            borderColor: 'brand.background'
          }}
        >
          <DashboardGrid
            colors={
              CustomizationStore?.colors ?? dashboard.white_label_settings
            }
            editable={editMode}
            gridRef={gridRef}
            layout={layout}
            title={dashboard.title}
            updateLayout={(layout) =>
              tryUpdateDashboardLayout(dashboard.id, {
                data: {
                  layout: layout.map((l) => ({
                    width: l.w,
                    height: l.h,
                    x: l.x,
                    y: l.y,
                    id: l.i
                  }))
                }
              })
            }
            variant={variant}
          >
            {({ scaleFactor }) => {
              return formattedWidgets
                .sort((a, b) =>
                  sortGridLayout(a?.widget.layout, b?.widget.layout)
                )
                .map(
                  (
                    formattedWidget: {
                      id: string
                      data: FormattedWidgetData
                      type: WidgetType
                      status: {
                        broken: boolean
                        required_data_modelling_missing: boolean
                        required_target_missing: boolean
                      }
                      widget: WidgetObject
                    } | null
                  ) => {
                    if (!formattedWidget) {
                      return null
                    }

                    const { id, data, type, status, widget } = formattedWidget
                    const customSegmentBy =
                      customSegmentByMapper[widget.id] || null

                    return (
                      <Box
                        key={id}
                        sx={{
                          position: 'relative',
                          height: widgetHeight,
                          minHeight: widgetHeight
                        }}
                      >
                        <Widget
                          availableSpace={dashboard.available_space}
                          allowBenchmarking={
                            AuthStore.customer?.allow_access.benchmarking
                          }
                          colors={
                            CustomizationStore?.colors ??
                            dashboard.white_label_settings
                          }
                          createAdditionalSegmentBy={(attributeOptionId) =>
                            tryCreateAdditionalSegmentBy(
                              {
                                widget_id: widget.id,
                                attribute_option_id: attributeOptionId
                              },
                              dashboard.id
                            )
                          }
                          createKpiOption={(body: KpiOptionPostAttributes) =>
                            tryCreateKpiOption(body, dashboard.id)
                          }
                          createWidgetTag={(body: WidgetTagPostAttributes) =>
                            tryCreateWidgetTag(body, dashboard.id, widget.id)
                          }
                          customer={AuthStore.customer}
                          customPeriodFilter={
                            periodFilter && isCustomPeriodFilter(periodFilter)
                              ? periodFilter
                              : null
                          }
                          customSegmentBy={customSegmentBy}
                          dashboardFilter={dashboardFilter}
                          // eslint-disable-next-line max-len
                          dashboardFilterOptions={
                            dashboard.dashboard_filters?.map((filter) => {
                              const option =
                                DashboardFilterOptionsStore.options.find(
                                  (opt) =>
                                    opt.relation_key === filter.relation_key
                                )

                              return {
                                attribute_id: filter.attribute_id,
                                title: option?.title || '',
                                relation_key: filter.relation_key,
                                relation_name: option?.relation_name ?? null,
                                index: filter.index,
                                options:
                                  DashboardFilterOptionsStore.data[
                                    filter.relation_key
                                  ]
                              }
                            }) ?? []
                          }
                          deleteAdditionalSegmentBy={(id) =>
                            tryDeleteAdditionalSegmentBy(
                              id,
                              widget.id,
                              dashboard.id
                            )
                          }
                          deleteKpiOption={(id: string) =>
                            tryDeleteKpiOption(id, widget.id, dashboard.id)
                          }
                          deleteWidgetTag={(id: string) =>
                            tryDeleteWidgetTag(id, widget.id, dashboard.id)
                          }
                          editMode={editMode}
                          exportWidgetData={() => {
                            const { headers, rows } = convertWidgetDataToExport(
                              widget,
                              data
                            )

                            tryExportDataXlsx(headers, rows, widget.title)
                          }}
                          fetchedKpis={KPITemplateStore.fetchedKpis}
                          filterOptions={KPITemplateStore.filterOptions}
                          formattedData={data}
                          showUnderlyingContent={(
                            segmentPaths: ParsedSegmentPath[],
                            initialKpiOptionId: string | null
                          ) => {
                            const initialId = initialKpiOptionId
                              ? initialKpiOptionId
                              : (widget.settings.kpi_options[0].id as string)

                            const segmentFilters = getFilterFromSegmentPaths(
                              widget,
                              segmentPaths,
                              initialId
                            )

                            const pathWithPeriod = segmentPaths.find(
                              (item) => item.period
                            )

                            let customPeriodFilter = undefined

                            if (pathWithPeriod) {
                              const { from_date, to_date } =
                                pathWithPeriod.period as SegmentPeriod

                              customPeriodFilter = {
                                from: from_date,
                                to: to_date
                              }
                            }

                            const contentQueryParams = getDetailsQueryParams(
                              widget,
                              dashboardFilter,
                              customPeriodFilter || periodFilter,
                              null,
                              segmentFilters,
                              CompanyGroupStore.showAs
                            )

                            setUnderlyingContent({
                              widget,
                              initialKpiId: initialId,
                              segmentLabel: segmentPaths[0]?.display_label,
                              queryParams: contentQueryParams
                            })
                          }}
                          isAdmin={isAdmin}
                          kpiTemplates={KPITemplateStore.data}
                          kpiTemplatesFetched={KPITemplateStore.fetchedKpis}
                          loading={
                            !widget.data &&
                            type !== WidgetType.TEXT &&
                            type !== WidgetType.COMMENT &&
                            type !== WidgetType.IMAGE &&
                            !status.broken
                          }
                          periodFilter={
                            periodFilter && isPeriodFilterEnum(periodFilter)
                              ? periodFilter
                              : null
                          }
                          putFunnelStages={(body: FunnelStagePutBody) =>
                            tryPutFunnelStages(widget.id, dashboard.id, body)
                          }
                          resetAllDashboardTempStates={resetAllTempStates}
                          scaleFactor={scaleFactor}
                          setCustomSegmentBy={(
                            attributeOptionId,
                            dbFilter,
                            pFilter
                          ) =>
                            setCustomSegmentBy(
                              widget.id,
                              attributeOptionId,
                              dbFilter,
                              pFilter
                            )
                          }
                          setDashboardFilter={setDashboardFilter}
                          setPeriodFilter={setPeriodFilter}
                          updateAdditionalSegmentBy={(id, attributeOptionId) =>
                            tryUpdateAdditionalSegmentBy(
                              id,
                              { attribute_option_id: attributeOptionId },
                              dashboard.id
                            )
                          }
                          updateKpiOption={(
                            id: string,
                            body: KpiOptionPatchAttributes
                          ) => tryUpdateKpiOption(id, body, dashboard.id)}
                          updateWidget={(
                            widgetId: string,
                            attribute: InnerWidgetPatchBody
                          ) => {
                            tryUpdateWidget(
                              widgetId,
                              { data: { ...attribute } },
                              !!periodFilter
                            )
                          }}
                          updateWidgetTag={(
                            id: string,
                            body: WidgetTagPatchAttributes
                          ) =>
                            tryUpdateWidgetTag(
                              id,
                              body,
                              dashboard.id,
                              widget.id
                            )
                          }
                          uploadImage={(image: Blob, name: string) =>
                            tryUploadWidgetImage(widget.id, image, name)
                          }
                          widget={widget}
                        />
                      </Box>
                    )
                  }
                )
                .filter(Boolean)
            }}
          </DashboardGrid>
        </Box>

        {/*-- underlying data dialog --*/}
        <ThemeProvider theme={getTheme('light')}>
          <UnderlyingContentDialog
            content={underlyingContent}
            onClose={() => setUnderlyingContent(null)}
            open={Boolean(underlyingContent)}
          />
        </ThemeProvider>
      </Box>
    </ContentContainer>
  )
}

interface ContentContainerProps {
  children: ReactNode
  displayTabs?: boolean
  isMobile: boolean
}

// Should later be included in- and replaced by desktop and mobile files:
const ContentContainer = (props: ContentContainerProps) => {
  const { children, displayTabs, isMobile } = props

  if (isMobile) {
    return (
      <Box sx={{ minHeight: '100%' }}>
        {displayTabs ? <MobileTabSelector /> : null}
        {children}
      </Box>
    )
  } else {
    return <>{children}</>
  }
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  CompanyGroupStore: state.CompanyGroupStore,
  CustomizationStore: state.CustomizationStore,
  DashboardFilterOptionsStore: state.DashboardFilterOptionsStore,
  KPIDashboardStore: state.KPIDashboardStore,
  KPITemplateStore: state.KPITemplateStore,
  NavigationMenuStore: state.NavigationMenuStore,
  TagOptionStore: state.TagOptionStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AdditionalSegmentActions,
      ...AccountActions,
      ...DashboardActions,
      ...DashboardFolderActions,
      ...DashboardGroupActions,
      ...KPITemplateActions,
      ...SIMActions,
      ...TagOptionActions,
      ...UtilActions,
      ...WidgetActions,
      ...WidgetTagActions,
      ...KpiOptionActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type ComponentProps = ConnectedProps<typeof connector> & DashboardProps

export default connector(Dashboard)
