import React, { FC, useEffect, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useParams, useLocation } from 'react-router-dom'

import { ApplicationState } from 'redux/Stores/types'

import * as DashboardActions from 'redux/actions/Dashboards'
import * as WidgetActions from 'redux/actions/Widgets'
import * as StatusBannerActions from 'redux/actions/StatusBanner'

import { DashboardFilter } from 'types/GlobalDashboardFilter'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'

import { LOADING_WIDGET_TITLES } from 'utils/loadingTitles'
import { getDataForWidgets } from './utils'
import { STATUS_BANNER_TIMER } from 'utils/constants'
import { parseQueryParamToNumber } from 'utils/queryHandler'

import Box from 'components_new/atoms/Box'

import LoadingSection from 'components_new/molecules/LoadingSection'

import Dashboard from 'components_new/organisms/Dashboard'

import PublicDashboardTemplate from 'components_new/templates/PublicDashboardTemplate'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPIDashboardStore: state.KPIDashboardStore,
  StatusBannerStore: state.StatusBannerStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions,
      ...StatusBannerActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type PublicDashboardPageProps = ConnectedProps<typeof connector>

const PublicDashboardPage: FC<PublicDashboardPageProps> = (
  props: PublicDashboardPageProps
) => {
  const [dataFetched, setDataFetched] = useState(false)
  const [dashboardFilter, setDashboardFilter] = useState<DashboardFilter>([])
  const [periodFilter, setPeriodFilter] = useState<
    PeriodFilter | CustomPeriodFilter | null
  >(null)

  const { KPIDashboardStore } = props

  const params = useParams<{ id: string }>()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)

  const mobileBreakpoint = parseQueryParamToNumber(
    queryParams.get('mobile_breakpoint')
  )
  const mobileResponsive = queryParams.get('responsive_mode') === 'mobile'

  const dashboard = KPIDashboardStore.data[params.id]

  useEffect(() => {
    const { tryGetOnePublicDashboard } = props

    tryGetOnePublicDashboard(params.id, () => {})
  }, [])

  useEffect(() => {
    if (dashboard && !dataFetched) {
      setDataFetched(true)
      getDataForWidgets(props, dashboard, [], periodFilter, location.search)
    }
  }, [KPIDashboardStore.data])

  useEffect(() => {
    if (dashboard?.customer_id) {
      props.tryGetStatusBanner(dashboard.customer_id, true)

      const interval = setInterval(
        () => props.tryGetStatusBanner(dashboard.customer_id as string, true),
        STATUS_BANNER_TIMER
      )

      return () => clearInterval(interval)
    }

    return () => {}
  }, [dashboard?.customer_id])

  const handleResetAllTempStates = () => {
    setDashboardFilter([])
    setPeriodFilter(null)

    getDataForWidgets(props, dashboard, [], null, location.search)
  }

  const handleSetPeriodFilter = (
    period: PeriodFilter | CustomPeriodFilter | null
  ) => {
    getDataForWidgets(
      props,
      dashboard,
      dashboardFilter,
      period,
      location.search
    )

    setPeriodFilter(period)
  }

  const handleSetDashboardFilter = (
    newDashboardFilter: DashboardFilter,
    newPeriodFilter?: CustomPeriodFilter
  ) => {
    const period =
      newPeriodFilter === undefined ? periodFilter : newPeriodFilter

    getDataForWidgets(
      props,
      dashboard,
      newDashboardFilter,
      period,
      location.search
    )

    setDashboardFilter(newDashboardFilter)

    if (newPeriodFilter !== undefined) {
      setPeriodFilter(newPeriodFilter)
    }
  }

  if (KPIDashboardStore.activeFetchOne !== params.id) {
    // check for loading or error
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {KPIDashboardStore.errorFetchOne === params.id ? (
          <p>Det går inte att hämta dashboarden.</p>
        ) : (
          <LoadingSection titles={LOADING_WIDGET_TITLES} loading={true} />
        )}
      </Box>
    )
  }

  return (
    <PublicDashboardTemplate
      bgcolor={dashboard.white_label_settings?.background_color}
      dashboardFilter={dashboardFilter}
      // Image export not available in public dashboard:
      gridRef={null}
      mobileBreakpoint={mobileBreakpoint}
      mobileResponsive={mobileResponsive}
      periodFilter={periodFilter}
      resetAllTempStates={handleResetAllTempStates}
      setDashboardFilter={handleSetDashboardFilter}
      setPeriodFilter={handleSetPeriodFilter}
    >
      <Dashboard
        // Segment by not available in public dashboard:
        customSegmentByMapper={{}}
        dashboardFilter={dashboardFilter}
        editMode={false}
        // Image export not available in public dashboard:
        gridRef={null}
        periodFilter={periodFilter}
        resetAllTempStates={handleResetAllTempStates}
        setDashboardFilter={handleSetDashboardFilter}
        setPeriodFilter={handleSetPeriodFilter}
        // Segment by not available in public dashboard:
        setCustomSegmentBy={() => {}}
        variant={{
          embedded: true,
          type: 'public'
        }}
      />
    </PublicDashboardTemplate>
  )
}

export default connector(PublicDashboardPage)
