import React, { MouseEvent, ReactNode, useEffect, useState } from 'react'
import html2canvas from 'html2canvas'
import { useParams } from 'react-router-dom'

import { getTheme } from 'themes'
import { ThemeProvider, useMediaQuery, useTheme } from '@mui/material'

import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'
import Text from 'components_new/atoms/Text'
import ToggleButton from 'components_new/atoms/ToggleButton'

import Desktop from './desktop'
import Mobile from './mobile'

interface ExportDialogProps {
  gridRef: any | null
  onClose: () => void
  onOpen: () => void
  open: boolean
}

export const IGNORE_ON_ALL_EXPORT = 'IGNORE_ON_ALL_EXPORT'
export const IGNORE_ON_TRANSPARENT_EXPORT = 'IGNORE_ON_TRANSPARENT_EXPORT'

const ExportDialog = (props: ComponentProps) => {
  const {
    gridRef,
    onClose,
    open,
    /*-- redux --*/
    KPIDashboardStore
  } = props

  const params = useParams<{ id: string }>()
  const dashboard = KPIDashboardStore.data[params.id]

  const [fileType, setFileType] = useState<'png' | 'jpeg'>('png')
  const [scale, setScale] = useState<number>(1)

  const [showDownloadMessage, setShowDownloadMessage] = useState<boolean>(false)

  const handleClose = () => {
    onClose()
  }

  const handleOpen = () => {
    onClose()
  }

  const downloadImage = (element: HTMLElement) => {
    const removedChildren: (HTMLTextAreaElement | HTMLParagraphElement)[][] = []

    // Replace textareas cause line break does not work.
    element.querySelectorAll('textarea').forEach((textarea) => {
      if (textarea.firstChild) {
        const div = document.createElement('div')

        while (textarea.firstChild) {
          div.appendChild(textarea.removeChild(textarea.firstChild))
        }
        removedChildren.push([div, textarea])

        div.style.overflowWrap = 'anywhere'

        const parent = textarea.parentNode

        if (parent) {
          parent.replaceChild(div, textarea)
        }
      }
    })

    html2canvas(element, {
      allowTaint: true,
      backgroundColor: fileType === 'png' ? null : '#f8fafc', // grey.100
      useCORS: true,
      logging: false,
      scale: scale,
      ignoreElements:
        fileType === 'png'
          ? (element) => {
              return (
                element.id === IGNORE_ON_ALL_EXPORT ||
                element.id === IGNORE_ON_TRANSPARENT_EXPORT
              )
            }
          : fileType === 'jpeg'
            ? (element) => {
                return element.id === IGNORE_ON_ALL_EXPORT
              }
            : undefined
    })
      .then((canvas) => {
        const downloadUrl = canvas.toDataURL(`image/${fileType}`)
        const link = document.createElement('a')

        link.href = downloadUrl
        link.download = `${dashboard?.title}.${fileType}`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        // Append textareas again
        removedChildren.forEach(([div, textarea]) => {
          while (div.firstChild) {
            textarea.appendChild(div.removeChild(div.firstChild))
          }

          const parent = div.parentNode

          if (parent) {
            parent.replaceChild(textarea, div)
          }
        })

        setShowDownloadMessage(true)
      })
      .catch(() => {
        // Append textareas again
        removedChildren.forEach(([div, textarea]) => {
          while (div.firstChild) {
            textarea.appendChild(div.removeChild(div.firstChild))
          }

          const parent = div.parentNode

          if (parent) {
            parent.replaceChild(textarea, div)
          }
        })
      })
  }

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (gridRef.current) setWidth(gridRef.current.offsetWidth * scale)
    if (gridRef.current) setHeight(gridRef.current.offsetHeight * scale)
  }, [scale, gridRef.current])

  /*-- dialog / drawer --*/
  return (
    <ThemeProvider theme={getTheme('light')}>
      <ContentWrapper
        onOpen={handleOpen}
        onClose={handleClose}
        open={open}
        title="Exportera"
      >
        <Box>
          <Text variant="subtitle2" gutterBottom>
            Variant
          </Text>
          <ToggleButton
            color={'primary'}
            exclusive={true}
            fullWidth={true}
            items={[
              {
                title: 'Transparent',
                iconName: 'PhotoLibraryOutlined',
                value: 'png'
              },
              {
                title: 'Bakgrund',
                iconName: 'PhotoLibrary',
                value: 'jpeg'
              }
            ]}
            value={fileType}
            onChange={(
              event: MouseEvent<HTMLElement>,
              value: 'png' | 'jpeg'
            ) => {
              if (!value) {
                return
              }
              setFileType(value)
            }}
          />
          <Text color="text.secondary" variant="caption">
            Format: .{fileType}
          </Text>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Text variant="subtitle2" gutterBottom>
            Kvalitet
          </Text>
          <ToggleButton
            color={'primary'}
            exclusive={true}
            fullWidth={true}
            items={[
              { title: 'Låg', value: 0.5 },
              { title: 'Mellan', value: 1 },
              { title: 'Hög', value: 2 }
            ]}
            value={scale}
            onChange={(event: MouseEvent<HTMLElement>, value: number) => {
              if (!value) {
                return
              }
              setScale(value)
            }}
          />
          <Text color="text.secondary" variant="caption">
            Upplösning: {width} x {height} pixlar
          </Text>
        </Box>
        <Button
          fullWidth={true}
          onClick={() => {
            downloadImage(gridRef.current)
          }}
          startIcon={<Icon name="FileDownload" />}
          sx={{ mt: 2 }}
        >
          Exportera
        </Button>
      </ContentWrapper>

      {/*-- feedback --*/}
      <Portal>
        <Snackbar
          open={showDownloadMessage}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setShowDownloadMessage(false)
            }
          }}
          message={`${dashboard?.title}.${fileType} har exporterats`}
        />
      </Portal>
    </ThemeProvider>
  )
}

interface ContentWrapperProps {
  children: ReactNode
  onClose: () => void
  onOpen: () => void
  open: boolean
  title: string
}

const ContentWrapper = (props: ContentWrapperProps) => {
  const { children, onClose, onOpen, open, title } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  return isMobile ? (
    <Mobile onClose={onClose} onOpen={onOpen} open={open} title={title}>
      {children}
    </Mobile>
  ) : (
    <Desktop onClose={onClose} open={open} title={title}>
      {children}
    </Desktop>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  KPIDashboardStore: state.KPIDashboardStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> & ExportDialogProps

export default connector(ExportDialog)
