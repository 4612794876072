import React, { ReactNode } from 'react'
import { SwipeableDrawer as MUISwipeableDrawer } from '@mui/material'

interface SwipeableDrawerProps {
  anchor?: 'bottom' | 'left' | 'right' | 'top'
  children?: ReactNode
  disableBackdropTransition?: boolean
  disableDiscovery?: boolean
  disableSwipeToOpen?: boolean
  elevation?: number
  hideBackdrop?: boolean
  ModalProps?: object
  onClose: () => void
  onOpen: () => void
  open?: boolean
  PaperProps?: object
  SlideProps?: object
  swipeAreaWidth?: number
  sx?: object
  variant?: 'permanent' | 'persistent' | 'temporary'
}

const SwipeableDrawer = React.forwardRef(
  (props: SwipeableDrawerProps, ref: any) => {
    return (
      <MUISwipeableDrawer {...props} ref={ref}>
        {props.children}
      </MUISwipeableDrawer>
    )
  }
)

SwipeableDrawer.displayName = 'SwipeableDrawer'
export default SwipeableDrawer
