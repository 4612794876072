import { createAction, createReducer } from '@reduxjs/toolkit'

import * as Types from 'redux/Types'
import { TrackingUser } from 'types/GlobalTracking'

export interface TrackingReducerType {
  usersUsage: TrackingUser[]
  usersUsageFeteched: boolean
  oneUserUsage: {
    [key: string]: TrackingUser
  }
}

// Initial state
const INITIAL_STATE: TrackingReducerType = {
  usersUsage: [],
  usersUsageFeteched: false,
  oneUserUsage: {}
}

// Actions
const getUsersUsage = createAction<{ data: any[] }>(
  Types.MEASUREMENTS_GET_USERS_USAGE_SUCCESS
)
const getOneUserUsage = createAction<{ data: any }>(
  Types.MEASUREMENTS_GET_ONE_USER_USAGE_SUCCESS
)
const updateUser = createAction<{ data: any }>(
  Types.MEASUREMENTS_UPDATE_USER_USAGE_SUCCESS
)
const signOutAction = createAction(Types.SIGN_OUT)

// Reducer
const dashboardReducer = createReducer(INITIAL_STATE, (builder: any) => {
  builder
    .addCase(getUsersUsage, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        usersUsage: payload,
        usersUsageFetched: true
      }
    })
    .addCase(getOneUserUsage, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        oneUserUsage: {
          ...state.oneUserUsage,
          [payload.id]: payload
        }
      }
    })
    .addCase(updateUser, (state: any, action: any) => {
      const { payload } = action

      return {
        ...state,
        usersUsage: state.usersUsage.map((user: TrackingUser) => {
          if (user.id === payload.id) {
            return {
              ...user,
              title: payload.title,
              ignore_from_statistics: payload.ignore_from_statistics
            }
          }

          return user
        }),
        oneUserUsage: {
          ...state.oneUserUsage,
          [payload.id]: {
            ...state.oneUserUsage[payload.id],
            title: payload.title,
            ignore_from_statistics: payload.ignore_from_statistics
          }
        }
      }
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state: any) => state)
})

export default dashboardReducer
