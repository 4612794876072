import React, { ReactNode } from 'react'

import SimpleSwipeableDrawer from 'components_new/molecules/SimpleSwipeableDrawer'

interface MobileProps {
  children: ReactNode
  onClose: () => void
  onOpen: () => void
  open: boolean
  title: string
}

const Mobile = (props: MobileProps) => {
  const { children, onClose, onOpen, open, title } = props

  return (
    <>
      <SimpleSwipeableDrawer
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        title={title}
      >
        {children}
      </SimpleSwipeableDrawer>
    </>
  )
}

export default Mobile
