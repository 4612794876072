import React, { useState } from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { useParams } from 'react-router-dom'

import * as WidgetActions from 'redux/actions/Widgets'
import { ApplicationState } from 'redux/Stores/types'
import { LayoutItem } from 'redux/reducers/Dashboards'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Collapse from 'components_new/atoms/Collapse'

import LoadingSection from 'components_new/molecules/LoadingSection'
import SimpleDialog from 'components_new/molecules/SimpleDialog'
import DashboardTreeSelector from 'components_new/organisms/DashboardTreeSelector'

import {
  FormattedWidgetData,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

import PreviewWidget from './PreviewWidget'

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  CustomizationStore: state.CustomizationStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

interface CopyToDashboardDialog {
  formattedData: FormattedWidgetData
  open: boolean
  onClose: () => void
  widget: WidgetObject
}

type ComponentProps = ConnectedProps<typeof connector> & CopyToDashboardDialog

const CopyToDashboardDialog = (props: ComponentProps) => {
  const {
    CustomizationStore,
    formattedData,
    KPIDashboardStore,
    onClose,
    open,
    tryCreateWidget,
    widget
  } = props
  const [copying, setCopying] = useState<boolean>(false)
  const [dashboard, setDashboard] = useState<string | null>(null)
  const { id: currentDashboardId } = useParams<{ id: string }>()
  const { colors } = CustomizationStore

  const handleCopy = () => {
    setCopying(true)

    if (dashboard) {
      const selectedDashboard = KPIDashboardStore.data[dashboard]
      const availableSpace = selectedDashboard.available_space as LayoutItem
      const skipFetchData = currentDashboardId !== dashboard

      tryCreateWidget(
        {
          data: {
            dashboard_id: dashboard,
            kpi_id: null,
            widget_id: widget.id,
            width: availableSpace.w,
            height:
              widget.settings.type.selected === WidgetType.TEXT
                ? 1
                : availableSpace.h,
            x: availableSpace.x,
            y: availableSpace.y
          }
        },
        skipFetchData,
        (err) => {
          if (!err) {
            setDashboard(null)
            onClose()
          }
          setCopying(false)
        }
      )
    }

    setDashboard(null)
  }

  const handleCloseDialog = () => {
    onClose()
    setCopying(false)
    setDashboard(null)
  }

  return (
    <>
      {/*-- menus --*/}
      <SimpleDialog
        actions={
          copying ? null : (
            <>
              <Button
                disabled={copying}
                onClick={handleCloseDialog}
                variant="text"
              >
                Avbryt
              </Button>
              <Button disabled={copying || !dashboard} onClick={handleCopy}>
                Kopiera till
              </Button>
            </>
          )
        }
        contentText={
          copying
            ? undefined
            : 'Välj den dashboard som du vill att din widget ska kopieras till.'
        }
        maxWidth="xs"
        onClose={handleCloseDialog}
        open={open}
        title="Kopiera till"
      >
        <Box
          sx={{
            position: 'relative'
          }}
        >
          {copying ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
                bgcolor: 'rgba(255, 255, 255, 0.92)'
              }}
            >
              <LoadingSection
                loading={true}
                sx={{ py: 2 }}
                titles={['Kopierar till dashboard']}
              />
            </Box>
          ) : null}

          <PreviewWidget
            colors={colors}
            formattedData={formattedData}
            widget={widget}
          />

          <Collapse in={!copying}>
            <DashboardTreeSelector
              selected={dashboard}
              setSelected={setDashboard}
              type="tab"
            />
          </Collapse>
        </Box>
      </SimpleDialog>
    </>
  )
}

export default connector(CopyToDashboardDialog)
