import React from 'react'

import { SxProps } from '@mui/material'

import { DashboardGroup } from 'redux/reducers/Dashboards'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import OverflowText from 'components_new/molecules/OverflowText'

interface MobileProps {
  group: DashboardGroup
  sx?: SxProps
}

const Mobile = (props: MobileProps) => {
  const { group, sx } = props

  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: 1,
          minWidth: 0,
          ...sx
        }}
      >
        <IconButton goBack={true}>
          <Icon name="ArrowBack" />
        </IconButton>
        <OverflowText color="text.primary" variant="subtitle1">
          {group.title}
        </OverflowText>
      </Box>
    </>
  )
}

Mobile.displayName = 'Mobile'

export default Mobile
