import React from 'react'

import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'
import { DashboardFilter } from 'types/GlobalDashboardFilter'

import { useMediaQuery, useTheme } from '@mui/material'

import Desktop from './desktop'
import Mobile from './mobile'

export interface DashboardUserFilterBarProps {
  bgcolor?: string | null
  dashboardFilter: DashboardFilter
  periodFilter: PeriodFilter | CustomPeriodFilter | null
  resetAllTempStates: () => void
  setDashboardFilter: (value: DashboardFilter) => void
  setPeriodFilter:
    | ((value: PeriodFilter | CustomPeriodFilter | null) => void)
    | ((value: CustomPeriodFilter | null) => void)
  sx?: object
}

const DashboardUserFilterBar = (props: DashboardUserFilterBarProps) => {
  const {
    bgcolor,
    dashboardFilter,
    periodFilter,
    resetAllTempStates,
    setDashboardFilter,
    setPeriodFilter,
    sx
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  // success
  return isMobile ? (
    <Mobile
      bgcolor={bgcolor}
      dashboardFilter={dashboardFilter}
      periodFilter={periodFilter}
      resetAllTempStates={resetAllTempStates}
      setDashboardFilter={setDashboardFilter}
      setPeriodFilter={setPeriodFilter}
      sx={sx}
    />
  ) : (
    <Desktop
      dashboardFilter={dashboardFilter}
      periodFilter={periodFilter}
      resetAllTempStates={resetAllTempStates}
      setDashboardFilter={setDashboardFilter}
      setPeriodFilter={setPeriodFilter}
      sx={sx}
    />
  )
}

export default DashboardUserFilterBar
