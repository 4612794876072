import React, { SyntheticEvent, useEffect, useState } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { getNavigationItems } from './utils'
import { ApplicationState } from 'redux/Stores/types'
import { connect, ConnectedProps } from 'react-redux'

import BottomNavigation from 'components_new/atoms/BottomNavigation'
import BottomNavigationAction from 'components_new/atoms/BottomNavigationAction'
import Icon from 'components_new/atoms/Icon'

const GlobalMobileNavigation = (props: ComponentProps) => {
  const { AuthStore } = props

  const history = useHistory()
  const location = useLocation()

  const [value, setValue] = useState<string>(location.pathname ?? '/dashboards')

  useEffect(() => {
    if (location.pathname.includes('/dashboards/folders')) {
      setValue('/dashboards')
    } else {
      setValue(location.pathname)
    }
  }, [location])

  const handleOnChange = (event: SyntheticEvent, newValue: any) => {
    history.push(newValue)
  }

  return (
    <BottomNavigation
      showLabels={true}
      value={value}
      onChange={handleOnChange}
      sx={{
        height: '100%'
      }}
    >
      {getNavigationItems(AuthStore).map((item) => {
        if (item.hidden) return null

        return (
          <BottomNavigationAction
            key={item.value}
            label={item.label}
            icon={<Icon name={item.iconName} />}
            value={item.value}
          />
        )
      })}
    </BottomNavigation>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore
})

const connector = connect(mapStateToProps)

export type ComponentProps = ConnectedProps<typeof connector>

export default connector(GlobalMobileNavigation)
