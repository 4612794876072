import React, { FC, useEffect, useRef, useState } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { useParams, useLocation } from 'react-router-dom'

import { ApplicationState } from 'redux/Stores/types'

import * as DashboardActions from 'redux/actions/Dashboards'
import * as KPITemplatesActions from 'redux/actions/KPITemplates'
import * as StatusBannerActions from 'redux/actions/StatusBanner'
import * as UtilityActions from 'redux/actions/Utilities'
import * as WidgetActions from 'redux/actions/Widgets'

import { LOADING_WIDGET_TITLES } from 'utils/loadingTitles'
import { getDataForWidgets } from './utils'
import { DashboardFilter } from 'types/GlobalDashboardFilter'
import { CustomPeriodFilter, PeriodFilter } from 'types/GlobalWidget'

import Box from 'components_new/atoms/Box'

import LoadingSection from 'components_new/molecules/LoadingSection'

import Dashboard from 'components_new/organisms/Dashboard'

import PublicDashboardTemplate from 'components_new/templates/PublicDashboardTemplate'

import { parseQueryParamToNumber } from 'utils/queryHandler'
import { STATUS_BANNER_TIMER } from 'utils/constants'

const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  KPIDashboardStore: state.KPIDashboardStore,
  KPITemplateStore: state.KPITemplateStore,
  StatusBannerStore: state.StatusBannerStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...DashboardActions,
      ...KPITemplatesActions,
      ...StatusBannerActions,
      ...UtilityActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type ApiAccessDashboardPageProps = ConnectedProps<typeof connector>

const ApiAccessDashboardPage: FC<ApiAccessDashboardPageProps> = (
  props: ApiAccessDashboardPageProps
) => {
  const { KPIDashboardStore, KPITemplateStore, tryGetAllKPIs } = props

  const [dataFetched, setDataFetched] = useState(false)
  const [dashboardFilter, setDashboardFilter] = useState<DashboardFilter>([])
  const [periodFilter, setPeriodFilter] = useState<
    PeriodFilter | CustomPeriodFilter | null
  >(null)
  const [customSegmentByMapper, setCustomSegmentByMapper] = useState<{
    [widgetId: string]: string | null
  }>({})
  const gridRef = useRef(null)

  const params = useParams<{ id: string }>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const allowDashboardFilter = queryParams.get('filter') === 'true'
  const allowExport = queryParams.get('export') === 'true'
  const mobileBreakpoint = parseQueryParamToNumber(
    queryParams.get('mobile_breakpoint')
  )
  const mobileResponsive = queryParams.get('responsive_mode') === 'mobile'
  const token = queryParams.get('token') as string

  const dashboard = KPIDashboardStore.data[params.id]

  useEffect(() => {
    const { tryGetOneApiAccessDashboard } = props

    tryGetOneApiAccessDashboard(params.id)

    if (!KPITemplateStore.fetchedKpis && !KPITemplateStore.fetchingKpis) {
      tryGetAllKPIs()
    }
  }, [])

  useEffect(() => {
    if (dashboard && !dataFetched) {
      setDataFetched(true)
      getDataForWidgets(
        props,
        token,
        dashboard,
        [],
        periodFilter,
        customSegmentByMapper,
        undefined,
        true
      )
    }
  }, [KPIDashboardStore.data])

  useEffect(() => {
    if (dashboard?.customer_id) {
      props.tryGetStatusBanner(dashboard.customer_id, true)

      const interval = setInterval(
        () => props.tryGetStatusBanner(dashboard.customer_id as string, true),
        STATUS_BANNER_TIMER
      )

      return () => clearInterval(interval)
    }

    return () => {}
  }, [dashboard?.customer_id])

  const handleResetAllTempStates = () => {
    setDashboardFilter([])
    setPeriodFilter(null)
    setCustomSegmentByMapper({})

    getDataForWidgets(props, token, dashboard, [], null, {})
  }

  const handleSetPeriodFilter = (
    period: PeriodFilter | CustomPeriodFilter | null
  ) => {
    setPeriodFilter(period)

    getDataForWidgets(
      props,
      token,
      dashboard,
      dashboardFilter,
      period,
      customSegmentByMapper
    )
  }

  const handleSetDashboardFilter = (
    newDashboardFilter: DashboardFilter,
    newPeriodFilter?: CustomPeriodFilter
  ) => {
    const period =
      newPeriodFilter === undefined ? periodFilter : newPeriodFilter

    getDataForWidgets(
      props,
      token,
      dashboard,
      newDashboardFilter,
      period,
      customSegmentByMapper
    )
    setDashboardFilter(newDashboardFilter)

    if (newPeriodFilter !== undefined) {
      setPeriodFilter(newPeriodFilter)
    }
  }

  const handleSetSegmentBy = (
    widgetId: string,
    attributeOptionId: string | null,
    dbFilter?: DashboardFilter,
    newPeriodFilter?: CustomPeriodFilter
  ) => {
    const newSegmentByMapper = {
      ...customSegmentByMapper,
      [widgetId]: attributeOptionId
    }
    const activeDbFilter = dbFilter || dashboardFilter
    const activePeriod =
      newPeriodFilter === undefined ? periodFilter : newPeriodFilter

    setCustomSegmentByMapper(newSegmentByMapper)

    if (dbFilter !== undefined) {
      setDashboardFilter(dbFilter)
    }
    if (newPeriodFilter !== undefined) {
      setPeriodFilter(newPeriodFilter)
    }

    getDataForWidgets(
      props,
      token,
      dashboard,
      activeDbFilter,
      activePeriod,
      newSegmentByMapper
    )
  }

  if (KPIDashboardStore.activeFetchOne !== params.id) {
    // check for loading or error
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          width: '100vw',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {KPIDashboardStore.errorFetchOne === params.id ? (
          <p>Det går inte att hämta dashboarden.</p>
        ) : (
          <LoadingSection titles={LOADING_WIDGET_TITLES} loading={true} />
        )}
      </Box>
    )
  }

  return (
    <PublicDashboardTemplate
      allowDashboardFilter={allowDashboardFilter}
      allowExport={allowExport}
      bgcolor={dashboard.white_label_settings?.background_color}
      dashboardFilter={dashboardFilter}
      gridRef={gridRef}
      mobileBreakpoint={mobileBreakpoint}
      mobileResponsive={mobileResponsive}
      periodFilter={periodFilter}
      resetAllTempStates={handleResetAllTempStates}
      setDashboardFilter={handleSetDashboardFilter}
      setPeriodFilter={handleSetPeriodFilter}
    >
      <Dashboard
        customSegmentByMapper={customSegmentByMapper}
        dashboardFilter={dashboardFilter}
        editMode={false}
        gridRef={gridRef}
        periodFilter={periodFilter}
        resetAllTempStates={handleResetAllTempStates}
        setDashboardFilter={handleSetDashboardFilter}
        setPeriodFilter={handleSetPeriodFilter}
        setCustomSegmentBy={handleSetSegmentBy}
        variant={{
          embedded: true,
          type: 'api'
        }}
      />
    </PublicDashboardTemplate>
  )
}

export default connector(ApiAccessDashboardPage)
