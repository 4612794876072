import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'

import IconTip from 'components_new/molecules/IconTip'
import SectionHeader from 'components_new/molecules/SectionHeader'

import AccessToggle from './AccessToggle'
import Dimensions, { TDimensions } from './Dimensions'
import DisabledWrapper from './DisabledWrapper'
import Features from './Features'
import Filter, { TFilter } from './Filter'
import NotInLicense from './NotInLicense'
import Output, { TOutputMode } from './Output'
import ResponsiveMode from './ResponsiveMode'

import { ParsedDashboardFilterOptions } from 'types/GlobalDashboardFilter'

export type TEmbedType = 'dashboard' | 'widget'

interface EmbedSectionProps {
  allowEmbed: boolean
  apiAccess?: boolean
  controlledFilter?: TFilter
  editable: boolean
  embedType: TEmbedType
  filterOptions: ParsedDashboardFilterOptions[]
  isManagementCompany?: boolean
  isPublic: boolean
  link: string
  setApiAccess?: (apiAccess: boolean) => void
  setIsPublic: (value: boolean) => void
}

const EmbedSection = (props: EmbedSectionProps) => {
  const {
    allowEmbed,
    apiAccess,
    controlledFilter,
    editable,
    embedType,
    filterOptions,
    isManagementCompany = false,
    isPublic,
    link,
    setApiAccess,
    setIsPublic
  } = props

  const [breakpoint, setBreakpoint] = useState<string | null>(null)
  const [dimensions, setDimensions] = useState<TDimensions>({
    width: '100%',
    height: '100%'
  })
  const [filter, setFilter] = useState<TFilter>({})
  const [imageExport, setImageExport] = useState<boolean>(false)
  const [mobile, setMobile] = useState<boolean>(false)
  const [outputMode, setOutputMode] = useState<TOutputMode>('iframe')
  const [userFilter, setUserFilter] = useState<boolean>(false)

  return (
    <>
      {allowEmbed ? (
        <>
          <Box sx={{ mb: 2 }}>
            <AccessToggle
              apiAccess={apiAccess}
              editable={editable}
              embedType={embedType}
              isManagementCompany={isManagementCompany}
              isPublic={isPublic}
              setApiAccess={setApiAccess}
              setIsPublic={setIsPublic}
            />
          </Box>
          <DisabledWrapper isPublic={isPublic}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1 }}>
                <Output
                  breakpoint={breakpoint}
                  dimensions={dimensions}
                  embedType={embedType}
                  filter={controlledFilter ?? filter}
                  imageExport={imageExport}
                  isPublic={isPublic}
                  link={link}
                  mobile={mobile}
                  outputMode={outputMode}
                  setOutputMode={setOutputMode}
                  sx={{ mb: 4 }}
                  userFilter={userFilter}
                />
                <SectionHeader
                  sx={{ mb: 2 }}
                  title="Inställningar"
                  titleAdornment={
                    <IconTip title="De här inställningarna sparas inte permanent utan används tillfälligt för att hjälpa dig lägga till dem i din länk eller ditt kodblock." />
                  }
                />
                <Box
                  sx={{
                    p: 2,
                    bgcolor: 'action.hover',
                    borderRadius: 1
                  }}
                >
                  <Collapse in={outputMode === 'iframe'}>
                    <Dimensions
                      dimensions={dimensions}
                      editable={editable}
                      embedType={embedType}
                      setDimensions={setDimensions}
                    />
                  </Collapse>
                  <Collapse in={embedType === 'dashboard'}>
                    <ResponsiveMode
                      breakpoint={breakpoint}
                      editable={editable}
                      embedType={embedType}
                      mobile={mobile}
                      setBreakpoint={setBreakpoint}
                      setMobile={setMobile}
                    />
                  </Collapse>
                  <Collapse in={embedType === 'dashboard' && apiAccess}>
                    <Features
                      editable={editable}
                      embedType={embedType}
                      imageExport={imageExport}
                      setImageExport={setImageExport}
                      setUserFilter={setUserFilter}
                      userFilter={userFilter}
                    />
                  </Collapse>
                </Box>
              </Box>
              {!controlledFilter ? (
                <Box sx={{ width: 320, px: 2 }}>
                  <Filter
                    embedType={embedType}
                    filter={filter}
                    filterOptions={filterOptions}
                    setFilter={setFilter}
                  />
                </Box>
              ) : null}
            </Box>
          </DisabledWrapper>
        </>
      ) : (
        <NotInLicense />
      )}
    </>
  )
}

export default EmbedSection
