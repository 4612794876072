import React from 'react'

import { ThemeProvider } from '@mui/material'
import { getColorMode, getTheme } from 'themes'

import Box from 'components_new/atoms/Box'
import Skeleton from 'components_new/atoms/Skeleton'

interface LoadingProps {
  colors?: any
}

const Loading = (props: LoadingProps) => {
  const { colors } = props

  return (
    <>
      <ThemeProvider
        theme={getTheme(getColorMode(colors?.background_color ?? 'light'))}
      >
        <Box
          sx={{
            flex: '1 1 auto',
            minWidth: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
            bgcolor: colors?.background_color ?? 'background.paper'
          }}
        >
          {[1, 2, 3, 4].map((item) => (
            <Skeleton key={item} variant="rounded" height={256} />
          ))}
        </Box>
      </ThemeProvider>
    </>
  )
}

Loading.displayName = 'Loading'

export default Loading
