import React, { useMemo } from 'react'

import PeriodMenu from './PeriodMenu'

import { getDatesOfPeriod } from '../utils'
import { getParsedDateSpan } from 'utils/dateParser'
import { translateDateFilter } from 'utils/enumTranslator'
import { isCustomPeriodFilter, isPeriodFilterEnum } from 'utils/functions'

import { PeriodSelectorProps } from '.'
import { EmptyValue } from './utils'

import TextField from 'components_new/atoms/TextField'

const Desktop = (props: PeriodSelectorProps) => {
  const { onSubmit, periodFilter, periodFilterOptions } = props

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const isCustom = periodFilter && isCustomPeriodFilter(periodFilter)
  const isPreset = periodFilter && isPeriodFilterEnum(periodFilter)

  const EMPTY_VALUE = 'STANDARD'

  const value = useMemo(() => {
    if (isPreset) {
      return getParsedDateSpan(
        getDatesOfPeriod(periodFilter).from,
        getDatesOfPeriod(periodFilter).to
      )
    }
    if (isCustom) {
      return getParsedDateSpan(periodFilter.from, periodFilter.to)
    }

    return EMPTY_VALUE
  }, [periodFilter])

  return (
    <>
      <TextField
        InputProps={{
          readOnly: true
        }}
        label={
          isPreset ? (
            translateDateFilter[periodFilter]
          ) : isCustom ? (
            'Anpassad'
          ) : (
            <EmptyValue />
          )
        }
        noGutter={true}
        onClick={handleClick}
        select={true}
        SelectProps={{
          renderValue: () => {
            if (value === EMPTY_VALUE) {
              return 'Enligt dashboard'
            } else {
              return value
            }
          }
        }}
        value={value}
      />

      <PeriodMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onSubmit={onSubmit}
        periodFilter={periodFilter}
        periodFilterOptions={periodFilterOptions}
      />
    </>
  )
}

export default Desktop
