import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import * as AlertActions from 'redux/actions/Alert'

class LockedModeWrapper extends Component {
  componentDidMount() {
    this.props.history.block((match) => {
      const { LockedModeStore } = this.props
      const subPath = match.pathname.replace(window.location.pathname, '')

      if (
        LockedModeStore.locked &&
        !LockedModeStore?.allowedSubPaths?.includes(subPath) &&
        match.pathname !== LockedModeStore.mainPath
      ) {
        this.props.setAlert(LockedModeStore.alert)

        return false
      }

      return true
    })
    window.addEventListener('beforeunload', (e) => {
      const { LockedModeStore } = this.props

      if (LockedModeStore.locked) {
        e.preventDefault() //per the standard
        e.returnValue = '' //required for Chrome
      }
    })
  }

  render() {
    const { children, LockedModeStore } = this.props

    return (
      <div
        onClickCapture={(e) => {
          if (LockedModeStore.locked) {
            const path =
              e.path ||
              (e.composedPath && e.composedPath()) ||
              e.nativeEvent.path

            if (path) {
              const hasAllowedOnClick = !path.every((item) => !item.onclick)

              const notInIgnore = path.filter(
                (item) =>
                  item.id === LockedModeStore.ignoreId ||
                  (item.className &&
                    item.className.includes &&
                    item.className.includes('ignore-locked-mode'))
              )

              if (hasAllowedOnClick && notInIgnore.length === 0) {
                this.props.setAlert(LockedModeStore.alert)

                e.stopPropagation()
              }
            }
          }
        }}
        style={{
          width: '100%',
          height: '100%'
        }}
      >
        {children}
      </div>
    )
  }
}

function mapStateToProps({ LockedModeStore }) {
  return { LockedModeStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...AlertActions }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LockedModeWrapper))
