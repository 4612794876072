import React from 'react'

import Box from 'components_new/atoms/Box'
import Tag from 'components_new/atoms/Tag'

import IconTip from 'components_new/molecules/IconTip'

export const EmptyValue = () => {
  return (
    <>
      <>Standard</>
      <IconTip
        size="small"
        sx={{ ml: 0.5 }}
        title={
          <>
            <Box sx={{ mb: 0.5 }}>
              <Tag label={'Standard'} />: Din dashboard utgår från dina
              nyckeltals standardlägen när det kommer till tidsperiod.
            </Box>
            <Box>
              Det innebär att den inte har en och samma gemensamma tidsperiod
              utan den visar data beroende på vad de separata nyckeltalen har
              ställts in till att visa i sina inställningar.
            </Box>
          </>
        }
      />
    </>
  )
}
