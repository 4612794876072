import React from 'react'

import { StatusBannerData } from 'utils/types'
import { WidgetObject } from 'types/GlobalWidget'

import Box from 'components_new/atoms/Box'

import Banner from 'components_new/molecules/Banner'
import ErrorPlaceholder from 'components_new/molecules/ErrorPlaceholder'

import WidgetComponent from 'components_new/organisms/Widget'
import { formatWidgetData } from 'components_new/organisms/Dashboard/utils'

export type ErrorCode = 404 | 500 | null

interface PublicWidgetTemplateProps {
  errorCode: ErrorCode
  widget: WidgetObject
  statusBanner: StatusBannerData[]
}

const PublicWidgetTemplate = (props: PublicWidgetTemplateProps) => {
  const { errorCode, widget, statusBanner } = props
  const formattedData = formatWidgetData(widget)

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        bgcolor: widget?.colors?.widget_background_color
      }}
    >
      {statusBanner.length > 0 &&
        statusBanner.map((banner) => (
          <Banner
            body={banner.description}
            href={banner.link}
            iconName={banner.icon}
            key={banner.id}
            linkLabel={banner.link_label}
            title={banner.title}
          />
        ))}
      {errorCode ? (
        <ErrorPlaceholder code={errorCode} />
      ) : (
        <WidgetComponent
          availableSpace={null}
          colors={widget?.colors}
          customer={null}
          dashboardFilter={[]}
          dashboardFilterOptions={[]}
          formattedData={formattedData}
          loading={!widget?.data || !widget || !widget.settings}
          editMode={false}
          embedded={true}
          isAdmin={false}
          plain={true}
          resetAllDashboardTempStates={() => {}}
          scaleFactor={1}
          standaloneWidget={true}
          widget={widget}
        />
      )}
    </Box>
  )
}

export default PublicWidgetTemplate
