import React, { ReactNode, useState } from 'react'

import { ThemeProvider, useMediaQuery, useTheme } from '@mui/material'
import { getTheme } from 'themes'

import { DashboardFilter } from 'types/GlobalDashboardFilter'
import {
  CustomPeriodFilter,
  PeriodFilter,
  WidgetPatchBody
} from 'types/GlobalWidget'

import FixedSideButton from 'components_new/molecules/FixedSideButton'

import ExportDialog from 'components_new/organisms/dialogs/ExportDialog'
import DashboardUserFilter from 'components_new/organisms/DashboardUserFilter'
import DashboardUserFilterBar from 'components_new/organisms/DashboardUserFilterBar'
import PageContainer from 'components_new/organisms/PageContainer'

export type InnerWidgetPatchBody = WidgetPatchBody['data']

interface Props {
  allowDashboardFilter?: boolean
  allowExport?: boolean
  bgcolor?: string | null
  children: ReactNode
  dashboardFilter: DashboardFilter
  gridRef: any | null
  mobileBreakpoint?: number
  mobileResponsive?: boolean
  periodFilter: CustomPeriodFilter | PeriodFilter | null
  resetAllTempStates: () => void
  setDashboardFilter: (
    filter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  setPeriodFilter: (
    periodFilter: CustomPeriodFilter | PeriodFilter | null
  ) => void
}

const PublicDashboardTemplate = (props: Props) => {
  const {
    allowDashboardFilter,
    allowExport,
    bgcolor,
    children,
    dashboardFilter,
    gridRef,
    mobileBreakpoint,
    mobileResponsive,
    periodFilter,
    resetAllTempStates,
    setDashboardFilter,
    setPeriodFilter
  } = props

  const [exportDialogOpen, setExportDialogOpen] = useState<boolean>(false)

  const currentTheme = useTheme()
  const theme = getTheme(currentTheme.palette.mode, null, {
    breakpoint: mobileBreakpoint,
    responsive: mobileResponsive
  })

  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  return (
    <ThemeProvider theme={theme}>
      <PageContainer
        bgcolor={bgcolor}
        embedded={true}
        rightSidebar={
          allowDashboardFilter ? (
            <DashboardUserFilter
              dashboardFilter={dashboardFilter}
              periodFilter={periodFilter}
              setDashboardFilter={setDashboardFilter}
              setPeriodFilter={setPeriodFilter}
            />
          ) : null
        }
        RightSidebarProps={{
          iconName: 'FilterAlt',
          mobilePreview: allowDashboardFilter ? (
            <DashboardUserFilterBar
              dashboardFilter={dashboardFilter}
              periodFilter={periodFilter}
              resetAllTempStates={resetAllTempStates}
              setDashboardFilter={setDashboardFilter}
              setPeriodFilter={setPeriodFilter}
            />
          ) : null,
          tooltip: 'Filter'
        }}
        toolbar={
          !isMobile ? (
            <DashboardUserFilterBar
              dashboardFilter={dashboardFilter}
              periodFilter={periodFilter}
              resetAllTempStates={resetAllTempStates}
              setDashboardFilter={setDashboardFilter}
              setPeriodFilter={setPeriodFilter}
            />
          ) : null
        }
        ToolbarProps={{
          open: Boolean(periodFilter) || dashboardFilter.length > 0
        }}
      >
        {/* Future work: Make this (more side buttons) part of PageContainer */}
        {allowExport && !isMobile ? (
          <>
            <FixedSideButton
              iconName="FileDownloadOutlined"
              onClick={() => setExportDialogOpen(true)}
              tooltip="Exportera"
              top={allowDashboardFilter ? 56 : 12}
            />
            <ExportDialog
              gridRef={gridRef}
              open={exportDialogOpen}
              onClose={() => setExportDialogOpen(false)}
              onOpen={() => setExportDialogOpen(true)}
            />
          </>
        ) : null}
        {children}
      </PageContainer>
    </ThemeProvider>
  )
}

export default PublicDashboardTemplate
