/**
 * Sort strings alphabetical ascending and put null last
 * Use it as '.sort((a, b) => sortAlphabeticalAsc(a, b))'
 */
export const sortAlphabeticalAsc = (a: string | null, b: string | null) => {
  if ((a === null || a === '') && (b === null || b === '')) {
    return 0
  }

  if (a === null || a === '') {
    return 1
  }

  if (b === null || b === '') {
    return -1
  }

  return a.localeCompare(b, 'sv', { sensitivity: 'base' })
}

/**
 * Sort strings alphabetical descending and put null last
 * Use it as '.sort((a, b) => sortAlphabeticalDesc(a, b))'
 */
export const sortAlphabeticalDesc = (a: string | null, b: string | null) => {
  if ((a === null || a === '') && (b === null || b === '')) {
    return 0
  }

  if (a === null || a === '') {
    return 1
  }

  if (b === null || b === '') {
    return -1
  }

  return b.localeCompare(a, 'sv', { sensitivity: 'base' })
}

/**
 * Sort numeric ascending and put null last
 * Use it as '.sort((a, b) => sortNumericAsc(a, b))'
 */
export const sortNumericAsc = (a: number | null, b: number | null) => {
  if (a === null && b === null) {
    return 0
  }

  if (a === null) {
    return 1
  }

  if (b === null) {
    return -1
  }

  return a - b
}

/**
 * Sort numeric descending and put null last
 * Use it as '.sort((a, b) => sortNumericDesc(a, b))'
 */
export const sortNumericDesc = (a: number | null, b: number | null) => {
  if (a === null && b === null) {
    return 0
  }

  if (a === null) {
    return 1
  }

  if (b === null) {
    return -1
  }

  return b - a
}

/**
 * Sort dates descending and put null last
 * Use it as '.sort((a, b) => sortDateDesc(a, b))'
 */
export const sortDateDesc = (a: Date | null, b: Date | null) => {
  if (a === null) {
    return 1
  }
  if (b === null) {
    return -1
  }

  if (a < b) {
    return 1
  }
  if (a > b) {
    return -1
  }

  return 0
}

/**
 * Sort a 2D layout into a 1D layout.
 * Use it as '.sort((a, b) => sortGridLayout(a, b))'
 */
export const sortGridLayout = (
  a: { width: number; height: number; x: number; y: number } | undefined,
  b: { width: number; height: number; x: number; y: number } | undefined
) => {
  if (a && b) {
    if (a.y !== b.y) {
      // sort by y first
      return a.y - b.y
    }

    // then by x
    return a.x - b.x
  }

  return 0
}
