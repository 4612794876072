import React, { useState } from 'react'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import TextField from 'components_new/atoms/TextField'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'
import SimpleDialog from 'components_new/molecules/SimpleDialog'

import DeleteDialog from 'components_new/organisms/dialogs/DeleteDialog'

import { DELETE_ICON } from 'utils/icons'
import { ApiKey } from 'types/GlobalApiKey'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'

interface ApiKeyProps {
  apiKeys: ApiKey[]
  createApiKey: (name: string) => void
  deleteApiKey: (id: string) => void
}

const ApiKeyTemplate = (props: ApiKeyProps) => {
  const { apiKeys, createApiKey, deleteApiKey } = props

  const [apiKeyName, setApiKeyName] = useState<string>('')
  const [apiKeyToDelete, setDeleteApiKey] = useState<ApiKey | null>(null)
  const [createApiKeyOpen, setCreateApiKeyOpen] = useState<boolean>(false)
  const [copySuccess, setCopySuccess] = useState<boolean>(false)

  return (
    <>
      <Box sx={{ maxWidth: 'content' }}>
        <SettingsGroup
          title="API-nycklar"
          helperText={
            <>
              Här visas dina API-nycklar. Dessa nycklar kan delas med en
              tredjepart som behöver bädda in dashboards på en webbplats. Med
              hjälp av en API-nyckel kan tredjeparten hämta information om era
              koncerner samt generera unika länkar till dashboards. Dessa länkar
              är giltiga i 1 timme. Om du är osäker på hur detta fungerar, tveka
              inte att kontakta oss för hjälp.
            </>
          }
        >
          {apiKeys
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((apiKey) => (
              <SettingsItem
                actions={
                  <>
                    <IconButton
                      disabled={apiKey.id === apiKeyToDelete?.id}
                      onClick={() => {
                        navigator.clipboard.writeText(apiKey.value)
                        setCopySuccess(true)
                      }}
                      size="small"
                    >
                      <Icon fontSize="small" name={'CopyAllOutlined'} />
                    </IconButton>
                    <IconButton
                      disabled={apiKey.id === apiKeyToDelete?.id}
                      onClick={() => setDeleteApiKey(apiKey)}
                      size="small"
                    >
                      <Icon fontSize="small" name={DELETE_ICON} />
                    </IconButton>
                  </>
                }
                alignItems="center"
                icon={<Icon fontSize="small" name={'VpnKeyOutlined'} />}
                key={apiKey.id}
                title={apiKey.name}
                value={apiKey.value}
              />
            ))}
          <SettingsItem
            color="success"
            onClick={() => setCreateApiKeyOpen(true)}
            title="Lägg till"
            variant="add"
          />
        </SettingsGroup>
      </Box>

      {/* DIALOGS */}
      <SimpleDialog
        actions={
          <>
            <Button variant="text" onClick={() => setCreateApiKeyOpen(false)}>
              Stäng
            </Button>
            <Button
              disabled={!apiKeyName}
              variant="contained"
              size="large"
              onClick={() => {
                createApiKey(apiKeyName)
                setCreateApiKeyOpen(false)
                setApiKeyName('')
              }}
            >
              Skapa
            </Button>
          </>
        }
        onClose={() => setCreateApiKeyOpen(false)}
        open={createApiKeyOpen}
        title={'Skapa en nyckel'}
      >
        <TextField
          label="Namn"
          required={true}
          autoComplete="off"
          value={apiKeyName}
          onChange={(event) => setApiKeyName(event.target.value)}
          sx={{ marginTop: 0, marginBottom: 0 }}
        />
      </SimpleDialog>
      <DeleteDialog
        open={apiKeyToDelete !== null}
        handleClose={() => setDeleteApiKey(null)}
        handleDelete={(cb) => {
          if (apiKeyToDelete) {
            deleteApiKey(apiKeyToDelete.id)
          }

          setDeleteApiKey(null)
          cb()
        }}
        type={'API-nyckel'}
        title={apiKeyToDelete?.name as string}
      />

      {/*-- snackbars --*/}
      <Portal>
        <Snackbar
          open={copySuccess}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setCopySuccess(false)
            }
          }}
          message="Kopierat till urklipp"
        />
      </Portal>
    </>
  )
}

export default ApiKeyTemplate
