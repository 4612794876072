import React from 'react'

import { AccountRole } from 'types/GlobalUser'
import { AccountType } from 'redux/reducers/Accounts'
import { CompanyGroupBody } from 'redux/reducers/CompanyGroups'

import { TRANSLATE_ROLES } from 'utils/enumTranslator'

import Avatar from 'components_new/atoms/Avatar'
import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

interface MobileProps {
  companyGroups: CompanyGroupBody
  users: AccountType[]
}

const Mobile = (props: MobileProps) => {
  const { companyGroups, users } = props

  return (
    <>
      <List>
        {users
          .sort((a, b) =>
            `${a.first_name} ${a.last_name}`.localeCompare(
              `${b.first_name} ${b.last_name}`
            )
          )
          .map((user) => (
            <ListItem key={user.id}>
              <ListItemAvatar sx={{ minWidth: 48 }}>
                <Avatar
                  alt={
                    user.first_name && user.last_name
                      ? user.first_name + ' ' + user.last_name
                      : 'Namnlös'
                  }
                  sx={{
                    width: 32,
                    height: 32
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex' }}>
                    <Text component="span" noWrap={true} variant="inherit">
                      {user.first_name && user.last_name
                        ? user.first_name + ' ' + user.last_name
                        : 'Namnlös'}
                    </Text>
                    {user.role === AccountRole.ADMIN ||
                    user.company_group_id ? (
                          <Chip
                            color={
                              user.role === AccountRole.ADMIN
                                ? 'secondary'
                                : undefined
                            }
                            label={
                              user.company_group_id
                                ? companyGroups[user.company_group_id]?.name ||
                              'Koncern borttagen'
                                : TRANSLATE_ROLES[user.role]
                            }
                            size="xsmall"
                            sx={{ ml: 1 }}
                          />
                        ) : null}
                    {!user.invite_accepted_at ? (
                      <Tooltip title="Användaren har inte aktiverat sitt konto än">
                        <Chip
                          color="warning"
                          label={'Inte aktiverad'}
                          size="xsmall"
                          sx={{ ml: 1 }}
                        />
                      </Tooltip>
                    ) : null}
                  </Box>
                }
                secondary={user.email}
                secondaryTypographyProps={{ noWrap: true }}
              />
            </ListItem>
          ))}
      </List>
    </>
  )
}

export default Mobile
