import React from 'react'

import Box from 'components_new/atoms/Box'
import Link from 'components_new/atoms/Link'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'
import Tooltip from 'components_new/atoms/Tooltip'

import { TEmbedType } from './EmbedSection'

interface PublicStatusProps {
  editable: boolean
  embedType: TEmbedType
  isPublic: boolean
  onRedirect?: () => void
}

const PublicStatus = (props: PublicStatusProps) => {
  const { editable, embedType, isPublic, onRedirect } = props

  return (
    <>
      <Box
        sx={{
          bgcolor: isPublic ? 'success.background' : 'info.background',
          borderRadius: 1,
          p: 2,
          display: 'flex',
          gap: 2
        }}
      >
        <Icon name={isPublic ? 'PublicOutlined' : 'PublicOffOutlined'} />
        <Text>
          {isPublic ? (
            <>
              <strong>Publik {embedType}.</strong> Alla som har tillgång till
              den publika länken kan se den.{' '}
            </>
          ) : (
            <>
              <strong>Privat {embedType}.</strong> Bara inloggade användare med
              åtkomst kan se den.{' '}
            </>
          )}
          Ändra publik åtkomst under{' '}
          <Tooltip
            title={
              'Endast ägare eller adminstratörer av dashboarden kan ändra det här.'
            }
            disableHoverListener={editable}
          >
            <Link color="inherit" disabled={!editable} onClick={onRedirect}>
              inbäddningssektionen
            </Link>
          </Tooltip>
          .
        </Text>
      </Box>
    </>
  )
}

export default PublicStatus
