import { PeriodFilter } from 'types/GlobalWidget'

// every 5th minute
export const STATUS_BANNER_TIMER = 60000 * 5

// tooltip text for when details is disabled for kpi
export const DETAILS_DISABLE_TEXT =
  'Det går inte att visa innehåll för det här nyckeltalet'

// Current special case to not allow rolling, because it will
// go past from_date of data storage.
export const BLOCKED_PERIOD_FILTERS = [
  PeriodFilter.LAST_24_MONTHS_TO_TODAY,
  PeriodFilter.LAST_36_MONTHS_TO_TODAY,
  PeriodFilter.LAST_3_YEARS_TO_TODAY,
  PeriodFilter.LAST_8_QUARTERS_TO_TODAY,
  PeriodFilter.LAST_12_QUARTERS_TO_TODAY
]
