import React, { ReactNode, useMemo } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'

import { Dashboard, DashboardReducerType } from 'redux/reducers/Dashboards'

import Icon from 'components_new/atoms/Icon'

import { OptionProps } from 'components_new/molecules/OptionsMenu'

export type TDialogType =
  | 'delete_dashboard'
  | 'edit_dashboard_title'
  | 'copy_dashboard'
  | 'move_dashboard'

function getDashboardLabel(dashboard: Dashboard) {
  if (dashboard.has_access) {
    return dashboard.title
  }

  return (
    <>
      <Icon name="VisibilityOffOutlined" sx={{ fontSize: '12px', mr: 1 }} />
      {dashboard.title}
    </>
  )
}

export function getDashboardTabs(
  KPIDashboardStore: DashboardReducerType,
  activeDashboardId: string,
  editable: boolean,
  onMove: (dashboardId: string, direction: number) => void,
  onDuplicate: (dashboardId: string) => void,
  handleOpenDialog: (dashboardId: string, copyType: TDialogType) => void
): {
  value: string
  label: string | ReactNode
  disabled: boolean
  disabledTooltip: string
  menuOptions: OptionProps[] | undefined
  title: string
}[] {
  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  return useMemo(() => {
    const activeDashboard = KPIDashboardStore.data[activeDashboardId]

    if (
      !activeDashboard ||
      !KPIDashboardStore.groups[activeDashboard.dashboard_group_id]
    ) {
      return []
    }

    const { dashboards } =
      KPIDashboardStore.groups[activeDashboard?.dashboard_group_id]

    return dashboards.map((dashboardId, i) => {
      const dashboard = KPIDashboardStore.data[dashboardId]

      return {
        value: dashboardId,
        title: dashboard.title,
        label: getDashboardLabel(dashboard),
        disabled: !dashboard.has_access,
        disabledTooltip: 'Du saknar tillgång till den här fliken.',
        menuOptions: editable
          ? [
              {
                iconName: 'MoveDownOutlined',
                iconRotation: lgUp ? '90deg' : '180deg',
                onClick: () => onMove(dashboard.id, -1),
                title: `Flytta ${lgUp ? 'vänster' : 'upp'}`,
                disabled: i === 0
              },
              {
                iconName: 'MoveUpOutlined',
                iconRotation: lgUp ? '90deg' : '180deg',
                onClick: () => onMove(dashboard.id, 1),
                title: `Flytta ${lgUp ? 'höger' : 'ner'}`,
                divider: true,
                disabled: i === dashboards.length - 1
              },
              {
                iconName: 'EditOutlined',
                onClick: () =>
                  handleOpenDialog(dashboard.id, 'edit_dashboard_title'),
                title: 'Redigera namn',
                divider: true
              },
              {
                iconName: 'ContentCopyOutlined',
                onClick: () => onDuplicate(dashboard.id),
                title: 'Duplicera'
              },
              {
                iconName: 'FileCopyOutlined',
                onClick: () => handleOpenDialog(dashboard.id, 'copy_dashboard'),
                title: 'Kopiera till...'
              },
              {
                iconName: 'DriveFileMoveOutlined',
                onClick: () => handleOpenDialog(dashboard.id, 'move_dashboard'),
                title: 'Flytta till...',
                disabled: dashboards.length === 1,
                divider: true
              },
              {
                iconName: 'DeleteOutlined',
                onClick: () =>
                  handleOpenDialog(dashboard.id, 'delete_dashboard'),
                title: 'Radera',
                disabled: dashboards.length === 1
              }
            ]
          : undefined
      }
    })
  }, [
    KPIDashboardStore.groups,
    KPIDashboardStore.data,
    lgUp,
    activeDashboardId
  ])
}
