import {
  ComparativePeriod,
  CustomPeriodFilter,
  DateFilterType,
  ForecastPeriod,
  PeriodFilter,
  translateForecastPeriod
} from 'types/GlobalWidget'
import { getParsedDateSpan } from 'utils/dateParser'

import {
  translateComparativePeriod,
  translateDateFilter
} from 'utils/enumTranslator'
import { isCustomPeriodFilter } from 'utils/functions'

export function getComparativePeriodLabel(
  displayedPeriodFilter: CustomPeriodFilter | PeriodFilter,
  displayedPeriodType: 'dashboard' | 'widget',
  comparativePeriod: ComparativePeriod | null,
  comparativePeriodFromDate: string,
  comparativePeriodToDate: string,
  isPoint: boolean,
  forecastPeriod: ForecastPeriod | null
) {
  if (!comparativePeriod) {
    return null
  }

  const isCustom = isCustomPeriodFilter(displayedPeriodFilter)

  if (isCustom) {
    return getParsedDateSpan(comparativePeriodFromDate, comparativePeriodToDate)
  }

  let label = ''

  if (displayedPeriodFilter) {
    label =
      translateComparativePeriod[
        isPoint ? DateFilterType.POINT : DateFilterType.PERIOD
      ][displayedPeriodFilter][comparativePeriod] || ''
  }

  if (forecastPeriod && displayedPeriodType === 'widget') {
    label += ` och ${translateForecastPeriod[forecastPeriod].toLowerCase()}`
  }

  return label
}

export function getPeriodLabel(
  displayedPeriodFilter: CustomPeriodFilter | PeriodFilter,
  displayedPeriodType: 'dashboard' | 'widget',
  forecastPeriod: ForecastPeriod | null,
  isPoint: boolean,
  restrictToPeriod: boolean
) {
  const isCustom = isCustomPeriodFilter(displayedPeriodFilter)

  if (isCustom) {
    return getParsedDateSpan(
      displayedPeriodFilter.from,
      displayedPeriodFilter.to
    )
  }

  let label = ''

  if (displayedPeriodFilter) {
    label = `${isPoint ? 'Senaste dagen i ' : ''} ${
      translateDateFilter[displayedPeriodFilter]
    }`
  }

  if (restrictToPeriod) {
    label += ' t.o.m. senaste stängda bokslutsperiod'
  }

  if (forecastPeriod && displayedPeriodType === 'widget') {
    label += ` och ${translateForecastPeriod[forecastPeriod]?.toLowerCase()}`
  }

  return label
}

export const getDisplayedPeriod = (
  dashboardCustomPeriodFilter: CustomPeriodFilter | null,
  dashboardPeriodFilter: PeriodFilter | null,
  isLocked: boolean,
  widgetPeriodFilter: PeriodFilter
): {
  filter: CustomPeriodFilter | PeriodFilter
  type: 'dashboard' | 'widget'
} => {
  if (!isLocked && dashboardCustomPeriodFilter) {
    return {
      filter: dashboardCustomPeriodFilter,
      type: 'dashboard'
    }
  }

  if (!isLocked && dashboardPeriodFilter) {
    return {
      filter: dashboardPeriodFilter,
      type: 'dashboard'
    }
  }

  return {
    filter: widgetPeriodFilter,
    type: 'widget'
  }
}
