import React, { ReactNode } from 'react'

import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

import * as NavigationMenuStoreActions from 'redux/actions/NavigationMenu'

import { useMediaQuery, useTheme } from '@mui/material'

import Mobile from './mobile'
import Desktop from './desktop'

interface TopbarProps {
  actions?: ReactNode
  children?: ReactNode
  displayProfile?: boolean
  navLocked?: boolean
  noGutter?: boolean
  sx?: object
  title?: ReactNode
}

const Topbar = (props: ComponentProps) => {
  const {
    actions,
    children,
    displayProfile,
    NavigationMenuStore,
    navLocked,
    noGutter,
    sx,
    title,
    toggleNavigationOpen
  } = props

  const navOpen = navLocked ?? NavigationMenuStore.open
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  return isMobile ? (
    <Mobile
      actions={actions}
      displayProfile={displayProfile}
      noGutter={noGutter}
      sx={sx}
    >
      {children}
    </Mobile>
  ) : (
    <Desktop
      actions={actions}
      displayProfile={displayProfile}
      navLock={navLocked}
      navOpen={navOpen}
      noGutter={noGutter}
      sx={sx}
      title={title}
      toggleNavigationOpen={toggleNavigationOpen}
    >
      {children}
    </Desktop>
  )
}

/*-- redux --*/
interface ComponentDispatchProps {
  toggleNavigationOpen(): (dispatch: Dispatch) => void
}

const mapStateToProps = (state: ApplicationState) => ({
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...NavigationMenuStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  ComponentDispatchProps &
  TopbarProps

export default connector(Topbar)
