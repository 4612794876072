import React from 'react'

import { ThemeProvider } from '@mui/material'
import { getColorMode, getTheme } from 'themes'

import Box from 'components_new/atoms/Box'
import Skeleton from 'components_new/atoms/Skeleton'

interface LoadingProps {
  colors?: any
}

const Loading = (props: LoadingProps) => {
  const { colors } = props

  return (
    <>
      <ThemeProvider
        theme={getTheme(getColorMode(colors?.background_color ?? 'light'))}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            p: 2,
            bgcolor: colors?.background_color ?? 'background.paper'
          }}
        >
          <Box sx={{ display: 'flex', gap: 2, flex: 0.25 }}>
            {[1, 2, 3, 4].map((item) => (
              <Skeleton
                key={item}
                variant="rounded"
                height={'100%'}
                sx={{ flex: 1 }}
              />
            ))}
          </Box>
          <Box sx={{ display: 'flex', gap: 2, flex: 0.75 }}>
            <Skeleton variant="rounded" height={'100%'} sx={{ flex: 0.5 }} />
            <Box
              sx={{
                flex: 0.5,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2
              }}
            >
              {[1, 2].map((item) => (
                <Skeleton
                  key={item}
                  variant="rounded"
                  width={'100%'}
                  sx={{ flex: 0.5 }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  )
}

Loading.displayName = 'Loading'

export default Loading
