import('dayjs/locale/sv')
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

/**
 * @param date needs to be an ISO-string of a date,
 * e.g. '2020-01-01T00:00:00.000Z' | '2020-01-01' |
 * '2020-01' | '2020'.
 *
 * @returns a date as a string on the format '1 jan 2020' | 'Jan 2020' | '2020'.
 */

export const getParsedDate = (
  date: Date | string | null | undefined
): string => {
  if (!date) return ''

  dayjs.locale('sv')

  let stringDate = ''

  if (date instanceof Date) {
    stringDate = date.toISOString()
  } else {
    stringDate = date
  }

  switch (stringDate.length) {
  case 4: // '2020'
    return dayjs(stringDate).format('YYYY')
  case 7: // '2020-01'
    const string = dayjs(stringDate).format('MMM YYYY')

    return string.charAt(0).toUpperCase() + string.slice(1)
  default: // '2020-01-01' || '2020-01-01T00:00:00.000Z'
    return dayjs(stringDate).format('D MMM YYYY')
  }
}

/**
 * @param fromDate and @param toDate needs to be an ISO-string of a date,
 * e.g. '2020-01-01T00:00:00.000Z' | '2020-01-01' | '2020-01' | '2020'.
 *
 * Uses @function getParsedDate and @returns a string on the format:
 * '1 jan. 2020 - 31 jan. 2020' if both inputs are set,
 * '1 jan. 2020' if only fromDate is set,
 * '1 jan. 2020' if fromDate is equal to toDate,
 * or null if both inputs are nullish.
 */

export const getParsedDateSpan = (
  fromDate: string | null | undefined,
  toDate: string | null | undefined
) => {
  if ((fromDate && !toDate) || fromDate === toDate) {
    return getParsedDate(fromDate)
  }
  if (fromDate && toDate) {
    return getParsedDate(fromDate) + ' - ' + getParsedDate(toDate)
  }

  return null
}

/**
 * Parses values that are NOT returned as a ISO-string date.
 * Includes a type @param dateType that manages what type of date like label
 * that should be returned. I.e. month, weekdays.
 */
export const getParsedPartOfDate = (
  label: number | string | null,
  dateType: string
): string => {
  let splittedLabel

  if (label != null) {
    switch (dateType) {
    case 'month':
      return MONTH_VALUE_TRANSLATOR[label as number]
    case 'weekday':
      return WEEKDAY_VALUE_TRANSLATOR[label as number]
    case 'year_quarter':
      splittedLabel = (label as string).split('-')

      return `${QUARTER_VALUE_TRANSLATOR[splittedLabel[1]]} ${
        splittedLabel[0]
      }`
    case 'year_week':
      splittedLabel = (label as string).split('-')

      return `v. ${parseWeek(splittedLabel[1])} ${splittedLabel[0]}`
    default:
      return label ? label.toString() : ''
    }
  }

  return ''
}

const MONTH_VALUE_TRANSLATOR: { [key: number]: string } = {
  1: 'Januari',
  2: 'Februari',
  3: 'Mars',
  4: 'April',
  5: 'Maj',
  6: 'Juni',
  7: 'Juli',
  8: 'Augusti',
  9: 'September',
  10: 'Oktober',
  11: 'November',
  12: 'December'
}

const WEEKDAY_VALUE_TRANSLATOR: { [key: number]: string } = {
  0: 'Måndag',
  1: 'Tisdag',
  2: 'Onsdag',
  3: 'Torsdag',
  4: 'Fredag',
  5: 'Lördag',
  6: 'Söndag'
}

const QUARTER_VALUE_TRANSLATOR: { [key: string]: string } = {
  '1': 'Q1',
  '2': 'Q2',
  '3': 'Q3',
  '4': 'Q4'
}

const parseWeek = (week: string) => {
  if (week.charAt(0) === '0') {
    return week.slice(1)
  }

  return week
}

export const parseDatetime = (datetime: string) => {
  const date = getParsedDate(datetime)
  let time = datetime.split('T')[1]

  time = time.split('.')[0]

  return `${date} kl ${time}`
}

export const toNowFrom = (date: string | undefined) => {
  if (!date) return undefined

  dayjs.locale('sv')
  dayjs.extend(relativeTime)

  return dayjs(date).fromNow()
}
