import React from 'react'

import Box from 'components_new/atoms/Box'

import LoadingSection from 'components_new/molecules/LoadingSection'

import BarChart from '../types/BarChart'
import BubbleChart from '../types/BubbleChart'
import KeyFigure from '../types/KeyFigure'
import Image from '../types/Image'
import ComboChart from '../types/ComboChart'
import LineChart from '../types/LineChart'
import Table from '../types/Table'
import Map from '../types/Map'
import Funnel from '../types/Funnel'
import PieChart from '../types/PieChart'

import Broken from '../errors/Broken'
import NoData from '../errors/NoData'
import NoTarget from '../errors/NoTarget'

import { LOADING_WIDGET_TITLES } from 'utils/loadingTitles'

import { getSegmentSetters } from './utils'
import { getIsComparativeButIllegal } from './utils'

import { DashboardFilter } from 'types/GlobalDashboardFilter'
import {
  CustomPeriodFilter,
  FormattedWidgetData,
  PeriodFilter,
  ParsedSegmentPath,
  TViewport,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'

interface WidgetBodyProps {
  customSegmentBy: string | null
  dashboardFilter: DashboardFilter
  fullSize: boolean
  embedded: boolean
  fakeLoading: boolean
  formattedData: FormattedWidgetData
  interactive: boolean
  isAdmin: boolean
  loading: boolean
  periodFilter: PeriodFilter | null
  scaleFactor: number
  setCustomSegmentBy: (
    attributeOptionId: string,
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  setDashboardFilter: (
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  setEdit: (arg0: boolean) => void
  setViewport: (value: TViewport) => void
  standaloneWidget: boolean
  viewport: TViewport
  widget: WidgetObject
  showUnderlyingContent: (
    segmentPaths: ParsedSegmentPath[],
    initialKpiOptionId: string | null
  ) => void
}

const WidgetBody = (props: WidgetBodyProps) => {
  const {
    customSegmentBy,
    dashboardFilter,
    embedded,
    fakeLoading,
    formattedData,
    fullSize,
    interactive,
    isAdmin,
    loading,
    periodFilter,
    scaleFactor,
    setCustomSegmentBy,
    setDashboardFilter,
    setEdit,
    setViewport,
    showUnderlyingContent,
    standaloneWidget,
    viewport,
    widget
  } = props

  const type = widget.settings.type.selected

  if (
    type !== WidgetType.TEXT &&
    type !== WidgetType.COMMENT &&
    type !== WidgetType.IMAGE
  ) {
    if (loading || fakeLoading) {
      return (
        <LoadingSection
          titles={LOADING_WIDGET_TITLES}
          loading={loading || fakeLoading}
          scaleFactor={scaleFactor}
        />
      )
    }

    if (widget.status.broken) {
      return <Broken scaleFactor={scaleFactor} />
    }

    if (widget.status.required_data_modelling_missing) {
      return <NoData embedded={embedded} scaleFactor={scaleFactor} />
    }

    if (widget.status.required_target_missing) {
      return (
        <NoTarget
          embedded={embedded}
          isAdmin={isAdmin}
          scaleFactor={scaleFactor}
        />
      )
    }
  }

  const isComparativeButIllegal = getIsComparativeButIllegal(
    periodFilter ? periodFilter : widget.settings.date_filter.selected?.value,
    widget.settings.comparative_period.selected?.value,
    widget.settings.date_filter.type
  )

  const getWidgetType = () => {
    const segmentSetterFunctions = getSegmentSetters(
      widget,
      dashboardFilter,
      setDashboardFilter,
      showUnderlyingContent,
      setCustomSegmentBy,
      standaloneWidget,
      embedded,
      interactive
    )

    switch (widget.settings.type.selected) {
    case WidgetType.BAR_CHART:
      return (
        <BarChart
          formattedData={formattedData}
          isComparativeButIllegal={isComparativeButIllegal}
          scaleFactor={scaleFactor}
          customSegmentBy={customSegmentBy}
          widget={widget}
          {...segmentSetterFunctions}
        />
      )
    case WidgetType.BUBBLE_CHART:
      return (
        <BubbleChart
          formattedData={formattedData}
          scaleFactor={scaleFactor}
          customSegmentBy={customSegmentBy}
          widget={widget}
          {...segmentSetterFunctions}
        />
      )
    case WidgetType.KEY_FIGURE:
      return (
        <KeyFigure
          interactive={interactive}
          scaleFactor={scaleFactor}
          widget={widget}
          setDetailsDialog={(initialId: string | null) =>
            showUnderlyingContent([], initialId)
          }
        />
      )
    case WidgetType.COMMENT:
      return null
    case WidgetType.TEXT:
      return null
    case WidgetType.IMAGE:
      return <Image widget={widget} setEdit={setEdit} />
    case WidgetType.COMBO_CHART:
      return (
        <ComboChart
          formattedData={formattedData}
          isComparativeButIllegal={isComparativeButIllegal}
          scaleFactor={scaleFactor}
          customSegmentBy={customSegmentBy}
          widget={widget}
          {...segmentSetterFunctions}
        />
      )
    case WidgetType.LINE_CHART:
      return (
        <LineChart
          formattedData={formattedData}
          scaleFactor={scaleFactor}
          widget={widget}
          {...segmentSetterFunctions}
        />
      )
    case WidgetType.LIST:
      return (
        <Table
          embedded={embedded}
          formattedData={formattedData}
          isComparativeButIllegal={isComparativeButIllegal}
          scaleFactor={scaleFactor}
          customSegmentBy={customSegmentBy}
          widget={widget}
          {...segmentSetterFunctions}
        />
      )
    case WidgetType.MAP:
      return (
        <Map
          formattedData={formattedData}
          isComparativeButIllegal={isComparativeButIllegal}
          scaleFactor={scaleFactor}
          setViewport={setViewport}
          viewport={viewport}
          widget={widget}
          {...segmentSetterFunctions}
        />
      )
    case WidgetType.FUNNEL:
      return (
        <Funnel
          clipContent={fullSize}
          embedded={embedded}
          interactive={interactive}
          scaleFactor={scaleFactor}
          widget={widget}
          {...segmentSetterFunctions}
        />
      )
    case WidgetType.PIE_CHART:
      return (
        <PieChart
          formattedData={formattedData}
          scaleFactor={scaleFactor}
          customSegmentBy={customSegmentBy}
          widget={widget}
          {...segmentSetterFunctions}
        />
      )
    default:
      return null
    }
  }

  return (
    <Box
      sx={{
        flex: 1,
        minHeight: 0,
        display: 'flex',
        flexDirection: 'column',
        userSelect: !fullSize ? 'none' : undefined,
        cursor: !fullSize ? 'default' : undefined
      }}
    >
      {getWidgetType()}
    </Box>
  )
}

export default WidgetBody
