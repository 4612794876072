import React from 'react'

import Box from 'components_new/atoms/Box'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import Logotype from 'assets/logotypes/Logotype'

import { ThemeProvider } from '@mui/material'
import { getTheme, hexToRgbObject } from 'themes'

interface DashboardFooterProps {
  colors?: any
  logotype?: string
  scaleFactor: number
  title: string
  show: boolean
}

const DashboardFooter = (props: DashboardFooterProps) => {
  const { colors, logotype, scaleFactor, title, show } = props

  if (!show) {
    return null
  }

  const textColor = getTextColor(colors?.background_color)

  return (
    <Box
      sx={{
        height: '5%',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          bgcolor: colors?.background_color || 'white.main',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 4 * scaleFactor,
          overflow: 'hidden'
        }}
      >
        <ThemeProvider theme={getTheme('light')}>
          <Box sx={{ flex: 1 }}>
            <Text
              color={textColor}
              fontWeight="bold"
              fontSize={24 * scaleFactor}
              noWrap
            >
              {title}
            </Text>
          </Box>
          {logotype ? (
            <img
              src={logotype}
              alt={'Logotyp'}
              style={{
                height: 32 * scaleFactor
              }}
            />
          ) : (
            <Stack alignItems="center" sx={{ gap: 0.5 }}>
              <Text
                color="text.primary"
                fontWeight="bold"
                fontSize={12 * scaleFactor}
                lineHeight={1}
                noWrap
              >
                Powered by
              </Text>
              <Box
                sx={{
                  height: 32 * scaleFactor,
                  color: textColor
                }}
              >
                <Logotype />
              </Box>
            </Stack>
          )}
        </ThemeProvider>
      </Box>
    </Box>
  )
}

const getTextColor = (color: string | null) => {
  if (color) {
    const rgb = hexToRgbObject(color)
    const black = rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue * 0.114 > 186

    if (black) {
      return '#0f172a'
    } else {
      return '#ffffff'
    }
  } else {
    return '#0f172a'
  }
}

export default DashboardFooter
