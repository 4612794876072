import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Chart as ChartJS, registerables, defaults } from 'chart.js'

import { CssBaseline, ThemeProvider } from '@mui/material'
import { getTheme } from 'themes'

import Auth from 'components/common/Auth'
import Alert from 'components/common/Alert'

import LiveChat from 'components_new/organisms/LiveChat'

import DataPlatformRoutes from './Routes/DataPlatform'
import AdminRoutes from './Routes/Admin'
import ApplicationRoutes from './Routes/Applications'
import ExploreRoutes from './Routes/Explore'
import DashboardRoutes from './Routes/Dashboards'
import ListRoutes from './Routes/Lists'
import OurCustomersRoutes from './Routes/OurCustomers'
import InviteMessagesRoutes from './Routes/InviteMessages'
import InternalRoutes from './Routes/Internal'
import NewTrackingRoutes from './Routes/NewTracking'

import * as AuthActions from 'redux/actions/Authentication'
import * as AlertActions from 'redux/actions/Alert'
import * as UserActions from 'redux/actions/Self'
import * as StatusBannerActions from 'redux/actions/StatusBanner'
import EmbeddedWidget from 'components_new/pages/EmbeddedWidget'
import PublicDashboard from 'components_new/pages/PublicDashboard'
import ApiAccessDashboard from 'components_new/pages/ApiAccessDashboard'
import FortnoxActivation from 'components_new/pages/FortnoxActivation'
import VismaActivation from 'components_new/pages/VismaActivation'

import RedirectWrapper from './RedirectWrapper'
import SnackbarWrapper from './SnackbarWrapper'

import NewUserDialog from 'components_new/organisms/dialogs/NewUserDialog'

import { STATUS_BANNER_TIMER } from 'utils/constants'

ChartJS.register(...registerables)
defaults.font.family = 'IBM Plex Sans'
defaults.color = '#64748b' // grey6

const App = (props) => {
  const { AuthStore, AlertStore, resetAlert } = props
  const router = React.createRef()
  const theme = getTheme('light', null, { responsive: true })

  useEffect(() => {
    // status banner for public pages are controlled in their pages
    if (AuthStore.customer && !AuthStore.customer.is_demo) {
      props.tryGetStatusBanner(AuthStore.customer.customer_id)

      const interval = setInterval(
        () => props.tryGetStatusBanner(AuthStore.customer.customer_id),
        STATUS_BANNER_TIMER
      )

      return () => clearInterval(interval)
    }

    return () => null
  }, [AuthStore.customer?.customer_id])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme={true} />
      <Router ref={router}>
        <Route path={'/public/widgets/:id'} exact>
          <EmbeddedWidget />
        </Route>
        <Route path={'/public/dashboards/:id'} exact>
          <PublicDashboard />
        </Route>
        <Route path={'/public/fortnox-activation'} exact>
          <FortnoxActivation />
        </Route>
        <Route path={'/public/visma-activation'} exact>
          <VismaActivation />
        </Route>
        <Route path={'/api-access/dashboards/:id'} exact>
          <ApiAccessDashboard />
        </Route>
        {window.location.pathname.includes('/public/') ||
        window.location.pathname.includes('/api-access/') ? null : (
            <Auth
              setLoggedIn={() => props.setLoggedIn()}
              isAuthenticated={AuthStore.isAuthenticated}
              validating={AuthStore.validating}
            >
              <RedirectWrapper AuthStore={AuthStore}>
                {AuthStore.isAuthenticated && AuthStore.user ? (
                  <LiveChat
                    user={{
                      email: AuthStore.user.email,
                      id: AuthStore.user.id,
                      name: `${AuthStore.user.first_name} ${AuthStore.user.last_name}`
                    }}
                  />
                ) : null}
                <DataPlatformRoutes AuthStore={AuthStore} />
                <ApplicationRoutes AuthStore={AuthStore} />
                <AdminRoutes AuthStore={AuthStore} />
                <DashboardRoutes AuthStore={AuthStore} />
                <ExploreRoutes AuthStore={AuthStore} />
                <ListRoutes AuthStore={AuthStore} />
                <InternalRoutes AuthStore={AuthStore} />
                <NewTrackingRoutes AuthStore={AuthStore} />
                <OurCustomersRoutes AuthStore={AuthStore} />
                <InviteMessagesRoutes AuthStore={AuthStore} />

                {/*-- dialogs --*/}
                <NewUserDialog
                  onClose={() =>
                    props.tryUpdateSelf(
                      {
                        data: {
                          attributes: {
                            has_finished_onboarding: true
                          }
                        }
                      },
                      false
                    )
                  }
                  open={
                  // eslint-disable-next-line max-len
                    AuthStore.user && !AuthStore.user.has_finished_onboarding
                  }
                />
                {/*-- Old welcome dialog --*/}
                {/* <WelcomeDialog
                      onClose={(role, preference) => {
                        props.tryUpdateSelf({
                          data: {
                            attributes: {
                              additional_information: { role, preference }
                            }
                          }
                        })

                        sendMessageToSlack(
                          'Någon har svarat på välkomstformuläret',
                          `${AuthStore.user.first_name} 
                          ${AuthStore.user.last_name} har angivit:
                          Roll: ${role}
                          Preferens: ${preference}
                      `,
                          AuthStore.user.email
                        )
                      }}
                      open={
                      // true
                        AuthStore.user && !AuthStore.user.additional_information
                      }
                      updating={AuthStore.updatingUser}
                      user={AuthStore.user}
                    /> */}
              </RedirectWrapper>
              <Alert AlertStore={AlertStore} resetAlert={resetAlert} />
              <SnackbarWrapper />
            </Auth>
          )}
      </Router>
    </ThemeProvider>
  )
}

function mapStateToProps({ AuthStore, AlertStore, StatusBannerStore }) {
  return { AuthStore, AlertStore, StatusBannerStore }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...AuthActions,
      ...AlertActions,
      ...UserActions,
      ...StatusBannerActions
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
