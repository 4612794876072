import React from 'react'

import { Link } from 'react-router-dom'

import { SxProps } from '@mui/material'
import { DashboardFolder } from 'types/GlobalDashboardFolder'
import { getEmptyState, SortByMethod } from '../utils'
import { DashboardGroup } from 'redux/reducers/Dashboards'
import { translateDashboardStatus } from 'utils/enumTranslator'
import { AuthenticationState } from 'types/GlobalAuthentication'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Chip from 'components_new/atoms/Chip'
import Icon from 'components_new/atoms/Icon'
import Paper from 'components_new/atoms/Paper'
import Stack from 'components_new/atoms/Stack'
import Text from 'components_new/atoms/Text'

import DashboardThumbnail from 'components_new/molecules/DashboardThumbnail'
import OverflowText from 'components_new/molecules/OverflowText'
import StandardThumbnail from 'components_new/molecules/StandardThumbnail'
import ThumbnailList from 'components_new/molecules/ThumbnailList'
import TooltipToggleButtonGroup from 'components_new/molecules/TooltipToggleButtonGroup'

import DashboardFolderThumbnail from 'components_new/organisms/DashboardFolderThumbnail'

import Loading from './loading'

interface DesktopProps {
  AuthStore: AuthenticationState
  dashboards: DashboardGroup[]
  dashboardUsers: {
    [key: string]: string[]
  }
  folders: DashboardFolder[]
  isHomepal: boolean
  isLoading: boolean
  resetAll: () => void
  selectedFavorites: boolean
  selectedFolder: DashboardFolder | undefined
  sortBy: SortByMethod
  sx?: SxProps
  thumbnails: {
    [id: string]: string
  }
  toggleFavorite: (id: string) => void
  updateSortByMethod: (value: SortByMethod) => void
  userCanEditFolder: boolean
  userIsCustomer: boolean
}

const Desktop = (props: DesktopProps) => {
  const {
    AuthStore,
    dashboards,
    dashboardUsers,
    folders,
    isHomepal,
    isLoading,
    resetAll,
    selectedFavorites,
    selectedFolder,
    sortBy,
    sx,
    thumbnails,
    toggleFavorite,
    updateSortByMethod,
    userCanEditFolder,
    userIsCustomer
  } = props

  // loading
  if (isLoading) return <Loading />

  // success
  return (
    <>
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          ...sx
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            pb: 1
          }}
        >
          {!selectedFolder ? (
            <OverflowText variant="h5">
              {selectedFavorites ? 'Favoriter' : 'Alla dashboards'}
            </OverflowText>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <OverflowText variant="h5" color="text.secondary">
                <Link to={'/dashboards'}>Alla dashboards</Link>
              </OverflowText>
              <Icon color="disabled" name="NavigateNextOutlined" />
              <OverflowText variant="h5">{selectedFolder.name}</OverflowText>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            flex: '1 1 auto',
            position: 'relative',
            overflow: 'auto',
            minHeight: 0,
            p: 2,
            pt: 0
          }}
        >
          {selectedFavorites ||
          Boolean(selectedFolder) ||
          folders.length === 0 ? null : (
              <Box sx={{ my: 3 }}>
                <Text fontWeight="700">Mappar</Text>
                <ThumbnailList>
                  {folders.map((folder) => (
                    <DashboardFolderThumbnail
                      userCanEditFolder={userCanEditFolder}
                      folder={folder}
                      key={folder.id}
                    />
                  ))}
                </ThumbnailList>
              </Box>
            )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between'
            }}
          >
            <Text fontWeight="700">Dashboards</Text>
            <Stack spacing={1} alignItems="center">
              <TooltipToggleButtonGroup
                exclusive={true}
                fullWidth={false}
                items={[
                  {
                    value: SortByMethod.LAST_VIEWED,
                    title: <Icon name="History" />,
                    tooltip: (
                      <>
                        Sortera efter <b>Senast besökt</b>
                      </>
                    )
                  },
                  {
                    value: SortByMethod.ALPHABETICAL,
                    title: <Icon name="TextRotationNone" />,
                    tooltip: (
                      <>
                        Sortera efter <b>A-Ö</b>
                      </>
                    )
                  },
                  {
                    value: SortByMethod.UPDATED_AT,
                    title: <Icon name="DateRange" />,
                    tooltip: (
                      <>
                        Sortera efter <b>Senast ändrad</b>
                      </>
                    )
                  }
                ]}
                size="small"
                value={sortBy}
                onChange={(event: any, value: SortByMethod) => {
                  if (value !== null) updateSortByMethod(value)
                }}
                color="primary"
              />
            </Stack>
          </Box>
          <ThumbnailList>
            {dashboards.length > 0
              ? dashboards.map((group) => (
                <DashboardThumbnail
                  AuthStore={AuthStore}
                  folders={folders}
                  group={group}
                  href={`/dashboards/${group.dashboards[0]}`}
                  users={dashboardUsers?.[group.id] || []}
                  userIsCustomer={userIsCustomer}
                  key={group.id}
                  variant="dashboard"
                  mediaAdornments={
                    isHomepal ? (
                      <Chip
                        color={translateDashboardStatus[group.status].color}
                        label={translateDashboardStatus[group.status].title}
                        size="small"
                      />
                    ) : undefined
                  }
                  src={thumbnails[group.id]}
                  toggleFavorite={
                    toggleFavorite
                      ? () => toggleFavorite(group.id)
                      : undefined
                  }
                />
              ))
              : [
                  <StandardThumbnail
                    key={'placeholder'}
                    disabled={!getEmptyState(selectedFavorites).routeToAll}
                    onClick={() => resetAll()}
                    title={getEmptyState(selectedFavorites).title}
                    body={getEmptyState(selectedFavorites).body}
                    content={
                      <Box
                        sx={{
                          py: 2,
                          display: 'flex',
                          justifyContent: 'center'
                        }}
                      >
                        <img
                          src={getEmptyState(selectedFavorites).src}
                          width="60%"
                        />
                      </Box>
                    }
                    actions={
                      getEmptyState(selectedFavorites).routeToAll ? (
                        <Button
                          endIcon={<Icon name="ArrowForwardOutlined" />}
                          variant="text"
                        >
                          Gå till Alla dashboards
                        </Button>
                      ) : null
                    }
                  />
                ]}
          </ThumbnailList>
        </Box>
      </Paper>
    </>
  )
}

Desktop.displayName = 'Desktop'

export default Desktop
