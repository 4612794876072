import React, { useState } from 'react'

import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import { SxProps } from '@mui/material'

import Banner from 'components_new/molecules/Banner'

/*-- local storage --*/
const LS_HIDDEN_BANNERS_KEY = 'hidden_banners'

interface LSHiddenBanner {
  id: string
  timestamp: number
}
/*-- --*/

const EXPIRE_IN_HOURS = 4

interface BannerBarProps {
  sx?: SxProps
}

function getHiddenBanners() {
  const storedHiddenBanners = localStorage.getItem(LS_HIDDEN_BANNERS_KEY)

  if (storedHiddenBanners) {
    const parsed = JSON.parse(storedHiddenBanners)
    const now = Date.now()

    // filter out expired entries
    const validBanners: LSHiddenBanner[] = parsed.filter(
      (banner: LSHiddenBanner) => banner.timestamp > now
    )

    localStorage.setItem(LS_HIDDEN_BANNERS_KEY, JSON.stringify(validBanners))

    return validBanners.map((banner) => banner.id)
  }

  return []
}

const BannerBar = (props: ComponentProps) => {
  const { StatusBannerStore, sx } = props

  const [hiddenBanners, setHiddenBanners] = useState<string[]>(
    getHiddenBanners()
  )

  const handleClose = (id: string) => {
    const now = Date.now()
    const expiration = now + EXPIRE_IN_HOURS * 60 * 60 * 1000 // hours in ms

    setHiddenBanners([...hiddenBanners, id])

    const updatedStoredHiddenBanners = [
      ...(JSON.parse(localStorage.getItem(LS_HIDDEN_BANNERS_KEY) || '[]') as {
        id: string
        timestamp: number
      }[]),
      { id, timestamp: expiration }
    ]

    localStorage.setItem(
      LS_HIDDEN_BANNERS_KEY,
      JSON.stringify(updatedStoredHiddenBanners)
    )
  }

  if (StatusBannerStore.data.length === 0) return null

  return (
    <>
      {StatusBannerStore.data
        .filter((banner) => !hiddenBanners.includes(banner.id))
        .map((banner) => {
          const iconName = banner.icon ?? 'InfoOutlined'

          return (
            <Banner
              body={banner.description}
              href={banner.link}
              iconName={iconName}
              key={banner.id}
              linkLabel={banner.link_label}
              onClose={() => handleClose(banner.id)}
              sx={sx}
              title={banner.title}
            />
          )
        })}
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  StatusBannerStore: state.StatusBannerStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> & BannerBarProps

BannerBar.displayName = 'BannerBar'

export default connector(BannerBar)
