import { TNavigationItem } from 'components_new/molecules/NavigationItem'

export const getNavigationsItems = (): TNavigationItem[] => {
  return [
    {
      activePaths: ['data-platform/ontology'],
      icon: 'LanTwoTone',
      href: '/data-platform/ontology/standard',
      title: 'Core'
    },
    {
      activePaths: ['data-platform/collect'],
      icon: 'StorageTwoTone',
      href: '/data-platform/collect/sources',
      title: 'Ansluten data'
    },
    {
      activePaths: ['data-platform/build'],
      icon: 'TableViewTwoTone',
      href: '/data-platform/build/registers',
      title: 'Manuell data'
    },
    {
      icon: 'NewReleasesTwoTone',
      href: '/internal/data-quality',
      title: 'Datavalidering'
    },
    {
      activePaths: ['lists'],
      divider: true,
      icon: 'ViewListTwoTone',
      href: '/lists',
      title: 'Listor'
    },
    {
      divider: true,
      icon: 'AutoGraphTwoTone',
      href: '/tracking/dashboard',
      title: 'Dashboard'
    },
    {
      icon: 'Diversity1TwoTone',
      href: '/tracking/users',
      title: 'Våra användare'
    },
    {
      icon: 'AutoAwesomeTwoTone',
      href: '/our-customers',
      title: 'Våra kunder'
    },
    {
      divider: true,
      icon: 'CampaignTwoTone',
      href: '/internal/status-banner',
      title: 'Banner'
    },
    {
      icon: 'ReviewsTwoTone',
      href: '/invite-messages',
      title: 'Meddelanden'
    },
    {
      icon: 'AlternateEmail',
      href: '/internal/emails',
      title: 'Emails'
    }
  ]
}
