import React from 'react'

import { ThemeProvider } from '@mui/material'
import { getColorMode, getTheme } from 'themes'

import { translateDateFilter } from 'utils/enumTranslator'
import { isPeriodFilterEnum } from 'utils/functions'
import { getParsedDateSpan } from 'utils/dateParser'
import { DashboardUserFilterBarProps } from '.'

import Box from 'components_new/atoms/Box'
import Chip from 'components_new/atoms/Chip'

const Mobile = (props: DashboardUserFilterBarProps) => {
  const {
    bgcolor,
    dashboardFilter,
    periodFilter,
    setDashboardFilter,
    setPeriodFilter,
    sx
  } = props

  const isPreset = periodFilter && isPeriodFilterEnum(periodFilter)

  const theme = getTheme(bgcolor ? getColorMode(bgcolor) : 'light', null, {
    responsive: true
  })

  // success
  return dashboardFilter.length > 0 || Boolean(periodFilter) ? (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 1,
          pr: 1,
          overflowX: 'auto',
          WebkitMaskImage:
            'linear-gradient(to right, rgb(0, 0, 0) 95%, rgba(0, 0, 0, 0) 100%)',
          ...sx
        }}
      >
        {/*-- left bleed --*/}
        <Box sx={{ minWidth: '8px', height: '100%' }} />

        {dashboardFilter.map((filter, i) => (
          <Chip
            key={i}
            color="primary"
            label={filter.value === null ? 'Saknar värde' : filter.value}
            onDelete={() =>
              setDashboardFilter(
                dashboardFilter.filter(
                  (f) =>
                    f.relation_key !== filter.relation_key ||
                    f.value !== filter.value
                )
              )
            }
            size="medium"
          />
        ))}
        {periodFilter ? (
          <Chip
            color="primary"
            label={
              isPreset
                ? translateDateFilter[periodFilter]
                : getParsedDateSpan(periodFilter.from, periodFilter.to)
            }
            onDelete={() => setPeriodFilter(null)}
            size="medium"
          />
        ) : null}
      </Box>
    </ThemeProvider>
  ) : null
}

export default Mobile
