import React, { ReactNode } from 'react'

import FormControlLabel from 'components_new/atoms/FormControlLabel'
import Switch from 'components_new/atoms/Switch'

interface SwitchItemProps {
  checked: boolean
  disabled?: boolean
  // If true, the label is rendered as it is passed without an
  // additional text node:
  disableText?: boolean
  label: ReactNode
  onChange?: () => void
  size?: 'small' | 'medium'
  sx?: object
  TextProps?: object
}

const SwitchItem = (props: SwitchItemProps) => {
  const {
    checked,
    disabled,
    disableText,
    label,
    onChange,
    size,
    sx,
    TextProps
  } = props

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          size={size}
        />
      }
      disableTypography={disableText}
      label={label}
      labelPlacement="end"
      slotProps={{
        typography: TextProps
      }}
      sx={{ mx: 0, ...sx }}
    />
  )
}

export default SwitchItem
