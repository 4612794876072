import React, { ReactNode } from 'react'
import { BottomNavigation as MUIBottomNavigation, SxProps } from '@mui/material'

interface BottomNavigationProps {
  children: ReactNode
  onChange: (event: React.SyntheticEvent, value: any) => void
  showLabels: boolean
  sx?: SxProps
  value: any
}

const BottomNavigation = React.forwardRef(
  (props: BottomNavigationProps, ref: any) => {
    return <MUIBottomNavigation {...props} ref={ref} />
  }
)

BottomNavigation.displayName = 'BottomNavigation'
export default BottomNavigation
