import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Divider from 'components_new/atoms/Divider'

import ProfileAvatar from 'components_new/organisms/ProfileAvatar'

interface MobileProps {
  actions?: ReactNode
  displayProfile?: boolean
  children?: ReactNode
  noGutter?: boolean
  sx?: object
}

const Mobile = (props: MobileProps) => {
  const { actions, children, displayProfile, noGutter, sx } = props

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        width: '100%',
        ...sx
      }}
    >
      <Box
        sx={{
          p: noGutter ? 0 : 1,
          flex: '1 1 auto',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          minWidth: 0
        }}
      >
        {children}
      </Box>

      <Box
        sx={{
          p: 1,
          pr: 2,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          flex: 0
        }}
      >
        {actions ? (
          <>
            <Divider flexItem={true} orientation="vertical" />
            {actions}
          </>
        ) : null}
        {displayProfile ? (
          <>
            <Divider flexItem={true} orientation="vertical" />
            <ProfileAvatar />
          </>
        ) : null}
      </Box>
    </Box>
  )
}

export default Mobile
