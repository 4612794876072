import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useMediaQuery, useTheme } from '@mui/material'

import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import * as AccountActions from 'redux/actions/Accounts'
import * as CompanyGroupActions from 'redux/actions/CompanyGroups'
import * as DashboardActions from 'redux/actions/Dashboards'
import * as DashboardFavoriteActions from 'redux/actions/DashboardFavorites'
import * as DashboardFolderActions from 'redux/actions/DashboardFolders'
import * as DashboardGroupActions from 'redux/actions/DashboardGroups'
import * as KPITemplateActions from 'redux/actions/KPITemplates'
import * as WidgetActions from 'redux/actions/Widgets'

import { AccountRole } from 'types/GlobalUser'

import { getAvailableFolders, getAvailableGroups } from 'utils/functions'

import Loading from './loading'
import Mobile from './mobile'
import Desktop from './desktop'

interface DashboardHeaderProps {
  gridRef: any | null
  editMode: boolean
  handleToggleEditMode: () => void
}

const DashboardHeader = (props: ComponentProps) => {
  const {
    gridRef,
    editMode,
    handleToggleEditMode,
    // redux stores:
    AuthStore,
    DashboardFolderStore,
    KPIDashboardStore
  } = props

  const params = useParams<{ id: string }>()
  const dashboard = KPIDashboardStore.data[params.id]
  const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]

  const availableGroups = getAvailableGroups(KPIDashboardStore.groups)
  const folders = getAvailableFolders(
    availableGroups,
    DashboardFolderStore.data,
    AuthStore.user
  )

  const selectedFolder = folders.find(
    (folder) => folder.id === group?.folder_id
  )

  const groupDashboards = useMemo(
    () =>
      KPIDashboardStore.groups[dashboard?.dashboard_group_id]?.dashboards.map(
        (id: string) => ({
          id,
          title: KPIDashboardStore.data[id].title,
          hasAccess: KPIDashboardStore.data[id].has_access
        })
      ),
    [KPIDashboardStore.groups, KPIDashboardStore.data]
  )

  const user = AuthStore.user
  const ownerId = group?.owner

  const isAdmin = user?.role === AccountRole.ADMIN
  const isHomepal = Boolean(user?.is_homepal_user)
  const isOwner = ownerId === user?.id

  const editable = isAdmin || isHomepal || isOwner

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  // loading
  if (
    !Boolean(AuthStore.user) ||
    !DashboardFolderStore.fetched ||
    !KPIDashboardStore.fetched ||
    !group
  ) {
    return <Loading />
  }

  // success
  return isMobile ? (
    <Mobile group={group} />
  ) : (
    <Desktop
      editable={editable}
      editMode={editMode}
      folders={folders}
      gridRef={gridRef}
      group={group}
      groupDashboards={groupDashboards}
      handleToggleEditMode={handleToggleEditMode}
      isAdmin={isAdmin}
      isHomepal={isHomepal}
      isOwner={isOwner}
      selectedFolder={selectedFolder}
    />
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore,
  KPITemplateStore: state.KPITemplateStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountActions,
      ...CompanyGroupActions,
      ...DashboardActions,
      ...DashboardFavoriteActions,
      ...DashboardFolderActions,
      ...DashboardGroupActions,
      ...KPITemplateActions,
      ...WidgetActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & DashboardHeaderProps

export default connector(DashboardHeader)
