import React, { ReactNode } from 'react'

import SimpleDialog from 'components_new/molecules/SimpleDialog'

interface DesktopProps {
  children: ReactNode
  onClose: () => void
  open: boolean
  title: string
}

const Desktop = (props: DesktopProps) => {
  const { children, onClose, open, title } = props

  return (
    <>
      <SimpleDialog onClose={onClose} open={open} title={title}>
        {children}
      </SimpleDialog>
    </>
  )
}

export default Desktop
