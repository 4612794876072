import React, { ReactNode } from 'react'

import { Layout } from 'react-grid-layout'

import { useMediaQuery, useTheme } from '@mui/material'
import Mobile from './mobile'
import Desktop from './desktop'

interface DashboardGridProps {
  children: (props: { scaleFactor: number }) => ReactNode
  colors?: any
  editable: boolean
  gridRef: any | null
  layout: Layout[]
  title: string
  updateLayout: (layout: Layout[]) => void
  variant: {
    embedded: boolean
    type: 'api' | 'portal' | 'public'
  }
}

const DashboardGrid = (props: DashboardGridProps) => {
  const {
    colors,
    children,
    editable,
    gridRef,
    layout,
    title,
    updateLayout,
    variant
  } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  return isMobile ? (
    <Mobile colors={colors}>{children}</Mobile>
  ) : (
    <Desktop
      colors={colors}
      editable={editable}
      gridRef={gridRef}
      layout={layout}
      title={title}
      updateLayout={updateLayout}
      variant={variant}
    >
      {children}
    </Desktop>
  )
}

export default DashboardGrid

// https://github.com/react-grid-layout/react-grid-layout/issues/1104
