import React from 'react'

import { bindActionCreators, Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import * as AuthStoreActions from 'redux/actions/Authentication'

import { SxProps } from '@mui/material'

import Avatar from 'components_new/atoms/Avatar'
import Badge from 'components_new/atoms/Badge'
import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Tooltip from 'components_new/atoms/Tooltip'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'

import OverflowText from 'components_new/molecules/OverflowText'

interface CustomerPreviewProps {
  navLock?: boolean
  sx?: SxProps
}

const CustomerPreview = React.forwardRef((props: ComponentProps, ref) => {
  const { AuthStore, NavigationMenuStore, navLock, sx } = props

  const open = navLock ?? NavigationMenuStore.open

  // default preview
  if (open)
    return (
      <>
        {true ? (
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              gap: 1,
              minWidth: 0,
              ...sx
            }}
          >
            <Avatar sx={{ width: 40, height: 40 }}>
              <Icon name={'BusinessOutlined'} />
            </Avatar>
            <OverflowText color="text.primary" variant="subtitle1">
              {AuthStore.customer?.name}
            </OverflowText>
          </Box>
        ) : (
          <ListItem
            component="div"
            disablePadding={true}
            disableGutters={true}
            ref={ref}
            sx={{ height: 40, pl: 0.75, ...sx }}
          >
            <ListItemIcon sx={{ minWidth: 48, position: 'relative' }}>
              <Avatar>
                <Icon name={'BusinessOutlined'} />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={AuthStore.customer?.name}
              primaryTypographyProps={{
                noWrap: true,
                sx: {
                  mb: -0.5
                },
                variant: 'subtitle1'
              }}
              secondaryTypographyProps={{
                noWrap: true,
                color: 'text.secondary'
              }}
              sx={{ m: 0 }}
            />
          </ListItem>
        )}
      </>
    )

  // collapsed preview
  return (
    <Tooltip
      placement="right"
      title={
        <>
          <b>Organisation</b>
          <br />
          {AuthStore.customer?.name}
        </>
      }
    >
      <Badge
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <Icon
            name={'BusinessOutlined'}
            sx={{
              fontSize: '12px'
            }}
          />
        }
        overlap={'circular'}
        sx={{
          '& .MuiBadge-badge': {
            p: 0
          }
        }}
      >
        <Avatar alt={AuthStore.customer?.name} ref={ref} />
      </Badge>
    </Tooltip>
  )
})

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AuthStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & CustomerPreviewProps

export default connector(CustomerPreview)
