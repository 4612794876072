import React, { useMemo } from 'react'

import { useLocation } from 'react-router-dom'

import Box from 'components_new/atoms/Box'
import Button from 'components_new/atoms/Button'
import Divider from 'components_new/atoms/Divider'
import Icon from 'components_new/atoms/Icon'
import Text from 'components_new/atoms/Text'

const MOBILE_SUPPORT_EXACT = ['/admin/settings/users']
const MOBILE_SUPPORT_PREFIX = ['/dashboards']

const MobileSupportChecker = () => {
  const location = useLocation()

  const isMobileSupported = useMemo(() => {
    return (
      MOBILE_SUPPORT_EXACT.includes(location.pathname) ||
      MOBILE_SUPPORT_PREFIX.some((prefix) =>
        location.pathname.startsWith(prefix)
      )
    )
  }, [location])

  if (isMobileSupported) return null

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 9999,
          height: '100vh',
          width: '100vw',
          top: 0,
          left: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: 'background.default'
        }}
      >
        <Box
          sx={{
            width: 256,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2
          }}
        >
          <Icon color="disabled" fontSize="large" name="MobileOffOutlined" />
          <Text align="center" color="text.secondary">
            Homepal stöder inte mobilläge för den här sidan.
          </Text>
          <Text align="center" color="text.primary" fontWeight="bold">
            Gör ditt fönster större 🙌
          </Text>
          <Divider flexItem={true}>eller</Divider>
          <Button to="/dashboards">Gå till startskärmen</Button>
        </Box>
      </Box>
    </>
  )
}

MobileSupportChecker.displayName = 'MobileSupportChecker'
export default MobileSupportChecker
