import React, { useEffect } from 'react'

import UserTrackingSidebar from 'components_new/organisms/UserTrackingSidebar'

import InternalTemplate from 'components_new/templates/InternalTemplate'
import UserTrackingTemplate from 'components_new/templates/internal/UserTracking'

import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { ApplicationState } from 'redux/Stores/types'

import * as TrackingActions from 'redux/actions/Tracking'
import { useParams } from 'react-router-dom'
import { PatchUserBody } from 'types/GlobalUser'

const mapStateToProps = (state: ApplicationState) => ({
  TrackingStore: state.TrackingStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...TrackingActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

// If more props should be used, extend prop with connectedProps
export type TrackingPageProps = ConnectedProps<typeof connector>

const UserTracking = (props: TrackingPageProps) => {
  const { TrackingStore } = props
  const params = useParams<{ id: string }>()

  useEffect(() => {
    if (!TrackingStore.oneUserUsage[params.id]) {
      props.tryGetOneUserUsage(params.id)
    }
  }, [])

  const user = TrackingStore.oneUserUsage[params.id]

  return (
    <InternalTemplate
      leftSidebar={
        <UserTrackingSidebar
          updateUser={(body: PatchUserBody) =>
            props.tryUpdateUser(params.id, body)
          }
          user={user}
        />
      }
    >
      {user ? <UserTrackingTemplate user={user} /> : null}
    </InternalTemplate>
  )
}

export default connector(UserTracking)
