import React from 'react'

import Box from 'components_new/atoms/Box'
import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 1 }}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
        <Skeleton key={item} variant="rounded" width={'100%'} height={56} />
      ))}
    </Box>
  )
}

Loading.displayName = 'Loading'

export default Loading
