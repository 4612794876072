import React from 'react'

import { AccountRole } from 'types/GlobalUser'

import Autocomplete from 'components_new/atoms/Autocomplete'
import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import List from 'components_new/atoms/List'
import ListItem from 'components_new/atoms/List/ListItem'
import ListItemAvatar from 'components_new/atoms/List/ListItemAvatar'
import ListItemText from 'components_new/atoms/List/ListItemText'
import TextField from 'components_new/atoms/TextField'
import Switch from 'components_new/atoms/Switch'
import FormControlLabel from 'components_new/atoms/FormControlLabel'

import SectionHeader from 'components_new/molecules/SectionHeader'

import { UserType } from 'components_new/organisms/Dashboard/utils'

import PermissionItem from '../PermissionSection/PermissionItem'
import { CompanyGroupWithPermission } from 'types/GlobalCompanyGroups'

interface CustomersSectionProps {
  allCompanyGroupsHasAccess: boolean
  customerOptions: CompanyGroupWithPermission[]
  fullRights: boolean
  onInvite: (companyGroupId: string) => void
  onRemove: (companyGroupId: string) => void
  setAllCompanyGroupsHasAccess: (allCompanyGroupsHasAccess: boolean) => void
  userOptions: UserType[]
}

const CustomersSection = (props: CustomersSectionProps) => {
  const {
    allCompanyGroupsHasAccess,
    customerOptions,
    fullRights,
    onInvite,
    onRemove,
    setAllCompanyGroupsHasAccess,
    userOptions
  } = props

  const notAddedCustomerOptions = customerOptions.filter(
    (customer) => !customer.hasPermission
  )

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={allCompanyGroupsHasAccess}
              onChange={() => {
                setAllCompanyGroupsHasAccess(!allCompanyGroupsHasAccess)
              }}
            />
          }
          label={'Alla koncerner har åtkomst'}
          labelPlacement={'end'}
        />
      </Box>
      {allCompanyGroupsHasAccess ? null : (
        <Autocomplete
          blurOnSelect={true}
          disableClearable
          value={null}
          onChange={(event, newCustomer: CompanyGroupWithPermission) => {
            onInvite(newCustomer.id)
          }}
          options={notAddedCustomerOptions}
          filterSelectedOptions
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          popupIcon={<Icon name="Add" />}
          renderOption={(props, option) => (
            <ListItem {...props}>
              <ListItemAvatar>
                <Icon color="disabled" name="BusinessOutlined" />
              </ListItemAvatar>
              <ListItemText
                primary={option.name}
                secondary={`${
                  userOptions.filter((u) => u.companyGroupId === option.id)
                    .length
                } användare`}
              />
            </ListItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Lägg till koncern"
              placeholder="Sök..."
            />
          )}
          renderTags={() => null}
          sx={{
            '& .MuiFormControl-root': {
              mt: 0,
              mb: 2
            }
          }}
        />
      )}
      <SectionHeader title="Koncerner med åtkomst" />
      <List sx={{ pt: 0 }}>
        {customerOptions
          .filter(
            (customer) => customer.hasPermission || allCompanyGroupsHasAccess
          )
          .sort((a, b) => a.name.localeCompare(b.name, 'sv'))
          .map((customer, i) => (
            <PermissionItem
              body={`${
                userOptions.filter((u) => u.companyGroupId === customer.id)
                  .length
              } användare`}
              editable={fullRights}
              fullRights={fullRights}
              isOwner={false}
              key={i}
              onDelete={() => {
                onRemove(customer.id)
              }}
              role={AccountRole.VIEW}
              title={customer.name}
              variant="customer"
            />
          ))}
      </List>
    </Box>
  )
}

export default CustomersSection
