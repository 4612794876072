import React, { ReactNode, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useResizeDetector } from 'react-resize-detector'

import Box from 'components_new/atoms/Box'

import AspectRatioContainer from 'components_new/molecules/AspectRatioContainer'

import DashboardFooter from 'components_new/organisms/DashboardFooter'

import { Responsive as ResponsiveGridLayout, Layout } from 'react-grid-layout'

import '../../../../node_modules/react-grid-layout/css/styles.css'
import '../../../../node_modules/react-resizable/css/styles.css'
import './index.css'

import { IGNORE_ON_TRANSPARENT_EXPORT } from '../dialogs/ExportDialog'

interface DesktopProps {
  children: (props: { scaleFactor: number }) => ReactNode
  colors?: any
  editable: boolean
  gridRef: any | null
  layout: Layout[]
  title: string
  updateLayout: (layout: Layout[]) => void
  variant: {
    embedded: boolean
    type: 'api' | 'portal' | 'public'
  }
}

const Desktop = (props: DesktopProps) => {
  const {
    colors,
    children,
    editable,
    gridRef,
    layout,
    title,
    updateLayout,
    variant
  } = props

  const layoutMapper = useMemo(() => {
    const mapper: { [key: string]: Layout } = {}

    layout.forEach((l) => (mapper[l.i] = l))

    return mapper
  }, [layout])

  const { width, height, ref } = useResizeDetector()

  const scaleFactor = (height as number) / 1080

  const padding = scaleFactor * 1

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const showFooter =
    variant.embedded && queryParams.get('hide_footer') !== 'true'

  const gridHeight = showFooter
    ? (height as number) * 0.95 - padding * 8 * 2
    : (height as number) * 1.0 - padding * 8 * 2

  const gridWidth = (width as number) - padding * 8 * 2

  return (
    <AspectRatioContainer ratio={0}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative'
        }}
        ref={(el: HTMLDivElement) => {
          if (gridRef) {
            gridRef.current = el
          }

          ref.current = el
        }}
      >
        <Box
          id={IGNORE_ON_TRANSPARENT_EXPORT}
          sx={{
            position: 'absolute',
            zIndex: 0,
            width: '100%',
            height: '100%',
            bgcolor: colors?.background_color ?? 'background.paper'
          }}
        />
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            height: '100%',
            width: '100%',
            p: padding
          }}
        >
          <ResponsiveGridLayout
            className="layout"
            layouts={{ lg: layout }}
            onLayoutChange={(l: Layout[]) => {
              const layoutChanged = l.some((item) => {
                const match = layoutMapper[item.i]

                if (!match) {
                  return true
                }

                return (
                  item.h !== match.h ||
                  item.w !== match.w ||
                  item.x !== match.x ||
                  item.y !== match.y
                )
              })

              if (layoutChanged) {
                updateLayout(l)
              }
            }}
            rowHeight={gridHeight / 12}
            maxRows={12}
            breakpoints={{
              lg: 1200,
              md: 996,
              sm: 768,
              xs: 480,
              xxs: 0
            }}
            cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
            width={gridWidth}
            margin={[0, 0]}
            compactType={null}
            preventCollision={true}
            useCSSTransforms={true}
            isDraggable={editable}
            isResizable={editable}
          >
            {children({ scaleFactor })}
          </ResponsiveGridLayout>
          <DashboardFooter
            colors={colors}
            logotype={colors?.logotype}
            scaleFactor={scaleFactor}
            title={title}
            show={showFooter}
          />
        </Box>
      </div>
    </AspectRatioContainer>
  )
}

export default Desktop

// https://github.com/react-grid-layout/react-grid-layout/issues/1104
