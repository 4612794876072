import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import Link from 'components_new/atoms/Link'
import Switch from 'components_new/atoms/Switch'
import Tooltip from 'components_new/atoms/Tooltip'

import SectionHeader from 'components_new/molecules/SectionHeader'
import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import { TEmbedType } from '.'

interface AccessToggleProps {
  apiAccess?: boolean
  embedType: TEmbedType
  isManagementCompany: boolean
  isPublic: boolean
  setApiAccess?: (value: boolean) => void
  setIsPublic: (value: boolean) => void
  editable: boolean
}

const AccessToggle = (props: AccessToggleProps) => {
  const {
    apiAccess,
    editable,
    embedType,
    isManagementCompany,
    isPublic,
    setApiAccess,
    setIsPublic
  } = props

  return (
    <Box>
      <SectionHeader sx={{ mb: 2 }} title="Åtkomst" />
      <SettingsGroup>
        <SettingsItem
          actions={
            <Tooltip
              title={
                'Endast ägare eller adminstratörer av dashboarden kan ändra det här.'
              }
              disableHoverListener={editable}
            >
              <span>
                <Switch
                  color="success"
                  checked={isPublic}
                  disabled={!editable}
                  onChange={() => setIsPublic(!isPublic)}
                  size="medium"
                />
              </span>
            </Tooltip>
          }
          body={`Alla som har tillgång till den publika länken kan se ${embedType}en.`}
          disabled={!editable}
          icon={<Icon name={'PublicOutlined'} />}
          title={'Publik länk'}
        />

        {embedType === 'dashboard' && setApiAccess && isManagementCompany ? (
          <SettingsItem
            actions={
              <Tooltip
                title={
                  'Endast ägare eller adminstratörer av dashboarden kan ändra det här.'
                }
                disableHoverListener={editable}
              >
                <span>
                  <Switch
                    color="success"
                    checked={apiAccess}
                    disabled={!editable}
                    onChange={() => setApiAccess(!apiAccess)}
                    size="medium"
                  />
                </span>
              </Tooltip>
            }
            body={
              <>
                Alla som har tillgång till en API-nyckel kan se {embedType}
                en via en dynamisk länk.
                <br />
                Du kan skapa och hitta API-nycklar i{' '}
                <Link href="/admin/settings/api-keys" newTab={true}>
                  Inställningar
                </Link>
                .
              </>
            }
            disabled={!editable}
            icon={<Icon name={'VpnKeyOutlined'} />}
            title={'Dynamisk länk'}
          />
        ) : null}
      </SettingsGroup>
    </Box>
  )
}

export default AccessToggle
