import React, { ReactNode } from 'react'

import Button from 'components_new/atoms/Button'

import AdvancedDialog from 'components_new/molecules/AdvancedDialog'

interface DesktopProps {
  children: ReactNode
  onClose: () => void
  open: boolean
  title: string
}

const Desktop = (props: DesktopProps) => {
  const { children, onClose, open, title } = props

  return (
    <>
      <AdvancedDialog
        actions={
          <Button variant="text" onClick={onClose}>
            Stäng
          </Button>
        }
        onClose={onClose}
        open={open}
        title={title}
      >
        {children}
      </AdvancedDialog>
    </>
  )
}

export default Desktop
