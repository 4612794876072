import React, { MouseEvent } from 'react'
import { cloneDeep } from 'lodash'

import Box from 'components_new/atoms/Box'
import Table from 'components_new/atoms/Table'
import TableBody from 'components_new/atoms/Table/TableBody'
import TableCell from 'components_new/atoms/Table/TableCell'
import TableContainer from 'components_new/atoms/Table/TableContainer'
import TableRow from 'components_new/atoms/Table/TableRow'

import TrendText from 'components_new/molecules/TrendText'

import { KeyFigureVisualizationType, WidgetObject } from 'types/GlobalWidget'

import { TParsedKpiValues } from '../utils'
import { translateStatus } from 'utils/enumTranslator'

interface ListContainerProps {
  data: TParsedKpiValues[]
  interactive: boolean
  scaleFactor: number
  widget: WidgetObject
  onClick: (event: MouseEvent<HTMLElement>, option: TParsedKpiValues) => void
}

const ListContainer = (props: ListContainerProps) => {
  const { data, interactive, onClick, scaleFactor, widget } = props

  let options = cloneDeep(data)
  let textColor: string | undefined = undefined

  if (
    widget.settings.key_figure_visualization_type ===
    KeyFigureVisualizationType.FOCUS
  ) {
    options = options.slice(1)
    textColor = 'text.secondary'
  }

  if (options.length === 0) {
    return null
  }

  const showComparativePeriod = Boolean(
    widget.settings.comparative_period.selected.value
  )

  const fontSize = `${1 * scaleFactor}rem`
  const listPadding = `${8 * scaleFactor}px`

  return (
    <TableContainer sx={{ overflow: 'visible' }}>
      <Table size={'small'}>
        <TableBody>
          {options.map((option) => (
            <TableRow
              key={option.id}
              onClick={
                interactive ? (event) => onClick(event, option) : () => {}
              }
              sx={{
                cursor: interactive ? 'pointer' : undefined,
                '&:hover': interactive
                  ? {
                      '.SegmentLabel': {
                        textDecoration: 'underline'
                      },
                      '.ValueLabel': {
                        textDecoration: 'underline'
                      }
                    }
                  : undefined
              }}
            >
              <TableCell
                className="SegmentLabel"
                scope="row"
                size="small"
                sx={{
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: 10,
                  padding: `${listPadding} 0px`,
                  color: textColor,
                  fontSize
                }}
              >
                {option.title}
              </TableCell>
              <TableCell
                className="ValueLabel"
                scope="row"
                sx={{
                  width: '0px',
                  whiteSpace: 'normal',
                  minWidth: 0,
                  padding: `${listPadding} 0px ${listPadding} 8px`,
                  color: option.status
                    ? `${translateStatus[option.status].colorName}.text`
                    : undefined
                }}
                align="right"
                size="small"
              >
                <Box
                  sx={{
                    textWrap: 'nowrap',
                    fontWeight: 'bold',
                    fontSize
                  }}
                >
                  {option.value} {option.unit}
                </Box>
              </TableCell>
              {showComparativePeriod ? (
                <TableCell
                  className="ComparativeLabel"
                  scope="row"
                  sx={{
                    width: '0px',
                    whiteSpace: 'normal',
                    minWidth: 0,
                    padding: `${listPadding} 0px ${listPadding} 4px`,
                    fontSize,
                    textDecoration: 'none'
                  }}
                  align="right"
                  size="small"
                >
                  <TrendText
                    equivalentFactor={option.equivalentFactor}
                    difference={option.difference}
                    increaseIsPositive={option.increaseIsPositive}
                    numberOfDecimals={option.numberOfDecimals || 0}
                    percentage={option.percentage || 0}
                    unitPrefix={option.unitPrefixSelected}
                  />
                </TableCell>
              ) : null}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ListContainer
