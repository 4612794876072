import React, { useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import { ThemeProvider } from '@mui/material'
import { getColorMode, getTheme } from 'themes'

import Box from 'components_new/atoms/Box'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'

interface MobileTabSelectorProps {
  sx?: object
}

const MobileTabSelector = (props: ComponentProps) => {
  const { DashboardFolderStore, CustomizationStore, KPIDashboardStore } = props

  const history = useHistory()

  const params = useParams<{ id: string }>()
  const dashboard = KPIDashboardStore.data[params.id]

  const groupDashboards = useMemo(
    () =>
      KPIDashboardStore.groups[dashboard?.dashboard_group_id]?.dashboards.map(
        (id: string) => ({
          id,
          title: KPIDashboardStore.data[id].title,
          hasAccess: KPIDashboardStore.data[id].has_access
        })
      ),
    [KPIDashboardStore.groups, KPIDashboardStore.data]
  )

  const isLoading = !DashboardFolderStore.fetched || !KPIDashboardStore.fetched

  if (isLoading) return <></>

  return (
    <ThemeProvider
      theme={getTheme(
        getColorMode(CustomizationStore.colors?.background_color ?? 'light'),
        null,
        { responsive: true }
      )}
    >
      <Box
        sx={{
          bgcolor:
            CustomizationStore?.colors?.background_color ?? 'background.paper',
          px: 1.75,
          pb: 1.75,
          pt: 3.5
        }}
      >
        <TextField
          label="Flik"
          noGutter={true}
          onChange={(event) =>
            history.push(`/dashboards/${event.target.value}`)
          }
          placeholder="Välj..."
          select={true}
          value={params.id}
        >
          {groupDashboards.map((dashboard) => (
            <MenuItem key={dashboard.id} value={dashboard.id}>
              {dashboard.title}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </ThemeProvider>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  CustomizationStore: state.CustomizationStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> & MobileTabSelectorProps

export default connector(MobileTabSelector)
