import React, { useState } from 'react'

import { ThemeProvider, useMediaQuery, useTheme } from '@mui/material'
import { getTheme, getWidgetMode } from 'themes'

import { CustomColors } from 'types/GlobalCustomization'
import { Customer } from 'types/GlobalCustomer'
import {
  DashboardFilter,
  ParsedDashboardFilterOptions
} from 'types/GlobalDashboardFilter'
import {
  KpiOptionPatchAttributes,
  KpiOptionPostAttributes
} from 'types/GlobalKpiOption'
import {
  KpiTemplateFilterOptionsBody,
  KpiTemplateRedux
} from 'types/GlobalKpiTemplates'
import {
  CustomPeriodFilter,
  FormattedWidgetData,
  FunnelStagePutBody,
  InnerWidgetPatchBody,
  PeriodFilter,
  ParsedSegmentPath,
  TViewport,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import {
  WidgetTagPatchAttributes,
  WidgetTagPostAttributes
} from 'types/GlobalWidgetTag'
import { LayoutItem } from 'redux/reducers/Dashboards'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'

import DashboardUserFilterBar from 'components_new/organisms/DashboardUserFilterBar'

import FullSizeDialog from './dialogs/FullSizeDialog'

import WidgetBanner from './WidgetBanner'
import WidgetBase from './WidgetBase'
import WidgetBody from './WidgetBody'
import WidgetFooter from './WidgetFooter'
import WidgetMenu from './WidgetMenu'
import WidgetHeader from './WidgetHeader'
import WidgetSettings from './WidgetSettings'

interface WidgetProps {
  availableSpace: LayoutItem | null
  allowBenchmarking?: boolean
  colors?: CustomColors | null
  createAdditionalSegmentBy?: (attributeOptionId: string) => void
  createKpiOption?: (body: KpiOptionPostAttributes) => void
  createWidgetTag?: (body: WidgetTagPostAttributes) => void
  customer: Customer | null
  customPeriodFilter?: CustomPeriodFilter | null
  customSegmentBy?: string | null
  dashboardFilter: DashboardFilter
  dashboardFilterOptions: ParsedDashboardFilterOptions[]
  deleteAdditionalSegmentBy?: (id: string) => void
  deleteKpiOption?: (id: string) => void
  deleteWidgetTag?: (id: string) => void
  editMode: boolean
  embedded?: boolean
  enableFullscreen?: boolean
  exportWidgetData?: () => void
  fetchedKpis?: boolean
  filterOptions?: KpiTemplateFilterOptionsBody | null
  formattedData: FormattedWidgetData
  isAdmin: boolean
  kpiTemplates?: KpiTemplateRedux
  kpiTemplatesFetched?: boolean
  loading: boolean
  periodFilter?: PeriodFilter | null
  plain?: boolean
  putFunnelStages?: (body: FunnelStagePutBody) => void
  resetAllDashboardTempStates: () => void
  scaleFactor: number
  setCustomSegmentBy?: (
    attributeOptionId: string | null,
    dbFilter?: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  setDashboardFilter?: (
    dbFilter: DashboardFilter,
    periodFilter?: CustomPeriodFilter
  ) => void
  setPeriodFilter?:
    | ((value: PeriodFilter | CustomPeriodFilter | null) => void)
    | ((value: CustomPeriodFilter | null) => void)
  standaloneWidget?: boolean
  updateAdditionalSegmentBy?: (id: string, attributeOptionId: string) => void
  updateKpiOption?: (id: string, body: KpiOptionPatchAttributes) => void
  updateWidget?: (arg0: string, arg1: InnerWidgetPatchBody) => void
  updateWidgetTag?: (id: string, body: WidgetTagPatchAttributes) => void
  uploadImage?: (image: Blob, name: string) => void
  widget: WidgetObject
  showUnderlyingContent?: (
    segmentPaths: ParsedSegmentPath[],
    initialKpiOptionId: string | null
  ) => void
}

export const STANDARD_WIDGET_SCALE_FACTOR = 0.8

const Widget = (props: WidgetProps) => {
  const {
    allowBenchmarking = true,
    availableSpace,
    colors,
    createAdditionalSegmentBy = () => {},
    createKpiOption = () => {},
    createWidgetTag = () => {},
    customer,
    customPeriodFilter = null,
    customSegmentBy = null,
    dashboardFilter,
    dashboardFilterOptions,
    deleteAdditionalSegmentBy = () => {},
    deleteKpiOption = () => {},
    deleteWidgetTag = () => {},
    editMode = false,
    embedded = false,
    enableFullscreen,
    exportWidgetData = () => {},
    fetchedKpis = true,
    filterOptions = null,
    formattedData,
    isAdmin = false,
    kpiTemplates = {},
    kpiTemplatesFetched = false,
    loading,
    periodFilter = null,
    plain = false,
    putFunnelStages = () => {},
    resetAllDashboardTempStates = () => {},
    scaleFactor,
    setCustomSegmentBy = () => {},
    setDashboardFilter = () => {},
    setPeriodFilter = () => {},
    standaloneWidget = false,
    updateAdditionalSegmentBy = () => {},
    updateKpiOption = () => {},
    updateWidget = () => {},
    updateWidgetTag = () => {},
    uploadImage = () => {},
    widget,
    showUnderlyingContent = () => {}
  } = props

  const [fullSizeDialogOpen, setFullSizeDialogOpen] = useState(false)
  const [edit, setEdit] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  const widgetMode = getWidgetMode(colors)

  // For MAP only, required in both settings and widget:
  const [viewport, setViewport] = useState<TViewport>({
    longitude: widget.settings.viewport.longitude,
    latitude: widget.settings.viewport.latitude,
    pitch: widget.settings.viewport.pitch,
    zoom: widget.settings.viewport.zoom
  })

  /*-- For Map beta: --*/
  const [fakeLoading, setFakeLoading] = useState(false)

  function triggerFakeLoading() {
    setFakeLoading(true)

    setTimeout(() => setFakeLoading(false), 200)
  }
  /*-----*/

  const getWigetBanner = (fullSize: boolean) => {
    const controlledScaleFactor = fullSize
      ? STANDARD_WIDGET_SCALE_FACTOR
      : scaleFactor

    return (
      <WidgetBanner
        clearCustomSegmentBy={() => setCustomSegmentBy(null)}
        colors={colors}
        customSegmentBy={customSegmentBy}
        fullSize={fullSize}
        scaleFactor={controlledScaleFactor}
        widget={widget}
      />
    )
  }

  const getWidgetBody = (fullSize: boolean) => {
    const controlledScaleFactor = fullSize
      ? STANDARD_WIDGET_SCALE_FACTOR
      : scaleFactor

    return (
      <WidgetBody
        dashboardFilter={dashboardFilter}
        embedded={embedded}
        fakeLoading={fakeLoading}
        formattedData={formattedData}
        fullSize={fullSize}
        interactive={!isMobile && !editMode}
        isAdmin={isAdmin}
        loading={loading}
        periodFilter={periodFilter}
        scaleFactor={controlledScaleFactor}
        customSegmentBy={customSegmentBy}
        setDashboardFilter={setDashboardFilter}
        showUnderlyingContent={showUnderlyingContent}
        setEdit={setEdit}
        setCustomSegmentBy={setCustomSegmentBy}
        setViewport={setViewport}
        standaloneWidget={standaloneWidget}
        viewport={viewport}
        widget={widget}
      />
    )
  }

  const getWigetFilterBar = () => {
    return (
      <DashboardUserFilterBar
        dashboardFilter={dashboardFilter}
        periodFilter={periodFilter}
        resetAllTempStates={resetAllDashboardTempStates}
        setDashboardFilter={setDashboardFilter}
        setPeriodFilter={setPeriodFilter}
        sx={{ border: 'none', p: 0, mb: 1 }}
      />
    )
  }

  const getWidgetFooter = (fullSize: boolean) => {
    const controlledScaleFactor = fullSize
      ? STANDARD_WIDGET_SCALE_FACTOR
      : scaleFactor

    return (
      <WidgetFooter
        customPeriodFilter={customPeriodFilter}
        interactive={!isMobile && !editMode}
        loading={loading}
        periodFilter={periodFilter}
        scaleFactor={controlledScaleFactor}
        widget={widget}
      />
    )
  }

  const getWidgetHeader = (fullSize: boolean) => {
    const controlledScaleFactor = fullSize
      ? STANDARD_WIDGET_SCALE_FACTOR
      : scaleFactor

    return (
      <WidgetHeader
        editMode={editMode}
        scaleFactor={controlledScaleFactor}
        updateWidget={updateWidget}
        widget={widget}
      />
    )
  }

  const getWidgetMenu = (fullSize: boolean) => {
    if (isMobile) return null

    return (
      <WidgetMenu
        availableSpace={availableSpace}
        customSegmentBy={customSegmentBy}
        dashboardFilterOptions={dashboardFilterOptions}
        editMode={editMode}
        embedded={embedded}
        enableFullscreen={enableFullscreen}
        exportWidgetData={exportWidgetData}
        formattedData={formattedData}
        fullSize={fullSize}
        isHomepalOrAdmin={isAdmin}
        loading={loading}
        kpiTemplates={kpiTemplates}
        scaleFactor={scaleFactor}
        showUnderlyingContent={showUnderlyingContent}
        setFullSizeDialogOpen={setFullSizeDialogOpen}
        setEdit={() => setEdit(true)}
        widget={widget}
      />
    )
  }

  return (
    <>
      {/*-- default widget --*/}
      <ThemeProvider theme={getTheme(widgetMode, colors)}>
        <WidgetBase
          colors={colors}
          editMode={editMode}
          plain={plain}
          scaleFactor={scaleFactor}
          widgetType={widget.settings.type.selected}
        >
          {getWigetBanner(false)}
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              minWidth: 0,
              position: 'relative',
              overflow: 'hidden',
              p:
                widget.settings.type.selected !== WidgetType.IMAGE
                  ? 2 * scaleFactor
                  : 0
            }}
          >
            {getWidgetMenu(false)}
            <Collapse in={!customSegmentBy}>{getWidgetHeader(false)}</Collapse>
            {getWidgetBody(false)}
            {getWidgetFooter(false)}
          </Box>
        </WidgetBase>
      </ThemeProvider>

      {/*-- full size widget --*/}
      <FullSizeDialog
        banner={getWigetBanner(true)}
        body={getWidgetBody(true)}
        colors={colors}
        customSegmentBy={customSegmentBy}
        filterBar={getWigetFilterBar()}
        footer={getWidgetFooter(true)}
        header={getWidgetHeader(true)}
        menu={getWidgetMenu(true)}
        onClose={() => setFullSizeDialogOpen(false)}
        open={fullSizeDialogOpen}
        widgetMode={widgetMode}
        widgetType={widget.settings.type.selected}
      />

      <ThemeProvider theme={getTheme('light')}>
        {/*-- widget drawer --*/}
        <WidgetSettings
          allowBenchmarking={allowBenchmarking}
          createAdditionalSegmentBy={createAdditionalSegmentBy}
          createKpiOption={createKpiOption}
          createWidgetTag={createWidgetTag}
          customer={customer}
          deleteAdditionalSegmentBy={deleteAdditionalSegmentBy}
          deleteKpiOption={deleteKpiOption}
          deleteWidgetTag={deleteWidgetTag}
          fetchedKpis={fetchedKpis}
          filterOptions={filterOptions}
          fullSize={fullSizeDialogOpen}
          kpiTemplates={kpiTemplates}
          kpiTemplatesFetched={kpiTemplatesFetched}
          open={edit}
          onClose={() => setEdit(false)}
          putFunnelStages={putFunnelStages}
          setViewport={setViewport}
          triggerFakeLoading={triggerFakeLoading}
          updateAdditionalSegmentBy={updateAdditionalSegmentBy}
          updateKpiOption={updateKpiOption}
          updateWidget={updateWidget}
          updateWidgetTag={updateWidgetTag}
          uploadImage={uploadImage}
          viewport={viewport}
          widget={widget}
        />
      </ThemeProvider>
    </>
  )
}

export default Widget
