import Box from 'components_new/atoms/Box'
import React from 'react'
import {
  CustomPeriodFilter,
  PeriodFilter,
  WidgetObject,
  WidgetType
} from 'types/GlobalWidget'
import DateFilterSection from './DateFilterSection'

interface WidgetFooterProps {
  customPeriodFilter: CustomPeriodFilter | null
  interactive: boolean
  loading: boolean
  periodFilter: PeriodFilter | null
  scaleFactor: number
  widget: WidgetObject
}

const WidgetFooter = (props: WidgetFooterProps) => {
  const {
    customPeriodFilter,
    interactive,
    loading,
    periodFilter,
    scaleFactor,
    widget
  } = props

  const showFooter =
    widget.settings.type.selected !== WidgetType.COMMENT &&
    widget.settings.type.selected !== WidgetType.IMAGE

  return showFooter ? (
    <Box
      sx={{
        mt: scaleFactor,
        position: 'relative'
      }}
    >
      <DateFilterSection
        loading={loading}
        dashboardCustomPeriodFilter={customPeriodFilter}
        dashboardPeriodFilter={periodFilter}
        interactive={interactive}
        scaleFactor={scaleFactor}
        widget={widget}
      />
    </Box>
  ) : null
}

export default WidgetFooter
