import React, {
  ReactElement,
  ElementType,
  ReactNode,
  MouseEventHandler
} from 'react'
import { Chip as MUIChip } from '@mui/material'
import { Color } from 'themes'

interface ChipProps {
  avatar?: ReactElement
  clickable?: boolean
  color?:
    | Color
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'brand'
    | 'black'
    | 'white'
  component?: ElementType<any>
  deleteIcon?: ReactElement
  disabled?: boolean
  icon?: ReactElement
  label?: ReactNode
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onDelete?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  size?: 'medium' | 'small' | 'xsmall'
  sx?: object
  variant?: 'filled' | 'outlined'
}

/**
 * The Chip component is a compact element
 * that represent an input, attribute, or action.
 */

const Chip = React.forwardRef((props: ChipProps, ref: any) => {
  const { size, sx, ...rest } = props

  return (
    <MUIChip
      size={size === 'xsmall' ? 'small' : size}
      sx={{
        ...(props.color ? { bgcolor: `${props.color}.background` } : null),
        ...(size === 'xsmall' ? { fontSize: '0.7rem', height: '18px' } : null),
        ...sx
      }}
      {...rest}
      ref={ref}
    />
  )
})

Chip.displayName = 'Chip'
export default Chip
