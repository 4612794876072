import React from 'react'

import { DashboardGroup } from 'redux/reducers/Dashboards'
import { DashboardFolder } from 'types/GlobalDashboardFolder'
import { getParsedDate } from 'utils/dateParser'

import { getEmptyState } from '../utils'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import List from 'components_new/atoms/List'
import ListItemButton from 'components_new/atoms/List/ListItemButton'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'

import EmptyPlaceholder from 'components_new/molecules/EmptyPlaceholder'

import Loading from './loading'

interface MobileProps {
  dashboards: DashboardGroup[]
  folders: DashboardFolder[]
  isLoading: boolean
  selectedFavorites: boolean
  selectedFolder: DashboardFolder | undefined
}

const Mobile = (props: MobileProps) => {
  const { dashboards, folders, isLoading, selectedFavorites, selectedFolder } =
    props

  // loading
  if (isLoading) return <Loading />

  // success
  return (
    <>
      <List>
        {!selectedFolder && !selectedFavorites ? (
          <>
            {folders.map((folder) => (
              <ListItemButton
                key={folder.id}
                to={`/dashboards/folders/${folder.id}`}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Icon name="FolderOutlined" />
                </ListItemIcon>
                <ListItemText
                  primary={folder.name}
                  primaryTypographyProps={{
                    fontWeight: 'medium',
                    variant: 'body1',
                    noWrap: true
                  }}
                  secondary={<>Uppdaterad {getParsedDate(folder.updated_at)}</>}
                  secondaryTypographyProps={{
                    color: 'text.secondary',
                    noWrap: true
                  }}
                />
                <Icon name="ChevronRight" />
              </ListItemButton>
            ))}
          </>
        ) : null}
        {dashboards.length > 0 ? (
          dashboards.map((group) => (
            <ListItemButton
              key={group.id}
              to={`/dashboards/${group.dashboards[0]}`}
            >
              <ListItemIcon sx={{ minWidth: 40 }}>
                <Icon name="AnalyticsOutlined" />
              </ListItemIcon>
              <ListItemText
                primary={group.title}
                primaryTypographyProps={{
                  fontWeight: 'medium',
                  variant: 'body1',
                  noWrap: true
                }}
                secondary={
                  <>
                    {group.favorite.active ? (
                      <Icon
                        name="Star"
                        sx={{
                          fontSize: '0.8rem',
                          mr: '0.25rem',
                          verticalAlign: '-0.1rem'
                        }}
                      />
                    ) : null}
                    Uppdaterad {getParsedDate(group.updated_at)}
                  </>
                }
                secondaryTypographyProps={{
                  color: 'text.secondary',
                  noWrap: true
                }}
              />
              <Icon name="ChevronRight" />
            </ListItemButton>
          ))
        ) : (
          <EmptyPlaceholder
            actions={
              getEmptyState(selectedFavorites).routeToAll ? (
                <Button
                  endIcon={<Icon name="ArrowForwardOutlined" />}
                  sx={{ width: 'fit-content' }}
                  to="/dashboards"
                >
                  Gå till Alla dashboards
                </Button>
              ) : undefined
            }
            body={getEmptyState(selectedFavorites).body}
            empty={true}
            size="medium"
            src={getEmptyState(selectedFavorites).src}
            title={getEmptyState(selectedFavorites).title}
          />
        )}
      </List>
    </>
  )
}

Mobile.displayName = 'Mobile'

export default Mobile
