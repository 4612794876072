import React from 'react'

import { WhoCanView } from 'redux/reducers/Dashboards'

import Icon from 'components_new/atoms/Icon'
import ListItemIcon from 'components_new/atoms/List/ListItemIcon'
import ListItemText from 'components_new/atoms/List/ListItemText'
import MenuItem from 'components_new/atoms/Menu/MenuItem'
import TextField from 'components_new/atoms/TextField'

import { translateWhoCanView } from 'utils/enumTranslator'

interface WhoCanViewSectionProps {
  editable: boolean
  setWhoCanView: (value: WhoCanView) => void
  whoCanView: WhoCanView
}

const whoCanViewOptions = [WhoCanView.ONLY_ADDED_USERS, WhoCanView.ORGANIZATION]

const WhoCanViewSection = (props: WhoCanViewSectionProps) => {
  const { editable, setWhoCanView, whoCanView } = props

  return (
    <>
      <TextField
        disabled={!editable}
        onChange={(event) => setWhoCanView(event.target.value as WhoCanView)}
        InputProps={{
          readOnly: !editable
        }}
        select={true}
        SelectProps={{
          renderValue: (value: WhoCanView) => (
            <MenuItem
              sx={{
                py: 0,
                '&:hover': {
                  bgcolor: 'transparent'
                }
              }}
            >
              <ListItemIcon>
                <Icon name={translateWhoCanView[value].iconName} />
              </ListItemIcon>
              <ListItemText
                primary={translateWhoCanView[value].title}
                secondary={translateWhoCanView[value].description}
              />
            </MenuItem>
          )
        }}
        value={whoCanView}
      >
        {whoCanViewOptions.map((value, i) => (
          <MenuItem value={value} key={i}>
            <ListItemIcon>
              <Icon name={translateWhoCanView[value].iconName} />
            </ListItemIcon>
            <ListItemText
              primary={translateWhoCanView[value].title}
              secondary={translateWhoCanView[value].description}
            />
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}

export default WhoCanViewSection
