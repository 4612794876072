import React from 'react'

import Box from 'components_new/atoms/Box'
import Paper from 'components_new/atoms/Paper'
import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return (
    <>
      <Paper
        sx={{
          height: '100%',
          width: '100%',
          overflowY: 'auto',
          position: 'relative',
          p: 4,
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box sx={{ width: '100%', maxWidth: 'lg' }}>
          <Skeleton variant="rounded" width={600} height={52} sx={{ mb: 1 }} />
          <Skeleton variant="rounded" width={240} height={14} sx={{ mb: 2 }} />
          <Skeleton variant="rounded" width="100%" height={630} />
        </Box>
      </Paper>
    </>
  )
}

Loading.displayName = 'Loading'

export default Loading
