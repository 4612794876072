import React, { useState } from 'react'

import { PageContainerProps } from '.'

import { ThemeProvider, useTheme } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'

import FixedSideButton from 'components_new/molecules/FixedSideButton'

import BannerBar from 'components_new/organisms/BannerBar'
import Navbar from 'components_new/organisms/Navbar'
import Topbar from 'components_new/organisms/Topbar'

import { getTheme } from 'themes'

interface DesktopProps extends PageContainerProps {
  NavbarProps?: PageContainerProps['NavbarProps'] & {
    open: boolean
  }
}

const Desktop = (props: DesktopProps) => {
  const {
    bgcolor,
    bottombar,
    children,
    embedded,
    leftSidebar,
    LeftSidebarProps,
    NavbarProps,
    noGutter,
    rightSidebar,
    RightSidebarProps,
    toolbar,
    ToolbarProps,
    topbar,
    TopbarProps,
    unauthorized,
    ...rest
  } = props

  const [collapsed, setCollapsed] = useState<boolean>(false)
  const theme = useTheme()

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        height: '100vh',
        minHeight: 0
      }}
    >
      {/*-- bannerbar --*/}
      {unauthorized ? null : <BannerBar />}
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'hidden',
          bgcolor: bgcolor ?? 'background.default',
          minHeight: 0
        }}
      >
        {/*-- topbar --*/}
        {!embedded && !unauthorized ? (
          <Topbar
            actions={TopbarProps?.actions}
            displayProfile={TopbarProps?.displayProfile}
            navLocked={NavbarProps?.locked}
            noGutter={TopbarProps?.noGutter}
            title={NavbarProps?.header}
          >
            {topbar}
          </Topbar>
        ) : null}

        <Box
          {...rest}
          sx={{
            position: 'relative',
            height: '100%',
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'row',
            overflowX: 'hidden',
            bgcolor: bgcolor ?? 'background.default',
            minHeight: 0
          }}
        >
          {(NavbarProps?.content ||
            NavbarProps?.footer ||
            NavbarProps?.header ||
            NavbarProps?.menu) &&
          !embedded ? (
                <ThemeProvider theme={getTheme('light')}>
                  <Box
                    sx={{
                      position: 'relative',
                      height: '100%',
                      // eslint-disable-next-line max-len
                      minWidth: NavbarProps.open || NavbarProps.locked ? 260 : 56,
                      // eslint-disable-next-line max-len
                      maxWidth: NavbarProps.open || NavbarProps.locked ? 500 : 56,
                      flexBasis:
                    NavbarProps.open || NavbarProps.locked ? '8%' : undefined,
                      transition: 'all 0.1s',
                      // eslint-disable-next-line max-len
                      transitionTimingFunction: theme.transitions.easing.easeInOut,
                      bgcolor: bgcolor ?? 'background.default'
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        overflow: 'auto'
                      }}
                    >
                      {/*-- navbar --*/}
                      <Navbar
                        content={NavbarProps.content}
                        locked={NavbarProps.locked}
                        footer={NavbarProps.footer}
                        menu={NavbarProps.menu}
                      />
                    </Box>
                  </Box>
                </ThemeProvider>
              ) : null}

          {/*-- left sidebar --*/}
          {leftSidebar && !embedded ? (
            <Box
              component="nav"
              sx={{
                position: 'relative',
                height: '100%',
                minWidth: LeftSidebarProps?.size === 'small' ? 220 : 280,
                maxWidth: 500,
                flexBasis: LeftSidebarProps?.size === 'small' ? '15%' : '20%'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  overflowY: 'auto'
                }}
              >
                {leftSidebar}
              </Box>
            </Box>
          ) : null}

          <Box
            sx={{
              position: 'relative',
              height: '100%',
              flex: '1 1 auto',
              display: 'flex',
              flexDirection: 'column',
              // overflowX: 'hidden',
              overflow: 'hidden',
              minHeight: 0
            }}
          >
            {/*-- toolbar --*/}
            {toolbar ? (
              <Collapse in={ToolbarProps?.open}>
                <Box
                  sx={{
                    height: 40,
                    width: '100%'
                  }}
                >
                  {toolbar}
                </Box>
              </Collapse>
            ) : null}

            <Box
              component="main"
              sx={{
                position: 'relative',
                height: '100%',
                flex: '1 1 auto',
                minWidth: 0
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                  overflow: 'auto',
                  pb: embedded || noGutter ? undefined : 2,
                  pr: embedded || noGutter ? undefined : 2
                }}
              >
                {children}

                {/*-- right sidebar --*/}
                {rightSidebar ? (
                  <FixedSideButton
                    badge={RightSidebarProps?.badge}
                    iconName={
                      RightSidebarProps?.iconName
                        ? RightSidebarProps.iconName
                        : collapsed
                          ? 'ChevronLeft'
                          : 'ChevronRight'
                    }
                    onClick={() => setCollapsed(!collapsed)}
                    tooltip={`${
                      collapsed ? 'Öppna' : 'Stäng'
                    } ${RightSidebarProps?.tooltip?.toLowerCase()}`}
                  />
                ) : null}
              </Box>
            </Box>

            {/*-- bottombar --*/}
            {bottombar ? (
              <Box
                component="footer"
                sx={{
                  flex: '0 0 40px',
                  height: 40,
                  width: '100%',
                  px: 1,
                  bgcolor: 'background.default',
                  borderLeft: '1px solid',
                  borderColor: 'divider'
                }}
              >
                {bottombar}
              </Box>
            ) : null}
          </Box>

          {/*-- right side bar --*/}
          {rightSidebar ? (
            <Box
              sx={{
                bgcolor: 'background.default',
                border: '1px solid',
                borderColor: 'divider',
                height: '100%',
                minWidth: collapsed ? 0 : 260,
                maxWidth: collapsed ? 0 : 500,
                flexBasis: collapsed ? undefined : '8%',
                position: 'relative',
                transition: theme.transitions.create('width', {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.leavingScreen
                })
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  overflowY: 'auto'
                }}
              >
                {rightSidebar}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

Desktop.displayName = 'Desktop'

export default Desktop
