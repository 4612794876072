/** Enums */
export enum UserStatus {
  ACTIVATION_FAILED = 'ACTIVATION_FAILED',
  ACTIVATION_IN_PROGRESS = 'ACTIVATION_IN_PROGRESS',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CHURN = 'CHURN'
}

export enum UserTag {
  ACTIVE_WEEKS_STREAK = 'ACTIVE_WEEKS_STREAK',
  CREATED_DASHBOARDS = 'CREATED_DASHBOARDS',
  DASHBOARD_VIEWS = 'DASHBOARD_VIEWS',
  INACTIVE_WEEKS_STREAK = 'INACTIVE_WEEKS_STREAK',
  INVITED_USERS = 'INVITED_USERS',
  NO_ACTIVITY = 'NO_ACTIVITY',
  NOT_ACCEPTED = 'NOT_ACCEPTED'
}

export enum UserTitle {
  CEO = 'CEO',
  CFO = 'CFO',
  IT = 'IT',
  LESSOR = 'LESSOR',
  TECHINCAL_MANAGER = 'TECHINCAL_MANAGER',
  REGION_MANAGER = 'REGION_MANAGER',
  FINANCE_CONTROLLER = 'FINANCE_CONTROLLER',
  CO_WORKER_TECHINCAL_MANAGEMENT = 'CO_WORKER_TECHINCAL_MANAGEMENT',
  MANAGEMENT_MANAGER = 'MANAGEMENT_MANAGER',
  CEO_ASSISTANT = 'CEO_ASSISTANT',
  LESSOR_MANAGER = 'LESSOR_MANAGER'
}

export enum ActivationType {
  FAST_LANE = 'FAST_LANE',
  SLOW_LANE = 'SLOW_LANE'
}

export const translateActivationType: Record<
  ActivationType,
  {
    title: string
    body: string
    tooltip: string
    icon: string
  }
> = {
  [ActivationType.FAST_LANE]: {
    title: 'Fast lane',
    body: '4 veckor i rad',
    tooltip: 'Användaren aktiverades genom att vara aktiv 4 veckor i rad',
    icon: '🏎️'
  },
  [ActivationType.SLOW_LANE]: {
    title: 'Slow lane',
    body: '3 månader i rad',
    tooltip: 'Användaren aktiverades genom att vara aktiv 3 månader i rad',
    icon: '🐌'
  }
}

/** Types */
export type TrackingUser = {
  id: string
  additional_information: {
    role: string
    preference: string
  } | null
  full_name: string
  email: string
  ignore_from_statistics: boolean
  swimlane: UserStatus
  customer: {
    id: number
    name: string
    license: string // @TODO: License enum.
  }
  title: UserTitle | null
  is_admin: boolean
  data: {
    activated_at: string | null
    activation_type: ActivationType | null
    invited_at: string
    invite_accepted_at: string
    viewed_dashboards: number
  }
  tags: { id: UserTag; value: boolean; count: number | null }[]
  views: {
    per_dashboard: { labels: string[]; values: number[] }
    per_day: { labels: string[]; values: number[] }
    latest_views: { dashboard: string; viewed_at: string }[]
  }
  open_replay_url: string | null
}

export type ActivationDashboardAccount = {
  days_to_activated: number | null
  days_to_activated_or_today: number
  created_at: string
  activated_at: string | null
  is_activated: boolean
  created_before_end_of_aug: boolean
}
export type ActivationDashboard = {
  activation_over_time: {
    label: string
    accounts: ActivationDashboardAccount[]
  }[]
  accounts: ActivationDashboardAccount[]
}
