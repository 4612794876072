import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js'
import JwtDecode from 'jwt-decode'
import Axios from 'axios'
import {
  VERIFICATION_CODE,
  SET_PASSWORD,
  SIGNED_IN,
  LOGIN_WITH_AZURE
} from './utils'
import { handleLoginWithAzure } from './ActionHandlers'

const API_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3990/'
    : process.env.REACT_APP_HOMEPAL_MDM_API_PROD

export const CALLBACK_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/callback'
    : 'https://portal.homepal.se/callback'

export const COGNITO_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://dev-homepal.auth.eu-north-1.amazoncognito.com'
    : 'https://homepal.auth.eu-north-1.amazoncognito.com'

class Storage {
  constructor() {
    this.cookies = {}
    this.settingCookies = {}
  }

  getItem(key) {
    return key in this.cookies ? this.cookies[key] : undefined
  }

  async setItem(key, value) {
    this.settingCookies[key] = true
    await Axios.post(
      `${API_URL}cookie`,
      { cookie: key, value: value },
      { withCredentials: true }
    )
    this.cookies[key] = value
    delete this.settingCookies[key]
  }

  removeItem(key) {
    delete this.cookies[key]
  }

  waitForCookies(callback) {
    const interval = setInterval(() => {
      if (Object.keys(this.settingCookies).length === 0) {
        clearInterval(interval)
        callback()
      }
    }, 300)
  }
}

const storage = new Storage()

const pool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  Storage: storage
})

export const signOut = async () => {
  const user = pool.getCurrentUser()

  await Axios.post(`${API_URL}sign-out`, {}, { withCredentials: true })

  if (user) {
    await new Promise((resolve) => user.signOut(() => resolve()))
  }
}

const getCurrentUser = async () => {
  try {
    const response = await Axios.get(`${API_URL}last-user`, {
      withCredentials: true
    })

    if (
      response.data.has_idp &&
      (response.data.email?.includes('@stockholm.se') ||
        response.data.email?.includes('@stockholmtest.se'))
    ) {
      // Sign out so that we ensure that we revalidate from the IDP.
      await signOut()

      handleLoginWithAzure({ email: response.data.email })

      return new CognitoUser({
        Username: response.data.last_user,
        Pool: pool,
        Storage: storage
      })
    } else if (response.data.last_user) {
      const cognitoUser = new CognitoUser({
        Username: response.data.last_user,
        Pool: pool,
        Storage: storage
      })

      return cognitoUser
    }

    return null
  } catch (e) {
    return null
  }
}

async function postToSlack(email, data) {
  if (
    process.env.NODE_ENV === 'production' &&
    email &&
    !email.includes('@homepal.se')
  ) {
    return Axios.post(
      'https://hooks.slack.com/services/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',
      JSON.stringify(data),
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' }
      }
    )
  }
}

/**
 * Send slack message that user is requesting forgot password code
 * @param {string} email - Email of which user has forgot password to.
 */
async function notifyForgotPassword(email, emailExists = true) {
  const data = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: ':circular-love: Någon försöker återställa sitt lösenord!'
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Email: *${email}*`
        }
      },
      emailExists
        ? null
        : {
            type: 'section',
            text: {
              type: 'mrkdwn',
              text: '*Användaren finns ej registrerad i Homepal.*'
            }
          },
      {
        type: 'divider'
      },
      {
        type: 'context',
        elements: [
          {
            type: 'mrkdwn',
            text: '*Verifiera* att denna person lyckas komma in i portalen igen.'
          }
        ]
      }
    ].filter(Boolean)
  }

  return postToSlack(email, data)
}

/**
 * Send slack message that user is requesting forgot password code
 * @param {string} email - Email of which user has forgot password to.
 */
export async function sendLogToSlack(email, log, type) {
  const data = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: `:circular-love: Något gick fel vid ${type}!`
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Email: *${email}*`
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'context',
        elements: [
          {
            type: 'mrkdwn',
            text: `*Error: ${log}.`
          }
        ]
      }
    ].filter(Boolean)
  }

  return postToSlack(email, data)
}

/**
 * Send slack custom message
 * @param {string} title   - The title of the message
 * @param {string} message - The message
 * @param {string} email   - Email of the user
 */
export async function sendMessageToSlack(title, message, email) {
  const data = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: title
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Email: *${email}*`
        }
      },
      {
        type: 'divider'
      },
      {
        type: 'context',
        elements: [
          {
            type: 'mrkdwn',
            text: message
          }
        ]
      }
    ].filter(Boolean)
  }

  return postToSlack(email, data)
}

export const getIdpLoginAction = (setState) => {
  return new Promise(async (resolve) => {
    const nrOfIdps = (await Axios.get(`${API_URL}nr-of-idps`)).data.nr_of_idps

    // We want to resolve a function here, but resolve calls
    // the function therefore we do () => () =>
    if (nrOfIdps === 0) {
      resolve(() => () => {})
    } else if (nrOfIdps > 1) {
      resolve(() => () => setState(LOGIN_WITH_AZURE))
    } else {
      resolve(
        () => () =>
          (window.location = `${COGNITO_BASE_URL}/oauth2/authorize?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${CALLBACK_URL}`)
      )
    }
  })
}

export const getUserFromEmail = (email) => {
  return new CognitoUser({
    Storage: storage,
    Username: email,
    Pool: pool
  })
}

export const login = (params, rememberMe = false) => {
  const username = params.email.toLowerCase()

  const user = getUserFromEmail(username)

  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: params.password
  })

  return new Promise((resolve) => {
    user.authenticateUser(authDetails, {
      onSuccess: () => {
        // onSuccess/onFailure is required, but we don't do anything
        if (rememberMe) {
          user.setDeviceStatusRemembered({
            onSuccess: () => {},
            onFailure: () => {}
          })
        } else {
          user.forgetDevice({ onSuccess: () => {}, onFailure: () => {} })
        }

        storage.waitForCookies(() => resolve({ type: SIGNED_IN }))
      },
      onFailure: () => {
        if (authDetails.username.includes('@')) {
          resolve({
            type: 'ERROR',
            error: 'Du har angivit en ogiltig email eller lösenord.'
          })
        } else {
          resolve({
            type: 'INVALID_EMAIL',
            error: 'För att logga in måste du använda en giltig email.'
          })
        }
      },
      newPasswordRequired: (data) => {
        delete data.email_verified

        resolve({ type: SET_PASSWORD, user })
      }
    })
  })
}

export const saveSamlSession = async (authResponse) => {
  const username = JwtDecode(authResponse.id_token)['cognito:username']

  const keyPrefix = `CognitoIdentityServiceProvider.${pool.getClientId()}`
  const idTokenKey = `${keyPrefix}.${username}.idToken`
  const accessTokenKey = `${keyPrefix}.${username}.accessToken`
  const refreshTokenKey = `${keyPrefix}.${username}.refreshToken`
  const lastUserKey = `${keyPrefix}.LastAuthUser`

  await pool.storage.setItem(accessTokenKey, authResponse.access_token)
  await pool.storage.setItem(idTokenKey, authResponse.id_token)
  await pool.storage.setItem(refreshTokenKey, authResponse.refresh_token)
  await pool.storage.setItem(lastUserKey, username)
}

export const getSession = () => {
  return new Promise(async (resolve, reject) => {
    const user = await getCurrentUser()

    if (!user) {
      return reject()
    }

    resolve()
  })
}

export const forgotPassword = (email) => {
  const user = getUserFromEmail(email.toLowerCase())

  return new Promise((resolve) =>
    user.forgotPassword({
      onFailure: () => {
        notifyForgotPassword(email, false)
        resolve({ type: 'ERROR', error: 'Kunde inte hitta användaren' })
      },
      inputVerificationCode: () => {
        notifyForgotPassword(email, true)
        resolve({ type: VERIFICATION_CODE, user })
      }
    })
  )
}

export const confirmPassword = (code, password, user) => {
  return new Promise((resolve) =>
    user.confirmPassword(code, password, {
      onSuccess: async () => {
        await login({ password, email: user.username })
        storage.waitForCookies(() => resolve({ type: SIGNED_IN }))
      },
      onFailure: () => {
        resolve({ type: 'ERROR', error: 'Något gick fel..' })
      }
    })
  )
}

export const setNewPasswordChallenge = (password, user, clientMetadata) => {
  return new Promise((resolve, reject) =>
    user.completeNewPasswordChallenge(
      password,
      {},
      {
        onSuccess: () => {
          storage.waitForCookies(() => resolve({ type: SIGNED_IN }))
        },
        onFailure: () => {
          reject()
        }
      },
      {
        ...clientMetadata
      }
    )
  )
}
