import React, { useEffect, useMemo, useState } from 'react'

import { useParams } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@mui/material'

import { ApplicationState } from 'redux/Stores/types'
import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import * as DashboardActions from 'redux/actions/Dashboards'
import * as AccountRoleActions from 'redux/actions/AccountRoles'

import { AccountRole } from 'types/GlobalUser'
import { PermissionPatchType } from 'components_new/organisms/Dashboard/utils'

import { formatUser, getPermissions } from './utils'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'
import Tooltip from 'components_new/atoms/Tooltip'

import Desktop from './desktop'
import Loading from './loading'
import Mobile from './mobile'

interface ShareDashboardButtonProps {
  sx?: object
}

const ShareDashboardButton = (props: ComponentProps) => {
  const {
    sx,
    // redux stores
    AccountStore,
    AuthStore,
    CompanyGroupStore,
    DashboardFilterOptionsStore,
    KPIDashboardStore,
    //redux actions
    tryUpdateDashboard,
    tryRevokeFromDashboard,
    tryInviteToDashboard
  } = props

  useEffect(() => {
    const { tryGetAllAccountRoles } = props

    if (!AccountStore.rolesFetched) {
      tryGetAllAccountRoles()
    }
  }, [])

  const userIsCustomer = Boolean(AuthStore?.user?.is_company_group)
  const customer = AuthStore.customer

  // A customer should never be able to create anything:
  if (userIsCustomer) return null

  const [dialog, setDialog] = useState<{
    open: boolean
    data: { email: string }
  }>({
    open: false,
    data: {
      email: ''
    }
  })

  const handleOpenDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: true }))
  }

  const handleCloseDialog = () => {
    setDialog((prevState) => ({ ...prevState, open: false }))
  }

  const params = useParams<{ id: string }>()
  const dashboard = KPIDashboardStore.data[params.id]
  const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]
  const ownerId = group?.owner

  const user = AuthStore.user
  const isHomepal = user?.is_homepal_user
  const isAdmin = user?.role === AccountRole.ADMIN || !!isHomepal
  const isOwner = ownerId === user?.id

  const groupDashboards = useMemo(
    () =>
      KPIDashboardStore.groups[dashboard?.dashboard_group_id]?.dashboards.map(
        (id: string) => ({
          id,
          title: KPIDashboardStore.data[id].title,
          hasAccess: KPIDashboardStore.data[id].has_access
        })
      ),
    [KPIDashboardStore.groups, KPIDashboardStore.data]
  )

  const companyGroups = useMemo(() => {
    return Object.values(CompanyGroupStore.data).map((cg) => {
      const hasPermission = KPIDashboardStore.data[
        params.id
      ]?.share_settings.permissions.find(
        (perm) => perm.company_group_id === cg.id
      )

      return {
        ...cg,
        hasPermission: !!hasPermission
      }
    })
  }, [CompanyGroupStore, KPIDashboardStore])

  const userOptions = useMemo(() => {
    return Object.values(AccountStore.data)
      .filter(
        (account) =>
          account.role === AccountRole.VIEW &&
          account.id !== user?.id &&
          // remove owner of dashboard from user options
          (ownerId ? account.id !== ownerId : true)
      )
      .map((account) => formatUser(account, false))
  }, [AccountStore.data, user, ownerId])

  const permissions = useMemo(
    () =>
      getPermissions(
        AccountStore,
        KPIDashboardStore.data,
        params.id,
        user,
        ownerId
      ),
    [dashboard?.share_settings, AccountStore.data, user, ownerId]
  )

  const theme = useTheme()
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  // loading
  if (
    !CompanyGroupStore.fetched ||
    !AccountStore.rolesFetched ||
    !KPIDashboardStore.fetched ||
    !Boolean(dashboard)
  ) {
    return <Loading />
  }

  // success
  return (
    <>
      <Tooltip title={!lgUp ? 'Dela' : ''}>
        <span>
          {lgUp ? (
            <Button
              color="primary"
              onClick={handleOpenDialog}
              startIcon={
                <Icon name="ReplyOutlined" sx={{ transform: 'scaleX(-1)' }} />
              }
              sx={sx}
              variant="contained"
            >
              Dela
            </Button>
          ) : (
            <IconButton
              color="primary"
              onClick={handleOpenDialog}
              sx={sx}
              variant="contained"
            >
              <Icon name="ReplyOutlined" sx={{ transform: 'scaleX(-1)' }} />
            </IconButton>
          )}
        </span>
      </Tooltip>

      {/*-- dialog / drawer --*/}
      {isMobile ? (
        <Mobile
          fullRights={isAdmin || isHomepal || isOwner}
          isAdmin={isAdmin}
          isPublic={dashboard?.share_settings?.is_public}
          isStockholmshem={
            !!(
              AuthStore.user?.email?.includes('@stockholm.se') ||
              AuthStore.user?.email?.includes('@stockholmtest.se')
            )
          }
          inviteUser={(body: PermissionPatchType) => {
            tryInviteToDashboard(dashboard?.id, {
              company_group_id: body.companyGroupId,
              account_id: body.accountId,
              message: body.message,
              new_user_email: body.newUserEmail
            })
          }}
          publicLink={dashboard?.share_settings?.public_link}
          permissions={permissions}
          onInvite={(body: PermissionPatchType) => {
            tryInviteToDashboard(dashboard?.id, {
              company_group_id: body.companyGroupId,
              account_id: body.accountId,
              message: body.message,
              new_user_email: body.newUserEmail
            })
          }}
          onRemove={(accountId?: string, companyGroupId?: string) => {
            tryRevokeFromDashboard(dashboard?.id, accountId, companyGroupId)
          }}
          userOptions={userOptions}
          whoCanView={dashboard?.share_settings?.who_can_view}
          // ...
          onClose={handleCloseDialog}
          onOpen={handleOpenDialog}
          open={dialog.open}
          title={`Dela ${
            groupDashboards.find((item) => item.id === dashboard?.id)?.title ??
            ''
          }`}
        />
      ) : (
        <Desktop
          allowEmbed={Boolean(AuthStore.customer?.allow_access?.embed)}
          apiAccess={dashboard?.share_settings?.api_access}
          allCompanyGroupsHasAccess={
            dashboard?.share_settings?.all_company_groups_has_access
          }
          fullRights={isAdmin || isHomepal || isOwner}
          isManagementCompany={Boolean(customer?.is_management_company)}
          isPublic={dashboard?.share_settings?.is_public}
          isStockholmshem={
            !!(
              AuthStore.user?.email?.includes('@stockholm.se') ||
              AuthStore.user?.email?.includes('@stockholmtest.se')
            )
          }
          open={dialog.open}
          onClose={handleCloseDialog}
          setApiAccess={(apiAccess) => {
            tryUpdateDashboard(dashboard.id, {
              data: { api_access: apiAccess }
            })
          }}
          setAllCompanyGroupsHasAccess={(cgAccess) => {
            tryUpdateDashboard(dashboard.id, {
              data: { all_company_groups_has_access: cgAccess }
            })
          }}
          setIsPublic={(isPublic) => {
            tryUpdateDashboard(dashboard.id, { data: { is_public: isPublic } })
          }}
          link={dashboard?.share_settings?.link}
          publicLink={dashboard?.share_settings?.public_link}
          dashboardFilterOptions={dashboard?.dashboard_filters.map((filter) => {
            const option = DashboardFilterOptionsStore.options.find(
              (opt) => opt.relation_key === filter.relation_key
            )

            return {
              attribute_id: filter.attribute_id,
              title: option?.title || '',
              relation_key: filter.relation_key,
              relation_name: option?.relation_name ?? null,
              index: filter.index,
              // eslint-disable-next-line max-len
              options: DashboardFilterOptionsStore.data[filter.relation_key]
            }
          })}
          dashboardTitle={
            groupDashboards.find((item) => item.id === dashboard?.id)?.title ??
            ''
          }
          groupTitle={group.title}
          inviteUser={(body: PermissionPatchType) => {
            tryInviteToDashboard(dashboard?.id, {
              company_group_id: body.companyGroupId,
              account_id: body.accountId,
              message: body.message,
              new_user_email: body.newUserEmail
            })
          }}
          revokeUser={(accountId?: string, companyGroupId?: string) => {
            tryRevokeFromDashboard(dashboard?.id, accountId, companyGroupId)
          }}
          setWhoCanView={(whoCanView) => {
            tryUpdateDashboard(dashboard?.id, {
              data: { permission_type: whoCanView }
            })
          }}
          isAdmin={isAdmin}
          customerOptions={companyGroups}
          userOptions={userOptions}
          userPermissions={permissions}
          whoCanView={dashboard?.share_settings?.who_can_view}
        />
      )}
    </>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  CompanyGroupStore: state.CompanyGroupStore,
  DashboardFilterOptionsStore: state.DashboardFilterOptionsStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountRoleActions,
      ...DashboardActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  ShareDashboardButtonProps

export default connector(ShareDashboardButton)
