import React, { ReactNode } from 'react'

import LockedModeWrapper from 'App/LockedModeWrapper'

import NavigationHeader from 'components_new/molecules/NavigationHeader'

import InternalNavigation from 'components_new/organisms/InternalNavigation'
import PageContainer from 'components_new/organisms/PageContainer'

interface InternalTemplateProps {
  children?: ReactNode
  leftSidebar?: ReactNode
}

const InternalTemplate = (props: InternalTemplateProps) => {
  const { children, leftSidebar } = props

  return (
    <LockedModeWrapper>
      <PageContainer
        leftSidebar={leftSidebar}
        NavbarProps={{
          header: <NavigationHeader title="Admin" />,
          menu: <InternalNavigation />
        }}
        noGutter={true}
      >
        {children}
      </PageContainer>
    </LockedModeWrapper>
  )
}

export default InternalTemplate
