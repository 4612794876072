import React, { ReactNode } from 'react'

import { Helmet } from 'react-helmet'

import { useMediaQuery, useTheme } from '@mui/material'

import NavigationHeader from 'components_new/molecules/NavigationHeader'

import CustomerPreview from 'components_new/organisms/CustomerPreview'
import GlobalMobileNavigation from 'components_new/organisms/GlobalMobileNavigation'
import InviteButton from 'components_new/organisms/InviteButton'
import PageContainer from 'components_new/organisms/PageContainer'
import SettingsNavigation from 'components_new/organisms/SettingsNavigation'

interface SettingsTemplateProps {
  children?: ReactNode
}

const SettingsTemplate = (props: SettingsTemplateProps) => {
  const { children } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={theme.palette.background.default} />
      </Helmet>

      <PageContainer
        NavbarProps={{
          header: <NavigationHeader title="Inställningar" />,
          menu: isMobile ? <GlobalMobileNavigation /> : <SettingsNavigation />
        }}
        topbar={isMobile ? <CustomerPreview navLock={true} /> : undefined}
        TopbarProps={{
          actions: isMobile ? <InviteButton /> : undefined
        }}
      >
        {children}
      </PageContainer>
    </>
  )
}

export default SettingsTemplate
