import React from 'react'

import Box from 'components_new/atoms/Box'
import Skeleton from 'components_new/atoms/Skeleton'

const Loading = () => {
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Skeleton variant="rounded" height={24} width={200} />
      </Box>
    </>
  )
}

Loading.displayName = 'Loading'

export default Loading
