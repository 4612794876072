import React from 'react'
import { useParams } from 'react-router-dom'

import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import { getAvailableFolders, getAvailableGroups } from 'utils/functions'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import IconButton from 'components_new/atoms/IconButton'

import OverflowText from 'components_new/molecules/OverflowText'

interface MobileFinderLocationProps {
  sx?: object
}

const MobileFinderLocation = (props: ComponentProps) => {
  const { AuthStore, DashboardFolderStore, KPIDashboardStore, sx } = props

  const params = useParams<{ folderId?: string }>()
  const selectedFolderId = params.folderId

  const availableGroups = getAvailableGroups(KPIDashboardStore.groups)
  const folders = getAvailableFolders(
    availableGroups,
    DashboardFolderStore.data,
    AuthStore.user
  )

  const selectedFolder = folders.find((item) => item.id === selectedFolderId)

  const isLoading = !DashboardFolderStore.fetched || !KPIDashboardStore.fetched

  if (isLoading) return <></>

  return (
    <Box
      sx={{ alignItems: 'center', display: 'flex', gap: 1, minWidth: 0, ...sx }}
    >
      <IconButton to={'/dashboards'}>
        <Icon name="ArrowBack" />
      </IconButton>
      <OverflowText color="text.primary" variant="subtitle1">
        {selectedFolder?.name}
      </OverflowText>
    </Box>
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AuthStore: state.AuthStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const connector = connect(mapStateToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  MobileFinderLocationProps

export default connector(MobileFinderLocation)
