import {
  create,
  DashboardPatchBody,
  DashboardPostBody,
  destroy,
  getAll,
  getOne,
  getOnePublic,
  move,
  putThumbnail,
  update,
  updateLayout,
  inviteToDashboard,
  revokeFromDashboard,
  WidgetLayoutPatchBody,
  Permission,
  getOneApiAccess
} from 'redux/api/Dashboards'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'

// eslint-disable-next-line no-unused-vars
type NavigateCallbackFunction = (id: string) => void

export function tryUpdateDashboardLayout(
  id: string,
  body: WidgetLayoutPatchBody
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_WIDGET_LAYOUT
    })

    updateLayout(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_WIDGET_LAYOUT_SUCCESS,
          payload: {
            id,
            layout: body.data.layout,
            available_space: response.data.data
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_WIDGET_LAYOUT_FAILED
        })
      })
  }
}

export function tryGetAllDashboards() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_DASHBOARDS
    })

    getAll()
      .then((response) => {
        dispatch({
          type: Types.GET_DASHBOARDS_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_DASHBOARDS_FAILED
        })
      })
  }
}

export function tryGetOneDashboard(id: string, fallback: () => void) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ONE_DASHBOARD
    })

    getOne(id)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_DASHBOARD_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_DASHBOARD_FAILED,
          payload: id
        })

        fallback()
      })
  }
}

export function tryGetOnePublicDashboard(id: string, fallback: () => void) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ONE_PUBLIC_DASHBOARD
    })

    getOnePublic(id)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_PUBLIC_DASHBOARD_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_PUBLIC_DASHBOARD_FAILED,
          payload: id
        })

        fallback()
      })
  }
}

export function tryGetOneApiAccessDashboard(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.GET_ONE_PUBLIC_DASHBOARD
    })

    getOneApiAccess(id)
      .then((response) => {
        dispatch({
          type: Types.GET_ONE_PUBLIC_DASHBOARD_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.GET_ONE_PUBLIC_DASHBOARD_FAILED,
          payload: id
        })
      })
  }
}

export function tryCreateDashboard(
  body: DashboardPostBody,
  navigate: NavigateCallbackFunction
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.CREATE_DASHBOARD
    })

    create(body)
      .then((response) => {
        dispatch({
          type: Types.CREATE_DASHBOARD_SUCCESS,
          payload: response.data
        })

        if (body.data.dashboard_id) {
          // custom snackbar when copying dashboard.
          dispatch({
            type: Types.TOGGLE_SNACKBAR_OPEN,
            payload: {
              message: 'Dashboard duplicerad'
            }
          })
        } else {
          dispatch({
            type: Types.TOGGLE_SNACKBAR_OPEN,
            payload: { message: 'Dashboard skapad' }
          })
        }

        navigate(response.data.data.dashboard.id)
      })
      .catch(() => {
        dispatch({
          type: Types.CREATE_DASHBOARD_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Dashboard kunde inte skapas' }
        })
      })
  }
}

export function tryDeleteDashboard(id: string, navigate: () => void) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.DELETE_DASHBOARD
    })

    destroy(id)
      .then(() => {
        navigate()

        dispatch({
          type: Types.DELETE_DASHBOARD_SUCCESS,
          payload: { id }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Dashboard har tagits bort' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.DELETE_DASHBOARD_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Dashboard kunde inte tas bort' }
        })
      })
  }
}

export function tryUpdateDashboard(id: string, body: DashboardPatchBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.UPDATE_DASHBOARD
    })

    update(id, body)
      .then((response) => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Dashboard har uppdaterats' }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.UPDATE_DASHBOARD_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Dashboard kunde inte uppdateras' }
        })
      })
  }
}

export function tryMoveDashboard(
  id: string,
  newDashboardGroupId: string,
  previousDashboardGroupId: string,
  cb: (err: boolean) => void
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MOVE_DASHBOARD
    })

    move(id, {
      data: { dashboard_group_id: newDashboardGroupId }
    })
      .then((response) => {
        dispatch({
          type: Types.MOVE_DASHBOARD_SUCCESS,
          payload: {
            data: response.data.data,
            previousDashboardGroupId
          }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Dashboard flyttad'
          }
        })

        cb(false)
      })
      .catch(() => {
        dispatch({
          type: Types.MOVE_DASHBOARD_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: { message: 'Dashboard kunde inte flyttas' }
        })

        cb(true)
      })
  }
}

export function tryInviteToDashboard(id: string, data: Permission) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.INVITE_TO_DASHBOARD
    })

    inviteToDashboard(id, { data })
      .then((response) => {
        dispatch({
          type: Types.INVITE_TO_DASHBOARD_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Inbjudan skickad'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.INVITE_TO_DASHBOARD_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Inbjudan kunde inte skickas'
          }
        })
      })
  }
}

export function tryRevokeFromDashboard(
  id: string,
  accountId?: string,
  companyGroupId?: string
) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.REVOKE_FROM_DASHBOARD
    })

    revokeFromDashboard(id, accountId, companyGroupId)
      .then((response) => {
        dispatch({
          type: Types.REVOKE_FROM_DASHBOARD_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Användare borttagen från dashboard'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.REVOKE_FROM_DASHBOARD_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Användare kunde inte tas bort från dashboard'
          }
        })
      })
  }
}

export function tryUploadDashboardThumbnail(id: string, thumbnail: Blob) {
  return () => {
    putThumbnail(id, thumbnail).catch(() => {
      // do nothing
    })
  }
}
