import React from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import InputAdornment from 'components_new/atoms/InputAdornment'
import Radio from 'components_new/atoms/Radio'
import TextField from 'components_new/atoms/TextField'

import SettingsGroup from 'components_new/molecules/SettingsGroup'
import SettingsItem from 'components_new/molecules/SettingsItem'

import { TEmbedType } from './'

export type TDimensions = { width: string; height: string }

interface DimensionsProps {
  dimensions: TDimensions
  editable: boolean
  embedType: TEmbedType
  setDimensions: (value: TDimensions) => void
}

const Dimensions = (props: DimensionsProps) => {
  const { dimensions, editable, embedType, setDimensions } = props

  const responsive = dimensions.width === '100%'

  const handleToggleDimensionMode = () => {
    if (responsive) {
      setDimensions({ width: '640', height: '360' })
    } else {
      setDimensions({ width: '100%', height: '100%' })
    }
  }

  const handleSetHeight = (value: string) => {
    const height = parseInt(value)
    const width = Math.floor((height * 16) / 9)

    setDimensions({ width: width.toString(), height: height.toString() })
  }

  const handleSetWidth = (value: string) => {
    const width = parseInt(value)
    const height = Math.floor((width * 9) / 16)

    setDimensions({ width: width.toString(), height: height.toString() })
  }

  return (
    <>
      <SettingsGroup title="Storlek">
        <SettingsItem
          body={
            <>
              Det enklaste alternativet.{' '}
              {embedType.charAt(0).toUpperCase() + embedType.slice(1)}en kommer
              att ta upp den bredd och höjd som finns tillgänglig.
            </>
          }
          disabled={!editable}
          icon={
            <Radio
              checked={responsive}
              onChange={handleToggleDimensionMode}
              size="small"
              sx={{ height: 24, width: 24 }}
            />
          }
          title={'Automatisk'}
        />

        <SettingsItem
          actions={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mt: 1
              }}
            >
              <TextField
                disabled={responsive}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">px</InputAdornment>
                  )
                }}
                onChange={(event) => handleSetWidth(event.target.value)}
                size="small"
                type="number"
                value={!responsive ? dimensions.width : '640'}
                sx={{ width: 104, my: 0 }}
              />
              <Icon
                color={responsive ? 'disabled' : undefined}
                fontSize="small"
                name="ClearOutlined"
              />
              <TextField
                disabled={responsive}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">px</InputAdornment>
                  )
                }}
                onChange={(event) => handleSetHeight(event.target.value)}
                size="small"
                type="number"
                value={!responsive ? dimensions.height : '360'}
                sx={{ width: 104, my: 0 }}
              />
            </Box>
          }
          body={
            <>
              Försäkra dig om att {embedType}en alltid är en viss storlek genom
              att ställa in bredd och höjd.
            </>
          }
          disabled={!editable}
          icon={
            <Radio
              checked={!responsive}
              onChange={handleToggleDimensionMode}
              size="small"
              sx={{ height: 24, width: 24 }}
            />
          }
          title={'Bestämd storlek'}
        />
      </SettingsGroup>
    </>
  )
}

export default Dimensions
