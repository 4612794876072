import React, { ReactNode } from 'react'

import Box from 'components_new/atoms/Box'
import Icon from 'components_new/atoms/Icon'
import SwipeableDrawer from 'components_new/atoms/SwipeableDrawer'
import Text from 'components_new/atoms/Text'
import IconButton from 'components_new/atoms/IconButton'

interface SimpleSwipeableDrawerProps {
  actions?: ReactNode
  children?: ReactNode
  contentText?: ReactNode
  height?: number | string
  onBack?: () => void
  onClose: () => void
  onOpen: () => void
  open: boolean
  sx?: object
  title?: ReactNode
}

const SimpleSwipeableDrawer = React.forwardRef(
  (props: SimpleSwipeableDrawerProps, ref: any) => {
    const { actions, children, contentText, height, onBack, title, ...rest } =
      props

    return (
      <SwipeableDrawer
        {...rest}
        anchor="bottom"
        PaperProps={{
          sx: {
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            overflowX: 'hidden',
            height: height ?? '75vh'
          }
        }}
        ref={ref}
      >
        <Box
          sx={{
            borderBottom: '1px solid',
            borderColor: 'divider',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Icon color="disabled" name="DragHandleOutlined" />
          <Box sx={{ position: 'relative', width: '100%', pb: 1, pt: 0 }}>
            {onBack ? (
              <Box sx={{ position: 'absolute', top: -6, left: 8 }}>
                <IconButton onClick={onBack}>
                  <Icon name="ArrowBackIos" />
                </IconButton>
              </Box>
            ) : null}
            <Text align="center" noWrap={true} variant="subtitle1">
              {title}
            </Text>
          </Box>
        </Box>
        <Box sx={{ overflowY: 'auto' }}>
          {contentText ? (
            <Box sx={{ p: 2, pb: 0 }}>
              <Text color="text.secondary" component="div">
                {contentText}
              </Text>
            </Box>
          ) : null}
          <Box sx={{ p: 2 }}>{children}</Box>
          {actions ? (
            <Box sx={{ borderTop: '1px solid', borderColor: 'divider', p: 2 }}>
              {actions}
            </Box>
          ) : null}
        </Box>
      </SwipeableDrawer>
    )
  }
)

SimpleSwipeableDrawer.displayName = 'SimpleSwipeableDrawer'
export default SimpleSwipeableDrawer
