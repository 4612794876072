import React, { ElementType, ReactNode } from 'react'

import { Dispatch, bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'
import { ApplicationState } from 'redux/Stores/types'

import * as NavigationMenuStoreActions from 'redux/actions/NavigationMenu'

import { useMediaQuery, useTheme } from '@mui/material'

import { IconNames } from 'components_new/atoms/Icon'

import Mobile from './mobile'
import Desktop from './desktop'

export interface PageContainerProps {
  bgcolor?: string | null
  /*-- Bottombar --*/
  bottombar?: ReactNode
  /*-- ... --*/
  children?: ReactNode
  component?: ElementType<any>
  embedded?: boolean
  /*-- Left Sidebar --*/
  leftSidebar?: ReactNode
  LeftSidebarProps?: {
    size?: 'small' | 'medium' | 'large'
  }
  /*-- Navbar --*/
  NavbarProps?: {
    content?: ReactNode
    footer?: ReactNode
    header?: ReactNode
    locked?: boolean
    menu?: ReactNode
  }
  noGutter?: boolean
  /*-- Right Sidebar --*/
  rightSidebar?: ReactNode
  RightSidebarProps?: {
    badge?: boolean
    defaultClosed?: boolean
    iconName?: IconNames
    mobilePreview?: ReactNode
    tooltip?: string
  }
  /*-- Toolbar --*/
  toolbar?: ReactNode
  ToolbarProps?: {
    open: boolean
  }
  /*-- Topbar --*/
  topbar?: ReactNode
  TopbarProps?: {
    actions?: ReactNode
    displayProfile?: boolean
    noGutter?: boolean
  }
  unauthorized?: boolean
}

/**
 * The PageContainer is used as a wrapper for our pages,
 * i.e. the outmost element in a template.
 *
 * It is used for (in a simple way) keeping a consistent
 * layout.
 */

const PageContainer = React.forwardRef((props: ComponentProps) => {
  const { NavigationMenuStore, NavbarProps, ...rest } = props

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  return isMobile ? (
    <Mobile NavbarProps={NavbarProps} {...rest} />
  ) : (
    <Desktop
      NavbarProps={{
        ...NavbarProps,
        open: NavigationMenuStore?.open
      }}
      {...rest}
    />
  )
})

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  NavigationMenuStore: state.NavigationMenuStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...NavigationMenuStoreActions
    },
    dispatch
  )
}

interface ComponentDispatchProps {
  toggleNavigationOpen(): (dispatch: Dispatch) => void
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> &
  ComponentDispatchProps &
  PageContainerProps

export default connector(PageContainer)
