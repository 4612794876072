import React, { useState } from 'react'

import { useMediaQuery, useTheme } from '@mui/material'

import Button from 'components_new/atoms/Button'
import Icon from 'components_new/atoms/Icon'
import Portal from 'components_new/atoms/Portal'
import Snackbar from 'components_new/atoms/Snackbar'

import SplitButton from 'components_new/molecules/SplitButton'

interface ActionsProps {
  showActions: boolean
  isAdmin: boolean
  isPublic: boolean
  isStockholmshem: boolean
  onClose: () => void
  publicLink: string
  setTab: (value: 'embed' | 'email' | 'default') => void
  tab: 'embed' | 'email' | 'default'
}

const Actions = (props: ActionsProps) => {
  const {
    showActions,
    isAdmin,
    isPublic,
    isStockholmshem,
    publicLink,
    setTab,
    tab
  } = props

  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  const copyCurrentUrl = () => {
    const currentUrl = window.location.href

    navigator.clipboard
      .writeText(currentUrl)
      .then(() => setShowCopiedMessage(true))
      .catch((err) => console.error('Failed to copy current URL:', err))
  }

  const copyPublicLink = () => {
    navigator.clipboard
      .writeText(publicLink)
      .then(() => setShowCopiedMessage(true))
      .catch((err) => console.error('Failed to copy public link:', err))
  }

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })

  return (
    <>
      {tab === 'default' ? (
        <>
          {showActions ? (
            <>
              <SplitButton
                onClick={copyCurrentUrl}
                options={[
                  {
                    disabled: !isPublic,
                    iconName: 'PublicOutlined',
                    title: 'Kopiera publik länk',
                    onClick: copyPublicLink
                  }
                ]}
                startIcon={<Icon name="Link" />}
                title={'Kopiera länk'}
              />
              {isStockholmshem ? null : (
                <Button
                  startIcon={<Icon name="MailOutlined" />}
                  variant="outlined"
                  onClick={() => setTab('email')}
                >
                  Bjud in
                </Button>
              )}
              {isAdmin && !isMobile ? (
                <Button
                  startIcon={<Icon name="CodeOutlined" />}
                  variant="outlined"
                  onClick={() => setTab('embed')}
                >
                  Bädda in
                </Button>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}

      {/* ----- Feedback ----- */}
      <Portal>
        <Snackbar
          open={showCopiedMessage}
          autoHideDuration={3000}
          onClose={(event, reason) => {
            if (reason !== 'clickaway') {
              setShowCopiedMessage(false)
            }
          }}
          message="Kopierat till urklipp"
        />
      </Portal>
    </>
  )
}

export default Actions
