import React, { ReactNode } from 'react'
import { BottomNavigationAction as MUIBottomNavigationAction } from '@mui/material'

interface BottomNavigationActionProps {
  icon: ReactNode
  label: string
  value?: any
}

const BottomNavigationAction = React.forwardRef(
  (props: BottomNavigationActionProps, ref: any) => {
    return <MUIBottomNavigationAction {...props} ref={ref} />
  }
)

BottomNavigationAction.displayName = 'BottomNavigationAction'
export default BottomNavigationAction
