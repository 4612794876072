import {
  createStatusBanner,
  deleteStatusBanner,
  getStatusBanner,
  updateStatusBanner
} from 'redux/api/internal/StatusBanner'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'
import { StatusBannerBody } from 'utils/types'

export function tryGetStatusBanner() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.INTERNAL_GET_STATUS_BANNER
    })

    getStatusBanner()
      .then((response) => {
        dispatch({
          type: Types.INTERNAL_GET_STATUS_BANNER_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.INTERNAL_GET_STATUS_BANNER_FAILED
        })
      })
  }
}

export function tryCreateStatusBanner(body: StatusBannerBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.INTERNAL_CREATE_STATUS_BANNER
    })

    createStatusBanner(body)
      .then((response) => {
        dispatch({
          type: Types.INTERNAL_CREATE_STATUS_BANNER_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Banner skapad'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.INTERNAL_CREATE_STATUS_BANNER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Misslyckade att skapa banner'
          }
        })
      })
  }
}

export function tryUpdateStatusBanner(id: string, body: StatusBannerBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.INTERNAL_UPDATE_STATUS_BANNER
    })

    updateStatusBanner(id, body)
      .then((response) => {
        dispatch({
          type: Types.INTERNAL_UPDATE_STATUS_BANNER_SUCCESS,
          payload: response.data
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Banner uppdaterad'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.INTERNAL_UPDATE_STATUS_BANNER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Misslyckade att uppdatera banner'
          }
        })
      })
  }
}

export function tryDeleteStatusBanner(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.INTERNAL_DELETE_STATUS_BANNER
    })

    deleteStatusBanner(id)
      .then(() => {
        dispatch({
          type: Types.INTERNAL_DELETE_STATUS_BANNER_SUCCESS,
          payload: { id }
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Banner borttagen'
          }
        })
      })
      .catch(() => {
        dispatch({
          type: Types.INTERNAL_DELETE_STATUS_BANNER_FAILED
        })

        dispatch({
          type: Types.TOGGLE_SNACKBAR_OPEN,
          payload: {
            message: 'Misslyckade att ta bort banner'
          }
        })
      })
  }
}
