export enum AccountRole {
  ADMIN = 'ADMIN',
  VIEW = 'VIEW',
  ACCESS_HUB = 'ACCESS_HUB'
}

export const translateAccountRole: Record<
  AccountRole,
  { display: string; value: AccountRole }
> = {
  [AccountRole.ADMIN]: {
    display: 'Administratör',
    value: AccountRole.ADMIN
  },
  [AccountRole.VIEW]: {
    display: 'Användare',
    value: AccountRole.VIEW
  },
  [AccountRole.ACCESS_HUB]: {
    display: 'Access hub',
    value: AccountRole.ACCESS_HUB
  }
}

export const TRANSLATE_ROLES: { [key in AccountRole]: string } = {
  ADMIN: 'Administratör',
  VIEW: 'Användare',
  ACCESS_HUB: 'Access hub'
}

export type PatchSelfBody = {
  data: {
    first_name: string
    last_name: string
  }
}

export type PostUserBody = {
  data: {
    email: string
    role: string
    first_name: string
    last_name: string
    message: string
    company_group_id?: string
  }
}

export type PatchUserBody = {
  data: {
    first_name?: string
    last_name?: string
    role?: string
    title?: string
    ignore_from_statistics?: boolean
  }
}

export type User = {
  email: string
  first_name: string
  id: string
  is_homepal_user: boolean
  is_company_group: boolean
  last_name: string
  role: AccountRole
  phone: string | null
  has_finished_onboarding: boolean
  additional_information: {
    role: string
    preference: string
  } | null
}
