import { getUsersUsage, getOneUserUsage } from 'redux/api/Tracking'
import { update as updateUser } from 'redux/api/Accounts'
import * as Types from 'redux/Types'

import { Dispatch } from 'redux'

import { PatchUserBody } from 'types/GlobalUser'

export function tryGetUsersUsage() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_GET_USERS_USAGE
    })

    getUsersUsage()
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_GET_USERS_USAGE_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_GET_USERS_USAGE_FAILED
        })
      })
  }
}

export function tryGetOneUserUsage(id: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_GET_ONE_USER_USAGE
    })

    getOneUserUsage(id)
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_GET_ONE_USER_USAGE_SUCCESS,
          payload: response.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_GET_ONE_USER_USAGE_FAILED
        })
      })
  }
}

export function tryUpdateUser(id: string, body: PatchUserBody) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: Types.MEASUREMENTS_UPDATE_USER_USAGE
    })

    updateUser(id, body)
      .then((response) => {
        dispatch({
          type: Types.MEASUREMENTS_UPDATE_USER_USAGE_SUCCESS,
          payload: response.data.data
        })
      })
      .catch(() => {
        dispatch({
          type: Types.MEASUREMENTS_UPDATE_USER_USAGE_FAILED
        })
      })
  }
}
