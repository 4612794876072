import React, { ReactNode } from 'react'

import { STANDARD_WIDGET_SCALE_FACTOR } from 'components_new/organisms/Widget'

import Box from 'components_new/atoms/Box'

interface MobileProps {
  children: (props: { scaleFactor: number }) => ReactNode
  colors?: any
}

const Mobile = (props: MobileProps) => {
  const { children, colors } = props

  return (
    <>
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          bgcolor: colors?.background_color ?? 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          p: 1
        }}
      >
        {children({ scaleFactor: STANDARD_WIDGET_SCALE_FACTOR })}
      </Box>
    </>
  )
}

Mobile.displayName = 'Mobile'

export default Mobile
