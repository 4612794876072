import { createAction, createReducer } from '@reduxjs/toolkit'
import Tracker from '@openreplay/tracker'
import Hotjar from '@hotjar/browser'

import { AuthenticationState } from 'types/GlobalAuthentication'

import * as Types from 'redux/Types'
import { DashboardAPI, DashboardGroup, DashboardStatus } from '../Dashboards'

const HOTJAR_VERSION = 6
const HOTJAR_SIDE_ID = 3416864

// Initial state
const INITIAL_STATE: AuthenticationState = {
  isAuthenticated: false,
  customer: null,
  user: null,
  isDemo: false,
  validating: false,
  azureAd: {
    fetched: false,
    metadataUrl: null,
    domains: []
  },
  updatingUser: false
}

// Actions
const validateUser = createAction<{ data: any[] }>(Types.VALIDATE_USER)
const signedInSuccess = createAction<{ data: any[] }>(Types.SET_SIGNED_IN)
const updateSelf = createAction<{ data: any[] }>(Types.UPDATE_SELF)
const updateSelfSuccess = createAction<{ data: any[] }>(
  Types.UPDATE_SELF_SUCCESS
)
const updateSelfFailed = createAction(Types.UPDATE_SELF_FAILED)
const getAzureAdSuccess = createAction<{ data: any[] }>(
  Types.GET_AZURE_AD_SUCCESS
)
const putAzureAdSuccess = createAction<{ data: any[] }>(
  Types.PUT_AZURE_AD_SUCCESS
)
const deleteAzureAdSuccess = createAction<{ data: any[] }>(
  Types.DELETE_AZURE_AD_SUCCESS
)
const signedOutSuccess = createAction(Types.SIGN_OUT)

const deleteDashboardGroupSuccess = createAction(
  Types.DELETE_DASHBOARD_GROUP_SUCCESS
)
const createDashboardSuccess = createAction<{
  data: { dashboard: DashboardAPI; group: DashboardGroup }
}>(Types.CREATE_DASHBOARD_SUCCESS)

// Reducer
const AuthenticationReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(validateUser, (state: AuthenticationState) => {
      return {
        ...state,
        validating: true
      }
    })
    .addCase(signedInSuccess, (state: AuthenticationState, action: any) => {
      const { payload } = action

      if (
        !payload.user.is_homepal_user &&
        process.env.NODE_ENV === 'production'
      ) {
        if (payload.user.opened_dashboards > 10) {
          try {
            Hotjar.init(HOTJAR_SIDE_ID, HOTJAR_VERSION)
          } catch (e) {
            console.log('Error hotjar', e)
          }
        }

        // Safety checks for tracker.
        try {
          const tracker = new Tracker({
            projectKey: 'kBLqF4m0p0GhezlJI8nk',
            obscureTextNumbers: true,
            defaultInputMode: 1,
            network: {
              sessionTokenHeader: false,
              failuresOnly: true,
              ignoreHeaders: true,
              capturePayload: false,
              captureInIframes: false
            }
          })

          tracker.start()

          tracker.setUserID(payload.user.id)
        } catch (e) {
          console.log('Tracker error', e)
        }
      }

      return {
        ...state,
        customer: payload.customer,
        user: payload.user,
        isAuthenticated: true,
        isDemo: payload.is_demo,
        validating: false
      }
    })
    .addCase(updateSelf, (state: AuthenticationState) => {
      return {
        ...state,
        updatingUser: true
      }
    })
    .addCase(updateSelfSuccess, (state: AuthenticationState, action: any) => {
      const { payload } = action

      return {
        ...state,
        user: { ...state.user, ...payload.attributes },
        updatingUser: false
      }
    })
    .addCase(updateSelfFailed, (state: AuthenticationState) => {
      return {
        ...state,
        updatingUser: false
      }
    })
    .addCase(getAzureAdSuccess, (state: AuthenticationState, action: any) => {
      const { payload } = action

      return {
        ...state,
        azureAd: {
          fetched: true,
          metadataUrl: payload.attributes.metadata_url,
          domains: payload.attributes.domains
        }
      }
    })
    .addCase(putAzureAdSuccess, (state: AuthenticationState, action: any) => {
      const { payload } = action

      return {
        ...state,
        azureAd: {
          fetched: true,
          metadataUrl: payload.attributes.metadata_url,
          domains: payload.attributes.domains
        }
      }
    })
    .addCase(deleteAzureAdSuccess, (state: AuthenticationState) => {
      return {
        ...state,
        azureAd: INITIAL_STATE.azureAd
      }
    })
    .addCase(signedOutSuccess, () => {
      return INITIAL_STATE
    })
    .addCase(deleteDashboardGroupSuccess, (state) => {
      if (!state.customer) {
        return state
      }

      return {
        ...state,
        customer: {
          ...state.customer,
          number_of_dashboards_in_use:
            state.customer.number_of_dashboards_in_use - 1
        }
      }
    })
    .addCase(createDashboardSuccess, (state, action) => {
      const { payload } = action

      if (
        !state.customer ||
        payload.data.dashboard.index !== 0 ||
        payload.data.dashboard.status !== DashboardStatus.PUBLISHED
      ) {
        return state
      }

      return {
        ...state,
        customer: {
          ...state.customer,
          number_of_dashboards_in_use:
            state.customer.number_of_dashboards_in_use + 1
        }
      }
    })
    .addDefaultCase((state: AuthenticationState) => state)
})

export default AuthenticationReducer
