import React, { ElementType, MouseEventHandler, ReactNode } from 'react'
import { IconButton as MUIIconButton } from '@mui/material'
import { Color } from 'themes'

import LinkWrapper from '../LinkWrapper'

interface IconButtonProps {
  children?: ReactNode
  color?:
    | Color
    | 'default'
    | 'disabled'
    | 'inherit'
    | 'neutral'
    | 'info'
    | 'brand'
  component?: ElementType<any>
  disabled?: boolean
  disableHistory?: boolean
  disableRipple?: boolean
  edge?: 'end' | 'start' | false
  goBack?: boolean
  href?: string
  newTab?: boolean
  onClick?: (() => void) | MouseEventHandler<HTMLElement>
  onMouseDown?: (() => void) | MouseEventHandler<HTMLElement>
  size?: 'small' | 'medium' | 'large'
  sx?: object
  to?: string
  variant?: 'contained' | 'outlined' | 'transparent'
}

/**
 * The IconButton component allow users to take
 * actions, and make choices, with a single tap
 * – without the need of text.
 *
 * Is recommended to combine with a Tooltip.
 *
 * Icon buttons are commonly found in app bars
 * and toolbars. Icons are also appropriate for
 * toggle buttons that allow a single choice to
 * be selected or deselected, such as adding or
 * removing a star to an item.
 */

const IconButton = React.forwardRef((props: IconButtonProps, ref: any) => {
  const {
    color,
    disabled,
    disableHistory,
    goBack,
    href,
    newTab,
    sx,
    to,
    variant,
    ...rest
  } = props

  return (
    <LinkWrapper
      disabled={disabled}
      disableHistory={disableHistory}
      goBack={goBack}
      href={to ?? href}
      newTab={newTab}
    >
      <MUIIconButton
        disabled={disabled}
        {...rest}
        ref={ref}
        color={color}
        sx={{
          bgcolor: variant === 'contained' ? `${color}.main` : undefined,
          color: variant === 'contained' ? `${color}.contrastText` : undefined,
          '&:hover': {
            bgcolor: variant === 'contained' ? `${color}.dark` : undefined
          },
          ...sx
        }}
      >
        {props.children}
      </MUIIconButton>
    </LinkWrapper>
  )
})

IconButton.displayName = 'IconButton'
export default IconButton
