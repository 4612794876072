import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Dispatch, bindActionCreators } from 'redux'
import { ApplicationState } from 'redux/Stores/types'
import { ConnectedProps, connect } from 'react-redux'

import * as AccountStoreActions from 'redux/actions/Accounts'
import * as AuthStoreActions from 'redux/actions/Authentication'
import * as DashboardFavoriteActions from 'redux/actions/DashboardFavorites'
import * as DashboardFolderStoreActions from 'redux/actions/DashboardFolders'
import * as KPIDashboardStoreActions from 'redux/actions/Dashboards'

import { AccountRole } from 'types/GlobalUser'
import { DashboardGroup } from 'redux/reducers/Dashboards'

import { useMediaQuery, useTheme } from '@mui/material'

import {
  SortByMethod,
  getPermissions,
  getSelectedGroups,
  groupDashboards
} from './utils'

import {
  getAvailableFolders,
  getAvailableGroups,
  getThumbnail
} from 'utils/functions'

import { sortAlphabeticalAsc, sortDateDesc } from 'utils/sortHelper'

import Desktop from './desktop'
import Mobile from './mobile'

interface FinderProps {
  sx?: object
}

const Finder = (props: ComponentProps) => {
  const {
    AccountStore,
    AuthStore,
    DashboardFolderStore,
    KPIDashboardStore,
    sx,
    tryPutDashboardFavorite
  } = props

  const theme = useTheme()

  const history = useHistory()

  // View all available dashboards
  const resetAll = () => {
    history.push('/dashboards')
  }

  const params = useParams<{ folderId?: string }>()
  const selectedFolderId = params.folderId

  const availableGroups = getAvailableGroups(KPIDashboardStore.groups)
  const folders = getAvailableFolders(
    availableGroups,
    DashboardFolderStore.data,
    AuthStore.user
  )

  const selectedFolder = folders.find((item) => item.id === selectedFolderId)
  const selectedFavorites = location.pathname === '/dashboards/favorites'

  const userCanEditFolder = Boolean(
    AuthStore.user?.role === AccountRole.ADMIN ||
      AuthStore.user?.is_homepal_user
  )

  const userIsCustomer = Boolean(AuthStore?.user?.is_company_group)

  const groupedDashboards = groupDashboards(availableGroups, folders)

  const sortByMethod = function (
    a: DashboardGroup,
    b: DashboardGroup,
    method: SortByMethod
  ) {
    switch (method) {
    case SortByMethod.ALPHABETICAL:
      return sortAlphabeticalAsc(a.title, b.title)
    case SortByMethod.LAST_VIEWED:
      return sortDateDesc(
        a.latest_visit ? new Date(a.latest_visit) : null,
        b.latest_visit ? new Date(b.latest_visit) : null
      )
    case SortByMethod.UPDATED_AT:
      return sortDateDesc(new Date(a.updated_at), new Date(b.updated_at))
    default:
      return 0
    }
  }

  // LocalStorage variables
  const [sortBy, setSortByMethod] = useState<SortByMethod>(
    SortByMethod.LAST_VIEWED
  )

  useEffect(() => {
    // List dashboards how
    const listDashboards = localStorage.getItem('listDashboards')

    if (listDashboards) {
      const listDashboardsObject = JSON.parse(listDashboards)

      setSortByMethod(listDashboardsObject.sortBy)
    } else {
      localStorage.setItem(
        'listDashboards',
        JSON.stringify({ sortBy: SortByMethod.LAST_VIEWED as string })
      )
    }
  }, [])

  const updateSortByMethod = (value: SortByMethod) => {
    localStorage.setItem(
      'listDashboards',
      JSON.stringify({ sortBy: value as string })
    )
    setSortByMethod(value)
  }

  // Filtered and sorted dashboards
  const handledDashboards = getSelectedGroups(
    groupedDashboards,
    selectedFavorites,
    selectedFolderId
  ).sort((a, b) => sortByMethod(a, b, sortBy))

  const isHomepal = !!AuthStore.user?.is_homepal_user

  // map users to dashboards
  const dashboardUsers = useMemo(() => {
    if (KPIDashboardStore.fetched && AccountStore.fetched) {
      const dashboardUsers: { [key: string]: string[] } = {}

      Object.values(KPIDashboardStore.data).forEach((dashboard) => {
        const group = KPIDashboardStore.groups[dashboard?.dashboard_group_id]

        const userNames = getPermissions(
          AccountStore,
          KPIDashboardStore.data,
          dashboard.id,
          AuthStore.user,
          group?.owner
        ).map((item) => item.name)

        if (dashboardUsers[dashboard.dashboard_group_id]) {
          const newPermissions = userNames.filter(
            (perm) =>
              !dashboardUsers[dashboard.dashboard_group_id].includes(perm)
          )

          if (newPermissions.length > 0) {
            dashboardUsers[dashboard.dashboard_group_id].push(...newPermissions)
          }
        } else {
          dashboardUsers[dashboard.dashboard_group_id] = userNames
        }
      })

      return dashboardUsers
    }

    return {}
  }, [KPIDashboardStore.data, AccountStore.data])

  const thumbnailMemo = useMemo(() => {
    const thumbnails: { [id: string]: string } = {}

    availableGroups.forEach((item) => {
      thumbnails[item.id] = getThumbnail(item.thumbnail)
    })

    return thumbnails
  }, [KPIDashboardStore.data])

  const toggleFavorite = (id: string) => {
    tryPutDashboardFavorite(id)
  }

  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'), {
    noSsr: true
  })
  const isLoading =
    !AccountStore.fetched ||
    !DashboardFolderStore.fetched ||
    !KPIDashboardStore.fetched

  return isMobile ? (
    <Mobile
      dashboards={handledDashboards}
      folders={folders}
      isLoading={isLoading}
      selectedFavorites={selectedFavorites}
      selectedFolder={selectedFolder}
    />
  ) : (
    <Desktop
      AuthStore={AuthStore}
      dashboards={handledDashboards}
      dashboardUsers={dashboardUsers}
      folders={folders}
      isHomepal={isHomepal}
      isLoading={isLoading}
      resetAll={resetAll}
      selectedFavorites={selectedFavorites}
      selectedFolder={selectedFolder}
      sortBy={sortBy}
      sx={sx}
      thumbnails={thumbnailMemo}
      toggleFavorite={toggleFavorite}
      updateSortByMethod={updateSortByMethod}
      userCanEditFolder={userCanEditFolder}
      userIsCustomer={userIsCustomer}
    />
  )
}

/*-- redux --*/
const mapStateToProps = (state: ApplicationState) => ({
  AccountStore: state.AccountStore,
  AuthStore: state.AuthStore,
  DashboardFolderStore: state.DashboardFolderStore,
  KPIDashboardStore: state.KPIDashboardStore
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      ...AccountStoreActions,
      ...AuthStoreActions,
      ...DashboardFavoriteActions,
      ...DashboardFolderStoreActions,
      ...KPIDashboardStoreActions
    },
    dispatch
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type ComponentProps = ConnectedProps<typeof connector> & FinderProps

export default connector(Finder)
