import { createAction, createReducer } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'

import * as Types from 'redux/Types'
import { arrayToObject } from 'utils/functions'
import { AccountType } from '../Accounts'
import { LayoutUpdate } from 'redux/api/Dashboards'

import { FunnelStage, WidgetBody, WidgetObject } from 'types/GlobalWidget'
import {
  KpiOptionPatchResponse,
  KpiOptionReduxResponse
} from 'types/GlobalKpiOption'
import {
  TagStatusItem,
  WidgetTagObject,
  WidgetTagReduxPayload,
  WidgetTagStatusItemReduxPayload
} from 'types/GlobalWidgetTag'
import { CustomColors } from 'types/GlobalCustomization'
import { AdditionalSegmentReduxPayload } from 'types/GlobalAdditionalSegment'

// Types
export enum PermissionRole {
  OWNER = 'OWNER',
  VIEWER = 'VIEWER'
}

export enum DashboardStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED'
}

export enum WhoCanView {
  ONLY_ADDED_USERS = 'ONLY_ADDED_USERS',
  ORGANIZATION = 'ORGANIZATION'
}

interface Permission {
  account_id: string | null
  company_group_id: string | null
  id: string
  role: PermissionRole
}

export interface ShareSettings {
  api_access: boolean
  all_company_groups_has_access: boolean
  is_public: boolean
  who_can_view: WhoCanView
  link: string
  public_link: string
  permissions: Permission[]
}

export enum DashboardOwnership {
  OWNED_BY_ME = 'OWNED_BY_ME',
  SHARED_WITH_ME = 'SHARED_WITH_ME',
  NO_ACCESS = 'NO_ACCESS'
}

export enum ShowDashboardOptions {
  ALL_DASHBOARDS = 'ALL_DASHBOARDS',
  CREATED_BY_ME = 'CREATED_BY_ME'
}

export enum Category {
  ENERGY = 'ENERGY',
  REAL_ESTATE_PORTFOLIO = 'REAL_ESTATE_PORTFOLIO',
  RENTAL = 'RENTAL',
  TECHNICAL_MANAGEMENT = 'TECHNICAL_MANAGEMENT',
  ECONOMY = 'ECONOMY'
}

interface CategorySettings {
  options: Category[]
  selected: Category | null
}

interface DashboardFilters {
  attribute_id: string
  index: number
  relation_key: string
}

export interface LayoutItem {
  x: number
  y: number
  w: number
  h: number
}

interface AbstractDashboard {
  id: string
  has_access: boolean
  available_space: LayoutItem | null
  title: string
  subtitle: string | null
  description: string
  dashboard_filters: DashboardFilters[]
  share_settings: ShareSettings
  status?: DashboardStatus
  category: CategorySettings
  thumbnail: string | null
  dashboard_group_id: string
  latest_visit: string | null
  updated_at: Date
  created_at: Date
  white_label_settings?: CustomColors // @TODO: Choose >one< interface for this.
  customer_id?: string // public dashboards
}
export interface DashboardAPI extends AbstractDashboard {
  index: number
  widgets: WidgetObject[]
}

export interface Dashboard extends AbstractDashboard {
  widgets: WidgetBody
}

export interface DashboardPermissionBody {
  id: string
  share_settings: ShareSettings
  new_account: AccountType | null
}

export type DashboardBody = { [key: string]: Dashboard }

export type DashboardGroup = {
  id: string
  title: string
  ownership: DashboardOwnership
  category: CategorySettings
  description: string
  updated_at: Date
  thumbnail: string | null
  favorite: {
    active: boolean
    index: number | null
  }
  folder_id: string | null
  dashboards: string[]
  status: DashboardStatus
  owner: string
  latest_visit: string | null
  cms_data: any | null
}

export interface DashboardReducerType {
  data: DashboardBody
  groups: {
    [groupId: string]: DashboardGroup
  }
  fetching: boolean
  fetched: boolean
  activeFetchOne: string | null
  errorFetchOne: string | null
  currentWidgetId: string | null
  // If we have multiple fetches we should only update if the latest
  // fetchId is being used
  fetchIds: { [widgetId: string]: string | null | undefined }
}

// Initial state
const INITIAL_STATE: DashboardReducerType = {
  data: {},
  groups: {},
  fetching: false,
  fetched: false,
  activeFetchOne: null,
  errorFetchOne: null,
  fetchIds: {},
  currentWidgetId: null
}

// Actions

//  Get all dashboards.
const getAllAction = createAction(Types.GET_DASHBOARDS)
const getAllSuccessAction = createAction<{
  data: {
    dashboards: DashboardAPI[]
    groups: { [groupId: string]: DashboardGroup }
  }
}>(Types.GET_DASHBOARDS_SUCCESS)
const getAllFailedAction = createAction(Types.GET_DASHBOARDS_FAILED)

const getOneDashboardAction = createAction<{
  data: { dashboard: DashboardAPI; group: DashboardGroup }
}>(Types.GET_ONE_DASHBOARD_SUCCESS)
const getOnePublicDashboardAction = createAction<{
  data: DashboardAPI
}>(Types.GET_ONE_PUBLIC_DASHBOARD_SUCCESS)
const getOnePublicDashboardFailedAction = createAction<string>(
  Types.GET_ONE_PUBLIC_DASHBOARD_FAILED
)
const getOneDashboardFailedAction = createAction<string>(
  Types.GET_ONE_DASHBOARD_FAILED
)
const createDashboardAction = createAction<{
  data: { dashboard: DashboardAPI; group: DashboardGroup }
}>(Types.CREATE_DASHBOARD_SUCCESS)
const deleteDashboardAction = createAction<{ id: string }>(
  Types.DELETE_DASHBOARD_SUCCESS
)
const updateDashboardAction = createAction<{
  data: { dashboard: DashboardAPI; group: DashboardGroup }
}>(Types.UPDATE_DASHBOARD_SUCCESS)
const moveDashboardAction = createAction<{
  data: { dashboard: DashboardAPI; group: DashboardGroup }
  previousDashboardGroupId: string
}>(Types.MOVE_DASHBOARD_SUCCESS)
const updateDashboardGroupAction = createAction<{
  data: {
    id: string
    title: string
    status: DashboardStatus
    folder_id: string | null
  }
}>(Types.UPDATE_DASHBOARD_GROUP_SUCCESS)

const deleteDashboardGroupAction = createAction<{
  id: string
}>(Types.DELETE_DASHBOARD_GROUP_SUCCESS)
const updateDashboardOrderAction = createAction<{
  id: string
  order: string[]
}>(Types.UPDATE_DASHBOARD_ORDER_SUCCESS)
const copyDashboardGroupAction = createAction<{
  data: {
    dashboards: DashboardAPI[]
    groups: { [groupId: string]: DashboardGroup }
  }
}>(Types.COPY_DASHBOARD_GROUP_SUCCESS)
const getOneWidgetAction = createAction<{
  id: string
  dashboardId: string
  fetchId: string
}>(Types.GET_ONE_WIDGET)
const getOneWidgetSuccessAction = createAction<{
  data: WidgetObject
  fetchId: string
}>(Types.GET_ONE_WIDGET_SUCCESS)
const createWidgetAction = createAction(Types.CREATE_WIDGET)
const createWidgetSuccessAction = createAction<{
  data: WidgetObject
  available_space: LayoutItem | null
  skipFetchData?: boolean
}>(Types.CREATE_WIDGET_SUCCESS)
const updateWidgetAction = createAction(Types.UPDATE_WIDGET)
const updateWidgetSuccessAction = createAction<{
  data: WidgetObject
  skipFetchData: boolean
}>(Types.UPDATE_WIDGET_SUCCESS)
const deleteWidgetAction = createAction<{
  id: string
  dashboardId: string
  available_space: LayoutItem | null
}>(Types.DELETE_WIDGET_SUCCESS)
const updateWidgetLayoutAction = createAction<{
  id: string
  layout: LayoutUpdate[]
  available_space: LayoutItem | null
}>(Types.UPDATE_WIDGET_LAYOUT_SUCCESS)
const inviteToDashboardAction = createAction<{
  data: DashboardPermissionBody
}>(Types.INVITE_TO_DASHBOARD_SUCCESS)
const revokeFromDashboardAction = createAction<{
  data: DashboardPermissionBody
}>(Types.REVOKE_FROM_DASHBOARD_SUCCESS)

const deleteUsersAction = createAction<string[]>(Types.DELETE_USERS_SUCCESS)
const signOutAction = createAction(Types.SIGN_OUT)
const putDashboardFavoriteSuccess = createAction<
  { dashboard_group_id: string; index: number }[]
>(Types.PUT_DASHBOARD_FAVORITE_SUCCESS)
const dashboardFavoritesLayoutSuccess = createAction<
  { dashboard_group_id: string; index: number }[]
>(Types.PUT_DASHBOARD_FAVORITES_LAYOUT_SUCCESS)
const uploadWidgetImageSuccessAction = createAction<WidgetObject>(
  Types.UPLOAD_WIDGET_IMAGE_SUCCESS
)

const putFunnelStagesSuccessAction = createAction<{
  data: FunnelStage[]
  dashboardId: string
  widgetId: string
}>(Types.PUT_WIDGET_FUNNEL_STAGES_SUCCESS)

// Kpi options
const createKpiOption = createAction(Types.CREATE_KPI_OPTION)
const createKpiOptionSuccess = createAction<KpiOptionReduxResponse>(
  Types.CREATE_KPI_OPTION_SUCCESS
)
const updateKpiOptionAction = createAction(Types.UPDATE_KPI_OPTION)
const updateKpiOptionSuccess = createAction<KpiOptionPatchResponse>(
  Types.UPDATE_KPI_OPTION_SUCCESS
)
const deleteKpiOptionAction = createAction(Types.DELETE_KPI_OPTION)
const deleteKpiOptionSuccess = createAction<{
  dashboardId: string
  id: string
  widgetId: string
}>(Types.DELETE_KPI_OPTION_SUCCESS)

// Widget tags
const createWidgetTag = createAction(Types.CREATE_WIDGET_TAG)
const createWidgetTagSuccess = createAction<WidgetTagReduxPayload>(
  Types.CREATE_WIDGET_TAG_SUCCESS
)
const updateWidgetTag = createAction(Types.UPDATE_WIDGET_TAG)
const updateWidgetTagSuccess = createAction<WidgetTagReduxPayload>(
  Types.UPDATE_WIDGET_TAG_SUCCESS
)
const deleteWidgetTag = createAction(Types.DELETE_WIDGET_TAG)
const deleteWidgetTagSuccess = createAction<{
  dashboardId: string
  id: string
  widgetId: string
}>(Types.DELETE_WIDGET_TAG_SUCCESS)

// Widget tag statuses
const createWidgetTagStatusSuccess =
  createAction<WidgetTagStatusItemReduxPayload>(
    Types.CREATE_WIDGET_TAG_STATUS_SUCCESS
  )
const updateWidgetTagStatusSuccess =
  createAction<WidgetTagStatusItemReduxPayload>(
    Types.UPDATE_WIDGET_TAG_STATUS_SUCCESS
  )
const deleteWidgetTagStatusSuccess = createAction<{
  dashboardId: string
  id: string
  tagId: string
  widgetId: string
}>(Types.DELETE_WIDGET_TAG_STATUS_SUCCESS)

const putDashboardFiltersSuccess = createAction<{
  dashboardId: string
  filters: DashboardFilters[]
}>(Types.PUT_DASHBOARD_FILTER_OPTIONS_SUCCESS)

// widget additional segments
const createAdditionalSegment = createAction(Types.CREATE_ADDITIONAL_SEGMENT)
const createAdditionalSegmentSuccess =
  createAction<AdditionalSegmentReduxPayload>(
    Types.CREATE_ADDITIONAL_SEGMENT_SUCCESS
  )

const updateAdditionalSegment = createAction(Types.UPDATE_ADDITIONAL_SEGMENT)
const updateAdditionalSegmentSuccess =
  createAction<AdditionalSegmentReduxPayload>(
    Types.UPDATE_ADDITIONAL_SEGMENT_SUCCESS
  )

const deleteAdditionalSegment = createAction(Types.DELETE_ADDITIONAL_SEGMENT)
const deleteAdditionalSegmentSuccess = createAction<{
  id: string
  widgetId: string
  dashboardId: string
}>(Types.DELETE_ADDITIONAL_SEGMENT_SUCCESS)

// Reducer
const dashboardReducer = createReducer(INITIAL_STATE, (builder) => {
  builder
    .addCase(getAllAction, (state) => {
      return {
        ...state,
        fetching: true
      }
    })
    .addCase(getAllSuccessAction, (state, action) => {
      const { payload } = action

      const data: DashboardBody = {}

      payload.data.dashboards.forEach((dashboard) => {
        let widgets = {}

        if (dashboard.id in state.data) {
          widgets = state.data[dashboard.id].widgets
        }

        data[dashboard.id] = {
          ...dashboard,
          widgets: widgets
        }
      })

      return {
        data,
        groups: payload.data.groups,
        fetching: false,
        fetched: true,
        activeFetchOne: state.activeFetchOne,
        errorFetchOne: null,
        currentWidgetId: null,
        fetchIds: {}
      }
    })
    .addCase(getAllFailedAction, (state) => {
      return {
        ...state,
        fetching: false
      }
    })
    .addCase(copyDashboardGroupAction, (state, action) => {
      const { payload } = action

      const data = cloneDeep(state.data)

      payload.data.dashboards.forEach((dashboard) => {
        data[dashboard.id] = {
          ...dashboard,
          widgets: arrayToObject(dashboard.widgets) as WidgetBody
        }
      })

      return {
        ...state,
        data,
        groups: {
          ...state.groups,
          ...payload.data.groups
        }
      }
    })
    .addCase(getOneDashboardAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard.id]: {
            ...payload.data.dashboard,
            widgets: arrayToObject(payload.data.dashboard.widgets)
          }
        },
        activeFetchOne: payload.data.dashboard.id
      }
    })
    .addCase(getOnePublicDashboardAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: {
            ...payload.data,
            widgets: arrayToObject(payload.data.widgets)
          }
        },
        fetched: true,
        activeFetchOne: payload.data.id
      }
    })
    .addCase(getOnePublicDashboardFailedAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        errorFetchOne: payload
      }
    })
    .addCase(getOneDashboardFailedAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        errorFetchOne: payload
      }
    })
    .addCase(createDashboardAction, (state, action) => {
      const { payload } = action

      return newDashboardCreated(
        payload.data.dashboard,
        payload.data.group,
        state
      )
    })
    .addCase(deleteDashboardAction, (state, action) => {
      const { payload } = action

      const data = cloneDeep(state.data)
      const groups = cloneDeep(state.groups)
      const groupId = data[payload.id].dashboard_group_id

      delete data[payload.id]

      if (groups[groupId].dashboards.length === 1) {
        delete groups[groupId]
      } else {
        groups[groupId].dashboards = groups[groupId].dashboards.filter(
          (d) => d !== payload.id
        )
      }

      return {
        ...state,
        data,
        groups
      }
    })
    .addCase(updateDashboardAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard.id]: {
            ...state.data[payload.data.dashboard.id],
            title: payload.data.dashboard.title,
            share_settings: payload.data.dashboard.share_settings
          }
        }
      }
    })
    .addCase(moveDashboardAction, (state, action) => {
      const { data, previousDashboardGroupId } = action.payload

      return {
        ...state,
        data: {
          ...state.data,
          [data.dashboard.id]: {
            ...state.data[data.dashboard.id],
            // update dashboard group id in redux state
            dashboard_group_id: data.dashboard.dashboard_group_id
          }
        },
        groups: {
          ...state.groups,
          // remove dashboard from old group
          [previousDashboardGroupId]: {
            ...state.groups[previousDashboardGroupId],
            dashboards: state.groups[
              previousDashboardGroupId
            ].dashboards.filter((id) => id !== data.dashboard.id)
          },
          // add to new group
          [data.group.id]: {
            ...state.groups[data.group.id],
            dashboards: [
              ...state.groups[data.group.id].dashboards,
              data.dashboard.id
            ]
          }
        }
      }
    })
    .addCase(updateDashboardGroupAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        groups: {
          ...state.groups,
          [payload.data.id]: {
            ...state.groups[payload.data.id],
            title: payload.data.title,
            status: payload.data.status,
            folder_id: payload.data.folder_id
          }
        }
      }
    })
    .addCase(deleteDashboardGroupAction, (state, action) => {
      const { payload } = action

      const newGroups = cloneDeep(state.groups)

      delete newGroups[payload.id]

      return {
        ...state,
        groups: newGroups
      }
    })
    .addCase(updateDashboardOrderAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        groups: {
          ...state.groups,
          [payload.id]: {
            ...state.groups[payload.id],
            dashboards: payload.order
          }
        }
      }
    })

    // Widget actions
    .addCase(getOneWidgetAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.dashboardId]: {
            ...state.data[payload.dashboardId],
            widgets: {
              ...state.data[payload.dashboardId].widgets,
              [payload.id]: {
                ...state.data[payload.dashboardId].widgets[payload.id],
                data: null
              }
            }
          }
        },
        fetchIds: {
          ...state.fetchIds,
          [payload.id]: payload.fetchId
        }
      }
    })
    .addCase(getOneWidgetSuccessAction, (state, action) => {
      const { payload } = action

      if (
        state.fetchIds[payload.data.id] &&
        state.fetchIds[payload.data.id] !== payload.fetchId
      ) {
        return state
      }

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard_id]: {
            ...state.data[payload.data.dashboard_id],
            widgets: {
              ...state.data[payload.data.dashboard_id].widgets,
              [payload.data.id]: {
                ...payload.data,
                layout:
                  state.data[payload.data.dashboard_id].widgets[payload.data.id]
                    .layout
              }
            }
          }
        },
        fetchIds: { ...state.fetchIds, [payload.data.id]: null },
        currentWidgetId: null
      }
    })
    .addCase(createWidgetAction, (state) => {
      return {
        ...state,
        currentWidgetId: null
      }
    })
    .addCase(createWidgetSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard_id]: {
            ...state.data[payload.data.dashboard_id],
            available_space: payload.available_space,
            widgets: {
              ...state.data[payload.data.dashboard_id].widgets,
              [payload.data.id]: payload.data
            }
          }
        },
        currentWidgetId: payload.skipFetchData ? null : payload.data.id
      }
    })
    .addCase(updateWidgetAction, (state) => {
      return {
        ...state,
        currentWidgetId: null
      }
    })
    .addCase(updateWidgetSuccessAction, (state, action) => {
      const { payload } = action

      const widget =
        state.data[payload.data.dashboard_id].widgets[payload.data.id]

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.dashboard_id]: {
            ...state.data[payload.data.dashboard_id],
            widgets: {
              ...state.data[payload.data.dashboard_id].widgets,
              [payload.data.id]: {
                ...payload.data,
                layout:
                  state.data[payload.data.dashboard_id].widgets[payload.data.id]
                    .layout,
                data: payload.skipFetchData ? widget.data : null,
                settings: {
                  ...payload.data.settings,
                  // dont overwrite to/from-dates and external_link
                  // when skipFetchData
                  date_filter: {
                    ...payload.data.settings.date_filter,
                    selected: {
                      ...payload.data.settings.date_filter.selected,
                      from_date: payload.skipFetchData
                        ? widget.settings.date_filter.selected.from_date
                        : payload.data.settings.date_filter.selected.from_date,
                      to_date: payload.skipFetchData
                        ? widget.settings.date_filter.selected.to_date
                        : payload.data.settings.date_filter.selected.to_date
                    }
                  },
                  comparative_period: {
                    ...payload.data.settings.comparative_period,
                    selected: {
                      ...payload.data.settings.comparative_period.selected,
                      from_date: payload.skipFetchData
                        ? widget.settings.comparative_period.selected.from_date
                        : payload.data.settings.comparative_period.selected
                          .from_date,
                      to_date: payload.skipFetchData
                        ? widget.settings.comparative_period.selected.to_date
                        : payload.data.settings.comparative_period.selected
                          .to_date
                    }
                  },
                  external_link: {
                    available: widget.settings.external_link.available,
                    show: payload.data.settings.external_link.show
                  }
                }
              }
            }
          }
        },
        currentWidgetId: payload.skipFetchData ? null : payload.data.id
      }
    })
    .addCase(uploadWidgetImageSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.dashboard_id]: {
            ...state.data[payload.dashboard_id],
            widgets: {
              ...state.data[payload.dashboard_id].widgets,
              [payload.id]: {
                ...payload,
                layout:
                  state.data[payload.dashboard_id].widgets[payload.id].layout
              }
            }
          }
        }
      }
    })
    .addCase(deleteWidgetAction, (state, action) => {
      const { payload } = action

      const widgets = cloneDeep(state.data[payload.dashboardId].widgets)

      delete widgets[payload.id]

      return {
        ...state,
        data: {
          ...state.data,
          [payload.dashboardId]: {
            ...state.data[payload.dashboardId],
            available_space: payload.available_space,
            widgets
          }
        }
      }
    })
    .addCase(updateWidgetLayoutAction, (state, action) => {
      const { payload } = action

      const widgets = cloneDeep(state.data[payload.id].widgets)

      payload.layout.forEach((layout: LayoutUpdate) => {
        widgets[layout.id] = {
          ...state.data[payload.id].widgets[layout.id],
          layout: layout
        }
      })

      return {
        ...state,
        data: {
          ...state.data,
          [payload.id]: {
            ...state.data[payload.id],
            available_space: payload.available_space,
            widgets
          }
        }
      }
    })
    .addCase(inviteToDashboardAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: {
            ...state.data[payload.data.id],
            share_settings: payload.data.share_settings
          }
        }
      }
    })
    .addCase(revokeFromDashboardAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.data.id]: {
            ...state.data[payload.data.id],
            share_settings: payload.data.share_settings
          }
        }
      }
    })
    .addCase(putFunnelStagesSuccessAction, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.dashboardId]: {
            ...state.data[payload.dashboardId],
            widgets: {
              ...state.data[payload.dashboardId].widgets,
              [payload.widgetId]: {
                ...state.data[payload.dashboardId].widgets[payload.widgetId],
                settings: {
                  ...state.data[payload.dashboardId].widgets[payload.widgetId]
                    .settings,
                  segment_by: {
                    ...state.data[payload.dashboardId].widgets[payload.widgetId]
                      .settings.segment_by,
                    stages: payload.data
                  }
                }
              }
            }
          }
        }
      }
    })
    // Kpi options
    .addCase(createKpiOption, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(createKpiOptionSuccess, (state, action) => {
      const { payload } = action

      return updateKpiOption(state, payload)
    })
    .addCase(updateKpiOptionAction, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(updateKpiOptionSuccess, (state, action) => {
      const { payload } = action

      let newState = cloneDeep(state)

      payload.data.forEach((option) => {
        newState = updateKpiOption(newState, {
          option,
          dashboardId: payload.dashboardId,
          skipFetchData: payload.skipFetchData
        })
      })

      return newState
    })
    .addCase(deleteKpiOptionAction, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(deleteKpiOptionSuccess, (state, action) => {
      const { payload } = action

      return deleteKpiOption(state, payload)
    })
    // Widget tag
    .addCase(createWidgetTag, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(createWidgetTagSuccess, (state, action) => {
      const { payload } = action

      return updateStoreWidgetTag(state, payload)
    })
    .addCase(updateWidgetTag, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(updateWidgetTagSuccess, (state, action) => {
      const { payload } = action

      return updateStoreWidgetTag(state, payload)
    })
    .addCase(deleteWidgetTag, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(deleteWidgetTagSuccess, (state, action) => {
      const { payload } = action

      return deleteStoreWidgetTag(state, payload)
    })
    // widget tag status items
    .addCase(createWidgetTagStatusSuccess, (state, action) => {
      const { payload } = action

      return updateStoreWidgetTagStatus(state, payload)
    })
    .addCase(updateWidgetTagStatusSuccess, (state, action) => {
      const { payload } = action

      return updateStoreWidgetTagStatus(state, payload)
    })
    .addCase(deleteWidgetTagStatusSuccess, (state, action) => {
      const { payload } = action

      return deleteStoreWidgetTagStatus(state, payload)
    })

    .addCase(deleteUsersAction, (state, action) => {
      const { payload } = action
      const newData = cloneDeep(state.data)
      const deletedAccountIds: { [id: string]: boolean } = {}

      payload.forEach((id) => (deletedAccountIds[id] = true))

      Object.keys(newData).forEach((id) => {
        if (newData[id]?.share_settings?.permissions) {
          newData[id].share_settings.permissions = newData[
            id
          ]?.share_settings.permissions.filter(
            (permission) =>
              !((permission.account_id as string) in deletedAccountIds)
          )
        }
      })

      return { ...state, data: newData }
    })
    .addCase(dashboardFavoritesLayoutSuccess, (state, action) => {
      const { payload } = action
      const newGroups = cloneDeep(state.groups)

      payload.forEach((favorite) => {
        newGroups[favorite.dashboard_group_id].favorite.index = favorite.index
      })

      return { ...state, groups: newGroups }
    })
    .addCase(putDashboardFavoriteSuccess, (state, action) => {
      const { payload } = action
      const newGroups = cloneDeep(state.groups)
      const indexMapper: { [dashboardId: string]: number } = {}

      payload.forEach((favorite) => {
        indexMapper[favorite.dashboard_group_id] = favorite.index
      })

      // update dashboards with favorites
      Object.keys(newGroups).forEach((groupId) => {
        if (indexMapper[groupId] !== undefined) {
          newGroups[groupId].favorite.index = indexMapper[groupId]
          newGroups[groupId].favorite.active = true
        } else if (newGroups[groupId].favorite.active) {
          newGroups[groupId].favorite.active = false
          newGroups[groupId].favorite.index = null
        }
      })

      return { ...state, groups: newGroups }
    })
    .addCase(putDashboardFiltersSuccess, (state, action) => {
      const { payload } = action

      return {
        ...state,
        data: {
          ...state.data,
          [payload.dashboardId]: {
            ...state.data[payload.dashboardId],
            dashboard_filters: payload.filters
          }
        }
      }
    })
    // Widget additional segment
    .addCase(createAdditionalSegment, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(createAdditionalSegmentSuccess, (state, action) => {
      const { payload } = action

      return updateStoreWidgetAdditionalSegment(state, payload)
    })
    .addCase(updateAdditionalSegment, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(updateAdditionalSegmentSuccess, (state, action) => {
      const { payload } = action

      return updateStoreWidgetAdditionalSegment(state, payload)
    })
    .addCase(deleteAdditionalSegment, (state) => {
      // Reset currentWidgetId, -> Trigger new widget fetch.
      return { ...state, currentWidgetId: null }
    })
    .addCase(deleteAdditionalSegmentSuccess, (state, action) => {
      const { payload } = action

      return deleteStoreWidgetAdditionalSegment(state, payload)
    })
    .addCase(signOutAction, () => INITIAL_STATE)
    .addDefaultCase((state) => state)
})

export const updateKpiOption = (
  state: DashboardReducerType,
  payload: KpiOptionReduxResponse
) => {
  const { dashboardId, option } = payload

  const kpiOptions = state.data[dashboardId].widgets[
    option.widget_id
  ].settings.kpi_options.filter((opt) => opt.id !== option.id)

  kpiOptions.push(option)

  return {
    ...state,
    data: {
      ...state.data,
      [dashboardId]: {
        ...state.data[dashboardId],
        widgets: {
          ...state.data[dashboardId].widgets,
          [option.widget_id]: {
            ...state.data[dashboardId].widgets[option.widget_id],
            settings: {
              ...state.data[dashboardId].widgets[option.widget_id].settings,
              kpi_options: kpiOptions
            }
          }
        }
      }
    },
    currentWidgetId: payload.skipFetchData ? null : option.widget_id
  }
}

export const deleteKpiOption = (
  state: DashboardReducerType,
  payload: { dashboardId: string; id: string; widgetId: string }
) => {
  const { dashboardId, id, widgetId } = payload

  return {
    ...state,
    data: {
      ...state.data,
      [dashboardId]: {
        ...state.data[dashboardId],
        widgets: {
          ...state.data[dashboardId].widgets,
          [widgetId]: {
            ...state.data[dashboardId].widgets[widgetId],
            settings: {
              ...state.data[dashboardId].widgets[widgetId].settings,
              kpi_options: state.data[dashboardId].widgets[
                widgetId
              ].settings.kpi_options
                .filter((option) => option.id !== id)
                // reset indices of kpi options
                .map((item, index) => ({ ...item, index }))
            }
          }
        }
      }
    },
    currentWidgetId: widgetId
  }
}

export const updateStoreWidgetTag = (
  state: DashboardReducerType,
  payload: WidgetTagReduxPayload
) => {
  const { dashboardId, tag, widgetId } = payload

  const selectedTags = state.data[dashboardId].widgets[
    widgetId
  ].settings.tags.selected.filter((t) => t.id !== tag.id)

  selectedTags.push(tag)

  return {
    ...state,
    data: {
      ...state.data,
      [dashboardId]: {
        ...state.data[dashboardId],
        widgets: {
          ...state.data[dashboardId].widgets,
          [widgetId]: {
            ...state.data[dashboardId].widgets[widgetId],
            settings: {
              ...state.data[dashboardId].widgets[widgetId].settings,
              tags: {
                ...state.data[dashboardId].widgets[widgetId].settings.tags,
                selected: selectedTags
              }
            }
          }
        }
      }
    },
    currentWidgetId: payload.skipFetchData ? null : widgetId
  }
}

export const deleteStoreWidgetTag = (
  state: DashboardReducerType,
  payload: { dashboardId: string; id: string; widgetId: string }
) => {
  const { dashboardId, id, widgetId } = payload

  return {
    ...state,
    data: {
      ...state.data,
      [dashboardId]: {
        ...state.data[dashboardId],
        widgets: {
          ...state.data[dashboardId].widgets,
          [widgetId]: {
            ...state.data[dashboardId].widgets[widgetId],
            settings: {
              ...state.data[dashboardId].widgets[widgetId].settings,
              tags: {
                ...state.data[dashboardId].widgets[widgetId].settings.tags,
                selected: state.data[dashboardId].widgets[
                  widgetId
                ].settings.tags.selected.filter((tag) => tag.id !== id)
              }
            }
          }
        }
      }
    },
    currentWidgetId: widgetId
  }
}

const updateStoreWidgetTagStatus = (
  state: DashboardReducerType,
  payload: WidgetTagStatusItemReduxPayload
) => {
  const { dashboardId, statusItem, tagId, widgetId } = payload

  const currentTags = cloneDeep(
    state.data[dashboardId].widgets[widgetId].settings.tags.selected
  )

  const tag = currentTags.find((t) => t.id === tagId) as WidgetTagObject

  tag.settings.status.items = upsertById<TagStatusItem>(
    tag.settings.status.items,
    statusItem
  )

  const selectedTags = upsertById(currentTags, tag)

  return {
    ...state,
    data: {
      ...state.data,
      [dashboardId]: {
        ...state.data[dashboardId],
        widgets: {
          ...state.data[dashboardId].widgets,
          [widgetId]: {
            ...state.data[dashboardId].widgets[widgetId],
            settings: {
              ...state.data[dashboardId].widgets[widgetId].settings,
              tags: {
                ...state.data[dashboardId].widgets[widgetId].settings.tags,
                selected: selectedTags
              }
            }
          }
        }
      }
    },
    currentWidgetId: widgetId
  }
}

export const deleteStoreWidgetTagStatus = (
  state: DashboardReducerType,
  payload: { dashboardId: string; id: string; tagId: string; widgetId: string }
) => {
  const { dashboardId, id, tagId, widgetId } = payload

  return {
    ...state,
    data: {
      ...state.data,
      [dashboardId]: {
        ...state.data[dashboardId],
        widgets: {
          ...state.data[dashboardId].widgets,
          [widgetId]: {
            ...state.data[dashboardId].widgets[widgetId],
            settings: {
              ...state.data[dashboardId].widgets[widgetId].settings,
              tags: {
                ...state.data[dashboardId].widgets[widgetId].settings.tags,
                selected: state.data[dashboardId].widgets[
                  widgetId
                ].settings.tags.selected.map((tag) => {
                  if (tag.id !== tagId) {
                    return tag
                  }

                  const newTag = cloneDeep(tag)

                  newTag.settings.status.items =
                    newTag.settings.status.items.filter(
                      (item) => item.id !== id
                    )

                  return newTag
                })
              }
            }
          }
        }
      }
    },
    currentWidgetId: widgetId
  }
}

export const updateStoreWidgetAdditionalSegment = (
  state: DashboardReducerType,
  payload: AdditionalSegmentReduxPayload
) => {
  const { dashboardId, data } = payload

  const additional = [
    ...state.data[dashboardId].widgets[data.widget_id].settings.segment_by
      .additional
  ]

  // replace value on given index
  additional.splice(data.index, 1, data)

  return {
    ...state,
    data: {
      ...state.data,
      [dashboardId]: {
        ...state.data[dashboardId],
        widgets: {
          ...state.data[dashboardId].widgets,
          [data.widget_id]: {
            ...state.data[dashboardId].widgets[data.widget_id],
            settings: {
              ...state.data[dashboardId].widgets[data.widget_id].settings,
              segment_by: {
                ...state.data[dashboardId].widgets[data.widget_id].settings
                  .segment_by,
                additional
              }
            }
          }
        }
      }
    },
    currentWidgetId: data.widget_id
  }
}

export const deleteStoreWidgetAdditionalSegment = (
  state: DashboardReducerType,
  payload: { dashboardId: string; id: string; widgetId: string }
) => {
  const { dashboardId, id, widgetId } = payload

  const additional = [
    ...state.data[dashboardId].widgets[widgetId].settings.segment_by.additional
  ].filter((segment) => segment.id !== id)

  return {
    ...state,
    data: {
      ...state.data,
      [dashboardId]: {
        ...state.data[dashboardId],
        widgets: {
          ...state.data[dashboardId].widgets,
          [widgetId]: {
            ...state.data[dashboardId].widgets[widgetId],
            settings: {
              ...state.data[dashboardId].widgets[widgetId].settings,
              segment_by: {
                ...state.data[dashboardId].widgets[widgetId].settings
                  .segment_by,
                // update indices
                additional: additional.map((item, index) => ({
                  ...item,
                  index
                }))
              }
            }
          }
        }
      }
    },
    currentWidgetId: widgetId
  }
}

/**
 * Updates an existing item in the array or
 * inserts a new item if it doesn't exist,
 * based on the `id` property.
 *
 * @param arr - The array of objects to be updated or appended to.
 * @param toUpsert - The object to insert or update in the array.
 * @returns A new array with the item either updated or inserted.
 *
 */
const upsertById = <T extends { id: string }>(arr: T[], toInsert: T) => {
  const index = arr.findIndex((item) => item.id === toInsert.id)

  if (index === -1) {
    // new item in array, put last
    return [...arr, toInsert]
  }

  // If the item is found, update it in the array
  const updatedArray = [...arr]

  updatedArray[index] = toInsert

  return updatedArray
}

export default dashboardReducer

const newDashboardCreated = (
  dashboard: DashboardAPI,
  group: DashboardGroup,
  state: DashboardReducerType
) => {
  let newGroup = group

  if (dashboard.dashboard_group_id in state.groups) {
    newGroup = cloneDeep(state.groups[dashboard.dashboard_group_id])
    newGroup.dashboards.push(dashboard.id)
  }

  return {
    ...state,
    data: {
      ...state.data,
      [dashboard.id]: {
        ...dashboard,
        widgets: arrayToObject<WidgetObject>(dashboard.widgets)
      }
    },
    groups: {
      ...state.groups,
      [dashboard.dashboard_group_id]: newGroup
    }
  }
}
