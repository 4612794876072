import React, { useState } from 'react'

import { PageContainerProps } from '.'

import { getColorMode, getTheme } from 'themes'
import { ThemeProvider } from '@mui/material'

import Box from 'components_new/atoms/Box'
import Fab from 'components_new/atoms/Fab'
import Icon from 'components_new/atoms/Icon'

import SimpleSwipeableDrawer from 'components_new/molecules/SimpleSwipeableDrawer'

import BannerBar from 'components_new/organisms/BannerBar'
import MobileSupportChecker from 'components_new/organisms/MobileSupportChecker'
import Topbar from 'components_new/organisms/Topbar'

export const MOBILE_FOOTER_HEIGHT = 72
export const MOBILE_HEADER_HEIGHT = 64

const Mobile = (props: PageContainerProps) => {
  const {
    bgcolor,
    children,
    embedded,
    NavbarProps,
    rightSidebar,
    RightSidebarProps,
    topbar,
    TopbarProps,
    unauthorized
  } = props

  const [open, setOpen] = useState<boolean>(false)

  const isExternal = embedded || unauthorized

  const theme = getTheme(bgcolor ? getColorMode(bgcolor) : 'light', null, {
    responsive: true
  })

  // check if the user agent is actually a phone:
  const userAgent = window.navigator.userAgent
  const isPhone = /iPhone|iPad|iPod|Android/i.test(userAgent)

  return (
    <>
      {/*-- mobile support --*/}
      {isExternal ? null : <MobileSupportChecker />}

      {/*-- banner --*/}
      {unauthorized ? null : <BannerBar />}

      {/*-- header --*/}
      {!isExternal ? (
        <Box
          component="header"
          sx={{
            bgcolor: 'background.default',
            borderBottom: '1px solid',
            borderColor: 'divider',
            scrollbarGutter: 'stable',
            overflow: 'hidden',
            position: 'fixed',
            width: '100%',
            top: 0,
            left: 0,
            zIndex: 100,
            height: MOBILE_HEADER_HEIGHT
          }}
        >
          {/*-- topbar --*/}
          <Topbar
            actions={TopbarProps?.actions}
            displayProfile={TopbarProps?.displayProfile}
            navLocked={NavbarProps?.locked}
            noGutter={TopbarProps?.noGutter}
            title={NavbarProps?.header}
          >
            {topbar}
          </Topbar>
        </Box>
      ) : null}

      {/*-- main --*/}
      <Box
        component="main"
        sx={{
          height:
            NavbarProps?.menu || rightSidebar
              ? `calc(100${isPhone ? 'svh' : 'vh'} - ${MOBILE_FOOTER_HEIGHT}px)`
              : `100${isPhone ? 'svh' : 'vh'}`,
          pt: isExternal ? undefined : `${MOBILE_HEADER_HEIGHT}px`,
          bgcolor: bgcolor ?? 'background.paper'
        }}
      >
        {children}

        {/*-- footer bleed --*/}
        {NavbarProps?.menu || rightSidebar ? (
          <Box
            sx={{
              height: MOBILE_FOOTER_HEIGHT,
              minHeight: MOBILE_FOOTER_HEIGHT,
              bgcolor: bgcolor ?? 'background.paper'
            }}
          />
        ) : null}
      </Box>

      {/* --- drawer --- */}
      {rightSidebar ? (
        <SimpleSwipeableDrawer
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          sx={{ zIndex: 1199 }}
        >
          {rightSidebar}
        </SimpleSwipeableDrawer>
      ) : null}

      {/* --- footer --- */}
      {NavbarProps?.menu ? (
        <Box
          component="footer"
          sx={{
            bgcolor: 'background.paper',
            scrollbarGutter: 'stable',
            overflow: 'hidden',
            position: 'fixed',
            width: '100%',
            bottom: 0,
            left: 0,
            zIndex: 100,
            height: MOBILE_FOOTER_HEIGHT,
            borderTop: '1px solid',
            borderColor: 'divider'
          }}
        >
          {NavbarProps?.menu}
        </Box>
      ) : null}

      {RightSidebarProps?.mobilePreview ? (
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 72,
            height: 56,
            width: 'calc(100% - 72px)'
          }}
        >
          {RightSidebarProps?.mobilePreview}
        </Box>
      ) : null}

      {rightSidebar ? (
        <ThemeProvider theme={theme}>
          <Fab
            color="primary"
            onClick={() => setOpen(true)}
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
          >
            <Icon name={RightSidebarProps?.iconName ?? 'ExpandLessOutlined'} />
          </Fab>
        </ThemeProvider>
      ) : null}
    </>
  )
}

Mobile.displayName = 'Mobile'

export default Mobile
