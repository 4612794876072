import { IconNames } from 'components_new/atoms/Icon'
import { AuthenticationState } from 'types/GlobalAuthentication'
import { AccountRole } from 'types/GlobalUser'

export const getNavigationItems = (
  AuthStore: AuthenticationState
): {
  hidden?: boolean
  iconName: IconNames
  label: string
  value: string
}[] => {
  return [
    {
      label: 'Dashboards',
      iconName: 'GridViewOutlined',
      value: '/dashboards'
    },
    {
      iconName: 'Star',
      label: 'Favoriter',
      value: '/dashboards/favorites'
    },
    {
      hidden: AuthStore.user?.role !== AccountRole.ADMIN,
      iconName: 'GroupOutlined',
      label: 'Användare',
      value: '/admin/settings/users'
    }
  ]
}
