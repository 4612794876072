import React from 'react'
import { createTheme, PaletteMode, darken, Theme } from '@mui/material'

import { CustomColors } from 'types/GlobalCustomization'

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: Palette['primary']
    quaternary: Palette['primary']
    quintary: Palette['primary']
    senary: Palette['primary']
    septenary: Palette['primary']
    octonary: Palette['primary']
    nonary: Palette['primary']
    denary: Palette['primary']
    brand: Palette['primary']
    black: Palette['primary']
    white: Palette['primary']
    neutral: Palette['primary']
  }

  interface PaletteOptions {
    tertiary: PaletteOptions['primary']
    quaternary: PaletteOptions['primary']
    quintary: PaletteOptions['primary']
    senary: PaletteOptions['primary']
    septenary: PaletteOptions['primary']
    octonary: PaletteOptions['primary']
    nonary: PaletteOptions['primary']
    denary: PaletteOptions['primary']
    brand: PaletteOptions['primary']
    black: PaletteOptions['primary']
    white: PaletteOptions['primary']
    neutral: PaletteOptions['primary']
  }

  interface PaletteColor {
    background?: string
    transparentDark?: string
    transparent?: string
    text?: string
  }

  interface SimplePaletteColorOptions {
    transparent?: string
    text?: string
  }

  interface Partial {
    tooltip?: string
  }

  interface TypeBackground {
    backdrop?: string
    overlay?: string
    transparent?: string
    widget?: string
  }

  interface TypeText {
    primaryHex: string
    secondaryHex: string
  }
}

export type Color =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'quintary'
  | 'senary'
  | 'septenary'
  | 'octonary'
  | 'nonary'
  | 'denary'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    gigantic: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    gigantic?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    gigantic: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true
    quaternary: true
    quintary: true
    senary: true
    septenary: true
    octonary: true
    nonary: true
    denary: true
    brand: true
    black: true
    white: true
    neutral: true
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    tertiary: true
    quaternary: true
    quintary: true
    senary: true
    septenary: true
    octonary: true
    nonary: true
    denary: true
    brand: true
    black: true
    white: true
    neutral: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    tertiary: true
    quaternary: true
    quintary: true
    senary: true
    septenary: true
    octonary: true
    nonary: true
    denary: true
    brand: true
    black: true
    white: true
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    tertiary: true
    quaternary: true
    quintary: true
    senary: true
    septenary: true
    octonary: true
    nonary: true
    denary: true
    brand: true
    black: true
    white: true
    neutral: true
  }
}

declare module '@mui/material/Icon' {
  interface IconPropsColorOverrides {
    tertiary: true
    quaternary: true
    quintary: true
    senary: true
    septenary: true
    octonary: true
    nonary: true
    denary: true
    brand: true
    black: true
    white: true
    neutral: true
    default: true
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    disabled: true
    tertiary: true
    quaternary: true
    quintary: true
    senary: true
    septenary: true
    octonary: true
    nonary: true
    denary: true
    brand: true
    black: true
    white: true
    neutral: true
  }
}

declare module '@mui/material/LinearProgress' {
  interface LinearProgressPropsColorOverrides {
    tertiary: true
    quaternary: true
    quintary: true
    senary: true
    septenary: true
    octonary: true
    nonary: true
    denary: true
    brand: true
    black: true
    white: true
    neutral: true
    default: true
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true
    quaternary: true
    quintary: true
    senary: true
    septenary: true
    octonary: true
    nonary: true
    denary: true
    brand: true
    black: true
    white: true
    neutral: true
    default: true
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    content: true
    mobile: true
    section: true
    title: true
  }
}

export function hexToRgbString(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')'
  }
}

export function hexToRgbObject(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  return {
    red: r,
    green: g,
    blue: b
  }
}

export function rgbaStringToRgbaObject(rgba: string) {
  const array = rgba
    .substring(5, rgba.length - 1)
    .replace(/ /g, '')
    .split(',')

  const r = parseInt(array[0]),
    g = parseInt(array[1]),
    b = parseInt(array[2]),
    a = parseInt(array[3])

  return {
    red: r,
    green: g,
    blue: b,
    alpha: a
  }
}

export function hexToDark(hex: string) {
  return darken(hex, 0.2 * 1.5) // 0.2 = default tonalOffset
}

export const getColorMode = (hex: string): PaletteMode => {
  const rgb = hexToRgbObject(hex)

  const light = rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue * 0.114 > 186

  if (light) {
    return 'light'
  }

  return 'dark'
}

export const getContrastTextColor = (hex: string) => {
  return getTheme(getColorMode(hex)).palette.text.primary
}

export const DEFAULT_MOBILE_BREAKPOINT = 768 // sm

export const getTheme = (
  mode: PaletteMode,
  customColors?: CustomColors | null,
  MobileProps?: {
    breakpoint?: number | null
    responsive?: boolean
  }
) => {
  let mobileBreakpoint = 0

  if (MobileProps?.responsive) {
    if (MobileProps?.breakpoint) {
      mobileBreakpoint = MobileProps.breakpoint
    } else {
      mobileBreakpoint = DEFAULT_MOBILE_BREAKPOINT
    }
  }

  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 996,
        lg: 1200,
        xl: 1536,
        mobile: mobileBreakpoint,
        section: 996,
        content: 700,
        title: 540
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '*': {
            boxSizing: 'border-box',
            margin: 0,
            padding: 0
          },
          html: {
            MozOsxFontSmoothing: 'grayscale',
            WebkitFontSmoothing: 'antialiased',
            textRendering: 'optimizeLegibility',
            scrollBehavior: 'smooth'
          },
          body: {
            backgroundColor: '#ffffff',
            overflow: 'auto',
            // userSelect: 'none', // Could be activated for mobile.
            WebKitTapHighlightColor: 'transparent',
            WebkitOverflowScrolling: 'touch'
          },
          a: {
            color: 'inherit',
            textDecoration: 'none'
          },
          ul: {
            listStyleType: 'none',
            margin: 0,
            padding: 0
          },
          code: {
            backgroundColor: 'rgba(26, 26, 29, 0.08)', // action.selected
            borderRadius: '3px',
            color: 'inherit',
            display: 'inline',
            fontSize: '0.875em',
            overflow: 'auto',
            overflowWrap: 'break-word',
            padding: '0.1em 0.5ch',
            WebKitBoxDecorationBreak: 'clone',
            whiteSpace: 'pre-wrap'
          }
        }
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            gigantic: 'h1'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: 500
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 999,
            whiteSpace: 'nowrap',
            minWidth: 'auto'
          }
        }
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            borderRadius: 999
          }
        }
      }
    },
    palette: {
      mode: mode,
      common: {
        black: '#1a1a1d',
        white: '#ffffff'
      },
      black: {
        main: '#1a1a1d',
        light: '#313134',
        dark: '#1a1a1d',
        contrastText: '#ffffff',
        text: '#ffffff',
        transparent: 'rgba(26, 26, 29, 0.12)'
      },
      white: {
        main: '#ffffff',
        light: '#ffffff',
        dark: '#e8e8e8',
        contrastText: '#1a1a1d',
        text: '#1a1a1d',
        transparent: 'rgba(255, 255, 255, 0.12)'
      },
      brand: {
        main: '#22c55e',
        light: '#4ade80',
        dark: '#16a34a',
        contrastText: 'rgba(26, 26, 29, 0.87)',
        text: '#16a34a',
        background: '#BBF7D0',
        backgroundDark: '#86EFAC',
        transparent: 'rgba(34, 197, 94, 0.12)'
      },
      // primary: is defined below in light- and dark mode.
      secondary: {
        main: customColors?.secondary_color || '#A855F7',
        light: '#C084FC',
        dark: '#9333EA',
        contrastText: 'rgba(26, 26, 29, 0.87)',
        text: '#9333EA',
        background: '#E9D5FF',
        transparent: customColors?.secondary_color
          ? hexToRgbString(customColors?.secondary_color, 0.12)
          : 'rgba(168, 85, 247, 0.12)'
      },
      tertiary: {
        main: customColors?.tertiary_color || '#84CC16',
        light: '#A3E635',
        dark: '#65A30D',
        contrastText: 'rgba(26, 26, 29, 0.87)',
        text: '#65A30D',
        background: '#D9F99D',
        transparent: customColors?.tertiary_color
          ? hexToRgbString(customColors?.tertiary_color, 0.12)
          : 'rgba(132, 204, 22, 0.12)'
      },
      // some work left to do here:
      quaternary: {
        main: customColors?.quaternary_color || '#06b6d4',
        light: '#22d3ee',
        dark: '#0891b2',
        contrastText: '#1a1a1d',
        text: '#0891b2',
        transparent: customColors?.quaternary_color
          ? hexToRgbString(customColors?.quaternary_color, 0.12)
          : 'rgba(6, 182, 212, 0.12)'
      },
      quintary: {
        main: customColors?.quintary_color || '#14b8a6',
        light: '#2dd4bf',
        dark: '#0d9488',
        contrastText: '#1a1a1d',
        text: '#0d9488',
        transparent: customColors?.quintary_color
          ? hexToRgbString(customColors?.quintary_color, 0.12)
          : 'rgba(20, 184, 166, 0.12)'
      },
      senary: {
        main: customColors?.senary_color || '#10B981',
        light: '#34d399',
        dark: '#059669',
        contrastText: '#1a1a1d',
        text: '#059669',
        transparent: customColors?.senary_color
          ? hexToRgbString(customColors?.senary_color, 0.12)
          : 'rgba(16, 185, 129, 0.12)'
      },
      septenary: {
        main: customColors?.septenary_color || '#6366f1',
        light: '#818cf8',
        dark: '#4f46e5',
        contrastText: '#1a1a1d',
        text: '#4f46e5',
        transparent: customColors?.septenary_color
          ? hexToRgbString(customColors?.septenary_color, 0.12)
          : 'rgba(99, 102, 241, 0.12)'
      },
      octonary: {
        main: customColors?.octonary_color || '#e879f9',
        light: '#d946ef',
        dark: '#c026d3',
        contrastText: '#1a1a1d',
        text: '#c026d3',
        transparent: customColors?.octonary_color
          ? hexToRgbString(customColors?.octonary_color, 0.12)
          : 'rgba(217, 70, 239, 0.12)'
      },
      nonary: {
        main: customColors?.nonary_color || '#0ea5e9',
        light: '#0284c7',
        dark: '#0369a1',
        contrastText: '#1a1a1d',
        text: '#0369a1',
        transparent: customColors?.nonary_color
          ? hexToRgbString(customColors?.nonary_color, 0.12)
          : 'rgba(2, 132, 199, 0.12)'
      },
      denary: {
        main: customColors?.denary_color || '#8b5cf6',
        light: '#7c3aed',
        dark: '#6d28d0',
        contrastText: '#1a1a1d',
        text: '#6d28d0',
        transparent: customColors?.denary_color
          ? hexToRgbString(customColors?.denary_color, 0.12)
          : 'rgba(124, 58, 237, 0.12)'
      },
      ...(mode === 'light'
        ? {
            primary: {
              main: customColors?.primary_color || '#1a1a1d',
              light: '#313134',
              dark: customColors?.primary_color
                ? hexToDark(customColors.primary_color)
                : '#000000',
              contrastText: 'rgba(255, 255, 255, 0.87)',
              text: customColors?.primary_color
                ? hexToDark(customColors.primary_color)
                : '#1a1a1d',
              background: '#1a1a1d',
              transparent: customColors?.primary_color
                ? hexToRgbString(customColors?.primary_color, 0.12)
                : 'rgba(26, 26, 29, 0.12)',
              transparentLight: 'rgba(26, 26, 29, 0.6)',
              transparentDark: 'rgba(26, 26, 29, 0.26)'
            },
            grey: {
              50: '#f7f7f8', // ?
              100: '#eeeef0',
              200: '#d9d9de',
              300: '#b8b9c1',
              400: '#91939f',
              500: '#747583',
              600: '#5e5f6b',
              700: '#4d4e57',
              800: '#42424a',
              900: '#3a3a40'
            },
            action: {
              active: 'rgba(26, 26, 29, 0.54)',
              hover: 'rgba(26, 26, 29, 0.04)',
              selected: 'rgba(26, 26, 29, 0.08)',
              disabled: 'rgba(26, 26, 29, 0.26)',
              disabledBackground: 'rgba(26, 26, 29, 0.06)',
              focus: 'rgba(26, 26, 29, 0.12)'
            },
            background: {
              backdrop: 'rgba(26, 26, 29, 0.5)',
              default: '#f7f7f8',
              paper: '#ffffff',
              overlay: 'rgba(26, 26, 29, 0.12)',
              tooltip: 'rgba(26, 26, 29, 0.92)',
              widget: customColors?.widget_background_color || '#ffffff'
            },
            default: {
              main: '#ffffff',
              light: '#fffff',
              dark: '#f7f7f8',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: '#1a1a1d',
              transparent: 'rgba(26, 26, 29, 0.87)'
            },
            divider: '#e2e8f0',
            neutral: {
              main: '#ffffff',
              light: '#ffffff',
              dark: '#eeeef0',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: '#1a1a1d',
              transparent: 'rgba(26, 26, 29, 0.87)'
            },
            success: {
              main: '#22C55E',
              light: '#4ADE80',
              dark: '#16A34A',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: '#16A34A',
              background: '#BBF7D0',
              transparent: 'rgba(74, 222, 128, 0.12)'
            },
            info: {
              main: '#3B82F6',
              light: '#60A5FA',
              dark: '#2563EB',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: '#2563EB',
              background: '#BFDBFE',
              transparent: 'rgba(96, 165, 250, 0.12)'
            },
            warning: {
              main: '#F59E0B',
              light: '#FBBF24',
              dark: '#D97706',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: '#D97706',
              background: '#FDE68A',
              transparent: 'rgba(251, 191, 36, 0.12)'
            },
            error: {
              main: '#EF4444',
              light: '#F87171',
              dark: '#DC2626',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: '#DC2626',
              background: '#FECACA',
              transparent: 'rgba(248, 113, 133, 0.12)'
            },
            text: {
              primary:
                customColors?.text_primary_color || 'rgba(26, 26, 29, 0.87)',
              secondary:
                customColors?.text_secondary_color || 'rgba(26, 26, 29, 0.6)',
              disabled: 'rgba(26, 26, 29, 0.38)',
              solid: '#1a1a1d',
              primaryHex: '#2a2a2c', // primary on #ffffff
              secondaryHex: '#636364' // secondary on #ffffff
            }
          }
        : {
            // DARK MODE
            primary: {
              main: customColors?.primary_color || '#ffffff',
              light: '#ffffff',
              dark: customColors?.primary_color
                ? hexToDark(customColors.primary_color)
                : '#f7f7f8',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: customColors?.primary_color
                ? hexToDark(customColors.primary_color)
                : '#ffffff',
              background: '#ffffff',
              transparent: customColors?.primary_color
                ? hexToRgbString(customColors?.primary_color, 0.12)
                : 'rgba(255, 255, 255, 0.12)',
              transparentLight: 'rgba(255, 255, 255, 0.6)',
              transparentDark: 'rgba(255, 255, 255, 0.26)'
            },
            grey: {
              // 50: '#', ?
              100: '#1c1c1e', // rgb = 48,48,50, white opacity = 5%
              200: '#1f1f22', // rgb = 46,46,48, white opacity = 7%
              300: '#212123', // rgb = 44,44,47, white opacity = 8%
              400: '#232325', // rgb = 41,41,42, white opacity = 9%
              500: '#262629', // rgb = 38,38,41, white opacity = 11%
              600: '#29292a', // rgb = 35,35,37, white opacity = 12%
              700: '#2c2c2f', // rgb = 33,33,35, white opacity = 14%
              800: '#2e2e30', // rgb = 31,31,34, white opacity = 15%
              900: '#303032' // rgb = 28,28,30, white opacity = 16%
            },
            action: {
              active: 'rgba(255, 255, 255, 0.54)',
              hover: 'rgba(255, 255, 255, 0.04)',
              selected: 'rgba(255, 255, 255, 0.08)',
              disabled: 'rgba(255, 255, 255, 0.26)',
              disabledBackground: 'rgba(255, 255, 255, 0.06)',
              focus: 'rgba(255, 255, 255, 0.12)'
            },
            background: {
              backdrop: 'rgba(255, 255, 255, 0.5)',
              default: '#111111',
              overlay: 'rgba(255, 255, 255, 0.12)',
              paper: '#1a1a1d',
              transparent: 'rgba(26, 26, 29, 0.87)',
              tooltip: 'rgba(26, 26, 29, 0.92)',
              widget: customColors?.widget_background_color || '#1a1a1d'
            },
            default: {
              main: '#1a1a1d',
              light: '#111111',
              dark: '#000000',
              contrastText: 'rgba(255, 255, 255, 0.87)',
              text: '#ffffff',
              transparent: 'rgba(255, 255, 255, 0.12)'
            },
            divider: 'rgba(255, 255, 255, 0.12)',
            neutral: {
              main: '#1c1c1e',
              light: '#111111',
              dark: '#1a1a1d',
              contrastText: 'rgba(255, 255, 255, 0.87)',
              text: '#111111',
              transparent: 'rgba(255, 255, 255, 0.12)'
            },
            success: {
              main: '#22C55E',
              light: '#4ADE80',
              dark: '#16A34A',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: '#16A34A',
              background: '#BBF7D0',
              transparent: 'rgba(74, 222, 128, 0.12)'
            },
            info: {
              main: '#3B82F6',
              light: '#60A5FA',
              dark: '#2563EB',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: '#2563EB',
              background: '#BFDBFE',
              transparent: 'rgba(96, 165, 250, 0.12)'
            },
            warning: {
              main: '#F59E0B',
              light: '#FBBF24',
              dark: '#D97706',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: '#D97706',
              background: '#FDE68A',
              transparent: 'rgba(251, 191, 36, 0.12)'
            },
            error: {
              main: '#EF4444',
              light: '#F87171',
              dark: '#DC2626',
              contrastText: 'rgba(26, 26, 29, 0.87)',
              text: '#DC2626',
              background: '#FECACA',
              transparent: 'rgba(248, 113, 133, 0.12)'
            },
            text: {
              primary:
                customColors?.text_primary_color || 'rgba(255, 255, 255, 0.87)',
              secondary:
                customColors?.text_secondary_color ||
                'rgba(255, 255, 255, 0.6)',
              disabled: 'rgba(255, 255, 255, 0.38)',
              solid: '#ffffff',
              primaryHex: '#dadadb', // primary on #1a1a1d
              secondaryHex: '#929294' // secondary on #1a1a1d
            }
          })
    },
    shape: {
      borderRadius: 6
    },
    shadows: [
      'none',
      '0px 1px 1px rgba(26, 26, 29, 0.06), 0px 1px 2px rgba(26, 26, 29, 0.1)',
      '0px 1px 2px rgba(26, 26, 29, 0.12)',
      '0px 1px 4px rgba(26, 26, 29, 0.12)',
      '0px 1px 5px rgba(26, 26, 29, 0.12)',
      '0px 1px 6px rgba(26, 26, 29, 0.12)',
      '0px 2px 6px rgba(26, 26, 29, 0.12)',
      '0px 3px 6px rgba(26, 26, 29, 0.12)',
      '0px 2px 4px rgba(26, 26, 29, 0.06), 0px 4px 6px rgba(26, 26, 29, 0.12)',
      '0px 5px 12px rgba(26, 26, 29, 0.12)',
      '0px 5px 14px rgba(26, 26, 29, 0.12)',
      '0px 5px 15px rgba(26, 26, 29, 0.12)',
      '0px 6px 15px rgba(26, 26, 29, 0.12)',
      '0px 7px 15px rgba(26, 26, 29, 0.12)',
      '0px 8px 15px rgba(26, 26, 29, 0.12)',
      '0px 9px 15px rgba(26, 26, 29, 0.12)',
      '0px 10px 15px rgba(26, 26, 29, 0.12)',
      '0px 12px 22px -8px rgba(26, 26, 29, 0.25)',
      '0px 13px 22px -8px rgba(26, 26, 29, 0.25)',
      '0px 14px 24px -8px rgba(26, 26, 29, 0.25)',
      // eslint-disable-next-line max-len
      '0px 10px 10px rgba(26, 26, 29, 0.04), 0px 20px 25px rgba(26, 26, 29, 0.1)',
      '0px 25px 50px rgba(26, 26, 29, 0.25)',
      '0px 25px 50px rgba(26, 26, 29, 0.25)',
      '0px 25px 50px rgba(26, 26, 29, 0.25)',
      '0px 25px 50px rgba(26, 26, 29, 0.25)'
    ],
    typography: {
      button: {
        fontSize: '0.875rem',
        fontWeight: 600,
        textTransform: 'none',
        lineHeight: 1.5
      },
      fontSize: 14,
      fontFamily: [
        'IBM Plex Sans',
        'Lato',
        'Helvetica Neue',
        'sans-serif'
      ].join(','),
      body1: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: 1.5
      },
      body2: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.57
      },
      subtitle1: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.75
      },
      subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 500,
        lineHeight: 1.57
      },
      overline: {
        fontSize: '0.75rem',
        fontWeight: 400,
        letterSpacing: '0.5px',
        lineHeight: 1.75,
        textTransform: 'uppercase'
      },
      caption: {
        fontSize: '0.7rem',
        fontWeight: 400,
        lineHeight: 1.375
      },
      gigantic: {
        fontWeight: 700,
        fontSize: '6rem',
        lineHeight: 1.375
      },
      h1: {
        fontWeight: 700,
        fontSize: '3.5rem',
        // lineHeight: 1.375
        fontFamily: 'DM Sans',
        lineHeight: 1.1,
        letterSpacing: '-0.05em'
      },
      h2: {
        fontWeight: 700,
        fontSize: '3rem',
        // lineHeight: 1.375
        fontFamily: 'DM Sans',
        lineHeight: 1.1,
        letterSpacing: '-0.05em'
      },
      h3: {
        fontWeight: 700,
        fontSize: '2.25rem',
        // lineHeight: 1.375
        fontFamily: 'DM Sans',
        lineHeight: 1.1,
        letterSpacing: '-0.05em'
      },
      h4: {
        fontWeight: 700,
        fontSize: '2rem',
        // lineHeight: 1.375
        fontFamily: 'DM Sans',
        lineHeight: 1.1,
        letterSpacing: '-0.05em'
      },
      h5: {
        fontWeight: 600,
        fontSize: '1.5rem',
        // lineHeight: 1.375
        fontFamily: 'DM Sans',
        lineHeight: 1.1,
        letterSpacing: '-0.05em'
      },
      h6: {
        fontWeight: 600,
        fontSize: '1.125rem',
        // lineHeight: 1.375
        fontFamily: 'DM Sans',
        lineHeight: 1.1,
        letterSpacing: '-0.05em'
      }
    },
    zIndex: {
      mobileStepper: 1000,
      fab: 1050,
      speedDial: 1050,
      appBar: 1100,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500
    }
  })
}

// Scrollbar
export const getScrollbarSx = (theme: Theme, scaleFactor?: number) => {
  const sf = scaleFactor ?? 1

  return {
    '&::-webkit-scrollbar': {
      width: 12 * sf,
      height: 12 * sf
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: `inset 0 0 ${24 * sf}px ${theme.palette.action.hover}`,
      webkitBoxShadow: `inset 0 0 ${24 * sf}px ${theme.palette.action.hover}`
    },
    '&::-webkit-scrollbar-corner': {
      boxShadow: `inset 0 0 ${24 * sf}px ${theme.palette.action.hover}`,
      webkitBoxShadow: `inset 0 0 ${24 * sf}px ${theme.palette.action.hover}`
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'action.focus',
      borderRadius: 6 * sf,
      outline: 'none'
    }
  }
}

export const getWidgetMode = (
  colors?: CustomColors | null
): 'light' | 'dark' => {
  if (colors?.widget_background_color) {
    const rgb = hexToRgbObject(colors.widget_background_color)
    const black = rgb.red * 0.299 + rgb.green * 0.587 + rgb.blue * 0.114 > 186

    if (!black) {
      return 'dark'
    }
  }

  return 'light'
}
