import React, { useRef, useState } from 'react'

import { WhoCanView } from 'redux/reducers/Dashboards'
import {
  PermissionPatchType,
  UserType
} from 'components_new/organisms/Dashboard/utils'

import Actions from '../Actions'
import Box from 'components_new/atoms/Box'
import Collapse from 'components_new/atoms/Collapse'

import SimpleSwipeableDrawer from 'components_new/molecules/SimpleSwipeableDrawer'

import InviteSection from '../desktop/InviteSection'
import PermissionSection from '../desktop/PermissionSection'

interface MobileProps {
  fullRights: boolean
  inviteUser: (user: PermissionPatchType) => void
  isAdmin: boolean
  isPublic: boolean
  isStockholmshem: boolean
  onInvite: (newPermission: PermissionPatchType) => void
  onClose: () => void
  onOpen: () => void
  onRemove: (accountId: string) => void
  open: boolean
  permissions: UserType[]
  publicLink: string
  title: string
  userOptions: UserType[]
  whoCanView: WhoCanView
}

const Mobile = (props: MobileProps) => {
  const {
    fullRights,
    inviteUser,
    isAdmin,
    isPublic,
    isStockholmshem,
    onClose,
    onInvite,
    onOpen,
    onRemove,
    open,
    permissions,
    publicLink,
    title,
    userOptions,
    whoCanView
  } = props

  const addUserInputRef = useRef<HTMLElement | null>(null)

  const focusAddUser = () => {
    setTimeout(() => {
      if (addUserInputRef.current != null) {
        addUserInputRef.current.focus()
      }
    }, 500)
  }

  const [tab, setTab] = useState<'default' | 'embed' | 'email'>('default')

  return (
    <>
      <SimpleSwipeableDrawer
        actions={
          tab === 'default' ? (
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                flexWrap: 'wrap'
              }}
            >
              <Actions
                showActions={true}
                isAdmin={isAdmin}
                isPublic={isPublic}
                isStockholmshem={isStockholmshem}
                onClose={onClose}
                publicLink={publicLink}
                setTab={(value) => setTab(value)}
                tab={tab}
              />
            </Box>
          ) : undefined
        }
        onBack={tab === 'email' ? () => setTab('default') : undefined}
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        title={title}
      >
        <Collapse in={tab === 'default'}>
          <PermissionSection
            addUserInputRef={addUserInputRef}
            fullRights={fullRights}
            permissions={permissions}
            onInvite={onInvite}
            onRemove={onRemove}
            userOptions={userOptions}
            whoCanView={whoCanView}
          />
        </Collapse>
        <Collapse in={tab === 'email'}>
          <InviteSection
            onInvite={(email: string, message: string) =>
              inviteUser({ message, newUserEmail: email })
            }
            onGoBack={() => {
              setTab('default')
              focusAddUser()
            }}
          />
        </Collapse>
      </SimpleSwipeableDrawer>
    </>
  )
}

export default Mobile
