import ResizeObserver from 'resize-observer-polyfill'

// Object.getOwnPropertyDescriptors
if (!Object.getOwnPropertyDescriptors) {
  Object.getOwnPropertyDescriptors = function (obj) {
    const descriptors = {}

    for (const key of Object.getOwnPropertyNames(obj)) {
      descriptors[key] = Object.getOwnPropertyDescriptor(obj, key)
    }

    return descriptors
  }
}

// Object.entries
if (!Object.entries) {
  Object.entries = function (obj) {
    if (obj === null || obj === undefined) {
      throw new TypeError('Cannot convert undefined or null to object')
    }
    const entries = []

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        entries.push([key, obj[key]])
      }
    }

    return entries
  }
}

// ResizeObserver
if (!global.ResizeObserver) {
  global.ResizeObserver = ResizeObserver
}
